import { t } from '../languages';
import service from "./service";
import request from "./http.js";

export default {
  //保存航线
  saveAirPoint: (data) => {
    return service.post({
      url: "/api/v1/route/plan",
      data,
    });
  },
  // 获取全部图层数据
  getAllLayers: () => {
    return service.get({
      url: "/api/v1/layers/all",
    });
  },
  // 修改图层默认显示方式
  updateLayerDisplay: (layerId, params) => {
    return request({
      url: `/api/v1/layers/${layerId}/displayState`,
      method: "put",
      params: params,
    });
  },

  //保存多边形测绘航线
  savePolygonMapping: (data) => {
    return service.post({
      url: "/api/v1/route/orthographic",
      data,
    });
  },

  //验证航线名称是否重名
  validationName: (params) => {
    return service.get({
      url: "/api/v1/route/repeatRouteName",
      params,
    });
  },

  //获取相机类型
  getCameraModel: () => {
    return service.get({
      url: "/api/v1/route/getCameraModel",
    });
  },

  //获取相机参数
  getCameraParam: (params) => {
    return service.get({
      url: "/api/v1/route/getCameraParam",
      params,
    });
  },

  //添加新建航线重命名检查
  getRoutePlanName: (params) => {
    return service.get({
      url: "/api/v1/route/repeatRouteName",
      params,
    });
  },

  //二次编辑航线
  reviseEdit: (params) => {
    return service.get({
      url: "/api/v1/route/revise2",
      params,
    });
  },
};
