import { t } from '../../languages';
const Cesium = require("cesium/Source/Cesium");
import ufi3d from "@/utils/ufi-cesium.js";
import { v4 as uuidv4 } from "uuid";
import { onBeforeUnmount, ref } from "vue";
import CoordTransform from "@/utils/cesium/CoordTransform";

/**
 *
 * @param {* 航线对象} routeObj
 * @param {* 左击回调} leftClickCallback
 * @param {* cesium鼠标样式类型} cursorStyle
 * @param {* 左键移动回调} leftMoveCallback
 * @param {* 左键弹起回调} leftUpCallback
 * @returns
 */
const earthInterface = (
  routeObj,
  leftClickCallback,
  cursorStyle,
  leftMoveCallback,
  leftUpCallback
) => {
  let viewer = null; //cesium视图
  let lastChooseRoutId = ""; //上一个选中的航点
  const showlabelEntity = [];
  let currLineId = "";
  let currLineItem = null;
  let currPointId = "";

  const tipRoutePoint = [];

  /* 清除显示的文本 */
  function clearLabelShow() {
    for (let i = 0; i < showlabelEntity.length; i++) {
      const entity = showlabelEntity[i];
      entity.label.show = false;
    }
  }

  function selectRoutePoint(routePointId) {
    clearLabelShow();
    // currLineId = lineId;
    currPointId = routePointId;
    // 获取航线
    // const lineItem = lineArr.find((i)=>{
    //     return i.routeplanId === lineId;
    // })
    const lineItem = lineArr[0];
    if (!lineItem) return;
    const index = lineItem.routePoint.findIndex((i) => {
      return i.routePointId === routePointId;
    });
    if (index === -1) return;
    const glLabelEntity = viewer.entities.getById("gl-" + routePointId);
    glLabelEntity.label.show = true;
    showlabelEntity.push(glLabelEntity);
    const len = lineItem.routePoint.length;
    if (len >= 2) {
      // 一个航点毫无意义
      if (index === 0) {
        // 第一个航点
        const nextId = lineItem.routePoint[1].routePointId;
        const nextDisEntity = viewer.entities.getById("dt-" + nextId);
        nextDisEntity.label.show = true;
        showlabelEntity.push(nextDisEntity);
      } else if (index === len - 1) {
        // 最后航点
        const prevDisEntity = viewer.entities.getById("dt-" + routePointId);
        prevDisEntity.label.show = true;
        showlabelEntity.push(prevDisEntity);
      } else {
        // 中间航点
        const prevDisEntity = viewer.entities.getById("dt-" + routePointId);
        prevDisEntity.label.show = true;

        const nextId = lineItem.routePoint[index + 1].routePointId;
        const nextDisEntity = viewer.entities.getById("dt-" + nextId);
        nextDisEntity.label.show = true;
        showlabelEntity.push(prevDisEntity);
        showlabelEntity.push(nextDisEntity);
      }
    }
    updateRouteLight(routePointId);
  }

  // const hoverLabelEntity = [];

  // function hoverRoutePoint(routePointId) {
  //     const lineItem = lineArr[0];
  //     if (!lineItem) return;
  //     const index = lineItem.routePoint.findIndex((i) => {
  //         return i.routePointId === routePointId;
  //     })
  //     if (index === -1) return;
  //     const glLabelEntity = viewer.entities.getById('gl-' + routePointId);
  //     glLabelEntity.label.show = true;
  //     hoverLabelEntity.push(glLabelEntity);
  //     const len = lineItem.routePoint.length;
  //     if (len >= 2) {
  //         // 一个航点毫无意义
  //         if (index === 0) {
  //             // 第一个航点
  //             const nextId = lineItem.routePoint[1].routePointId;
  //             const nextDisEntity = viewer.entities.getById('dt-' + nextId);
  //             nextDisEntity.label.show = true;
  //             hoverLabelEntity.push(nextDisEntity);
  //         } else if (index === len - 1) {
  //             // 最后航点
  //             const prevDisEntity = viewer.entities.getById('dt-' + routePointId);
  //             prevDisEntity.label.show = true;
  //             hoverLabelEntity.push(prevDisEntity);
  //         } else {
  //             // 中间航点
  //             const prevDisEntity = viewer.entities.getById('dt-' + routePointId);
  //             prevDisEntity.label.show = true;

  //             const nextId = lineItem.routePoint[index + 1].routePointId;
  //             const nextDisEntity = viewer.entities.getById('dt-' + nextId);
  //             nextDisEntity.label.show = true;
  //             hoverLabelEntity.push(prevDisEntity);
  //             hoverLabelEntity.push(nextDisEntity);
  //         }
  //     }
  // }

  // /* 清除显示的文本 */
  // function clearHoverLabel() {
  //     for (let i = 0; i < hoverLabelEntity.length; i++) {
  //         const entity = hoverLabelEntity[i];
  //         entity.label.show = false;
  //     }
  // }

  /**
   * 设置cesium鼠标样式
   * @param {* 样式名} style  -- string
   */
  function setCursorStyle(style) {
    viewer._element.style.cursor = style;
  }

  /* 设置鼠标可移动图标 */
  function setMoveCursor() {
    if (viewer) {
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      let timer = null;
      handler.setInputAction((e) => {
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        const entityDrag = viewer.scene.pick(e.endPosition); //选取当前的entity
        const position = viewer.scene.pickPosition(e.endPosition);
        if (entityDrag && entityDrag.id && entityDrag.id.draggable === true) {
          setCursorStyle("move");
          // const routePointId = entityDrag.id.routePointId;
          // if(routePointId !== currPointId){
          //     console.log(timer === null)
          //     if (timer === null) {
          //         console.log(routePointId)
          //         hoverRoutePoint(routePointId);
          //     }
          //     clearTimeout(timer);
          //     timer = setTimeout(() => {
          //         console.log(t('58'))
          //         clearHoverLabel();
          //         timer = null;
          //     }, 1000)
          // }
          if (position && hoverTipEntity) {
            hoverTipEntity.position = position;
            if (entityDrag.id.entityType === "rp") {
              hoverTipEntity.label.text =
                t("59") + '\n' + t("60");
            } else {
              hoverTipEntity.label.text = t("59");
            }
          }
        } else {
          // 恢复原有的样式
          setCursorStyle(cursorStyle.value);
          if (hoverTipEntity) {
            hoverTipEntity.label.text = "";
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    }
  }

  /**
   * 锁定/解锁相机
   * @param {* 是否锁定相机} flag -- boolean
   */
  function toggleLockCamera(flag) {
    viewer.scene.screenSpaceCameraController.enableRotate = flag;
    viewer.scene.screenSpaceCameraController.enableTranslate = flag;
  }

  /* 修改选中航点效果 */
  function updateRouteLight(newId) {
    if (lastChooseRoutId !== "") {
      const lastEntity = viewer.entities.getById("rp-" + lastChooseRoutId);
      if (lastEntity) {
        lastEntity.model.uri = "public/model/chassis.glb";
        combineIconAndLabel(
          require("./../../asset/img/routeLine/route_icon.png"),
          lastEntity.number,
          32,
          14
        ).then((res) => {
          lastEntity.billboard.image = res;
        });
      }
    }
    lastChooseRoutId = newId;
    const entity = viewer.entities.getById("rp-" + lastChooseRoutId);
    combineIconAndLabel(
      require("./../../asset/img/routeLine/route_icon_light.png"),
      entity.number,
      32,
      14
    ).then((res) => {
      entity.billboard.image = res;
    });
    entity.model.uri = "public/model/chassis_active.glb";
  }

  let hoverTipEntity = null;
  /* 创建航点悬浮文本 */
  function createHoverTip() {
    hoverTipEntity = viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(120, 30, 100),
      label: {
        // text: t('61'),
        text: "",
        font: "12px sans-serif",
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        showBackground: true,
        backgroundColor: Cesium.Color.fromCssColorString("#000000"),
        fillColor: Cesium.Color.fromCssColorString("#fff"),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        pixelOffset: new Cesium.Cartesian2(15, 15),
      },
    });
  }

  // 地形提供者
  const terrainProvider = setTerrain();
  /* 设置地形 */
  function setTerrain() {
    const mars3dTerrain = localStorage.getItem('mars3dTerrain')
    const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
    return  new Cesium.CesiumTerrainProvider({
        url: terrainUrl
    })
  };
  let locationTimer = null;
  /* 记录相机位置 */
  function rememberLocation() {
    locationTimer = setInterval(() => {
      if (viewer) {
        const obj = {
          position: Cesium.clone(viewer.camera.position),
          direction: Cesium.clone(viewer.camera.direction),
          up: Cesium.clone(viewer.camera.up),
        };
        localStorage.setItem("camera", JSON.stringify(obj));
      }
    }, 5000);
  }

  /* 恢复相机位置 */
  function recoveryLocation() {
    const cameraJson = localStorage.getItem("camera");
    if (!viewer) return;
    if (cameraJson !== null) {
      const cameraParam = JSON.parse(cameraJson);
      viewer.camera.position = cameraParam.position;
      viewer.camera.direction = cameraParam.direction;
      viewer.camera.up = cameraParam.up;
    } else {
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(116.4, 39.91, 1000000),
        orientation: {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: 0.0,
        },
      });
    }
  }

  /**
   * 初始化cesium
   * @param {* dom元素的id名称} el
   */
  function initCesium(el, callback) {
    if (!viewer) {
      const tiandituConSess = localStorage.getItem("tiandituCon");
      viewer = ufi3d.initCesium(el, {
        // 影像地图
        // imageryProvider: new Cesium.UrlTemplateImageryProvider({
        //     url: window.g.satelliteMapUrl + '/{z}/{x}/{y}.png',
        //     tilingScheme: new Cesium.WebMercatorTilingScheme(),
        // }),
        // 地形
        terrainProvider: terrainProvider,
        // terrainProvider: Cesium.createWorldTerrain(),
      });
      // 需要带上，不然会加载默认地图
      viewer.imageryLayers.removeAll();
      if (tiandituConSess === "true") {
        // 天地图
        const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
        const layers = viewer.imageryLayers;
        layers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            //影像底图
            url:
              "https://t{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=" +
              "47421dd0051ada3957f279b484932789",
            subdomains: subdomains, //URL模板中用于{s}占位符的子域。如果该参数是单个字符串，则字符串中的每个字符都是一个子域。如果它是一个数组，数组中的每个元素都是一个子域
            layer: "tdtImgLayer",
            style: "default",
            format: "image/jpeg",
            tileMatrixSetID: "GoogleMapsCompatible", //使用谷歌的瓦片切片方式
            maximumLevel: 18,
            show: true,
          })
        );
        layers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            //影像注记
            url:
              "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
              "47421dd0051ada3957f279b484932789",
            subdomains: subdomains,
            layer: "tdtCiaLayer",
            style: "default",
            format: "image/jpeg",
            tileMatrixSetID: "GoogleMapsCompatible",
            maximumLevel: 18,
            show: true,
          })
        );
      } else {
        // 谷歌地图
        const layers = viewer.imageryLayers;
        layers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
          })
        );
      }
      // /* 加载安居宝b3dm */
      // const tileset = viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
      //     show: false,
      //     url: 'http://120.25.246.199:9391/asset/2022/7/26/a9e053b7c3091907b642516d53b1773d/anjubao_b3dms/tileset.json',
      //     // url: 'http://120.25.246.199:9391/asset/2022/4/22/9438bf9d061be54fd60df7719332991f/p4/tileset.json',
      //     maximumScreenSpaceError: 1,
      //     maximumMemoryUsage: 1024,
      //     dynamicScreenSpaceError: true,
      //     cullWithChildrenBounds: false,
      //     skipLevelOfDetail: true,
      //     preferLeaves: true,
      // }));
      // tileset.readyPromise.then(function (tileset) {
      //     viewer.zoomTo(tileset);
      // }).catch(function (error) {
      //     console.log(er11ror);
      // });
      recoveryLocation();
      createHoverTip();
      // 显示帧率
      // viewer.scene.debugShowFramesPerSecond = true;
      // viewer.scene.globe.translucency.frontFaceAlpha = 0.1;
      // viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      // 左击事件
      handler.setInputAction((e) => {
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        const entityDrag = viewer.scene.pick(e.position);
        // 返回从深度缓冲区和窗口位置重建的笛卡尔位置。适用于模型表面位置和地球表面位置的选取
        const position = viewer.scene.pickPosition(e.position);
        if (typeof entityDrag === "undefined") {
          if (position) {
            // 拾取到坐标
            leftClickCallback(false, {
              position: position,
            });
          }
        } else {
          if (entityDrag.primitive instanceof Cesium.Cesium3DTileset) {
            // 点云、倾斜模型
            if (position) {
              // 拾取到坐标
              leftClickCallback(false, {
                position: position,
              });
            }
          } else {
            // 其他类型实体
            if (entityDrag.id && entityDrag.id.draggable) {
              // 拾取到实体
              const routeplanId = entityDrag.id.routeplanId;
              const routePointId = entityDrag.id.routePointId;
              leftClickCallback(true, {
                routeplanId: routeplanId,
                routePointId: routePointId,
              });
              selectRoutePoint(routePointId);
            } else {
              // console.log(t('62'));
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // 鼠标移动事件
      setMoveCursor();

      // 鼠标左键按下事件
      handler.setInputAction((e) => {
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        // const entityDrag = viewer.scene.pick(e.position); //选取当前的entity
        const pickedEntities = viewer.scene.drillPick(e.position); //选取当前的entity
        // console.log(pickedEntities)

        let entityDrag = null;
        for (let i = 0; i < pickedEntities.length; i++) {
          const pickEntity = pickedEntities[i];
          if (pickEntity.id && pickEntity.id.draggable === true) {
            entityDrag = pickEntity;
            break;
          }
        }
        const ellipsoid = viewer.scene.globe.ellipsoid; //椭球体
        // const downPosition = viewer.scene.pickPosition(e.position);
        if (entityDrag) {
          // console.log(t('63'), downPosition)
          // const downCartographic = Cesium.Cartographic.fromCartesian(
          //     downPosition,
          //     viewer.scene.globe.ellipsoid
          // );
          // console.log(t('64'), downCartographic.height);
          // viewer.entities.add({
          //     position: downPosition,
          //     point: {
          //         pixelSize: 10
          //     }
          // })

          setCursorStyle("move");
          const entityType = entityDrag.id.entityType;
          let routePointHeight = -1; //高度
          let moveType = "";
          // 航线和航点的id
          const routeplanId = entityDrag.id.routeplanId;
          const routePointId = entityDrag.id.routePointId;
          selectRoutePoint(routePointId);
          // 航线对象
          const lineItem = lineArr.find((i) => {
            return i.routeplanId === routeplanId;
          });
          // 点对象的索引
          const routePointIndex = lineItem.routePoint.findIndex((i) => {
            return i.routePointId === routePointId;
          });
          let newPosition = null;

          let currPrevDistance = -1;
          let nextPrevDistance = -1;
          if (entityType === "gp") {
            // 垂地点
            toggleLockCamera(false);
            // 航点实体
            const routePointEntity = viewer.entities.getById(
              "rp-" + routePointId
            );
            const routePointPosi = routePointEntity.position._value;
            const routePointHei =
              ellipsoid.cartesianToCartographic(routePointPosi).height;
            handler.setInputAction((downE) => {
              moveType = "gp_mouseMove";
              // 垂地点的新位置
              const newGPosition = viewer.scene.pickPosition(downE.endPosition);
              if (!newGPosition) return;
              entityDrag.id.position = newGPosition;

              // 新位置转为弧度
              const newGPositionCartographic =
                ellipsoid.cartesianToCartographic(newGPosition);
              newGPositionCartographic.height = routePointHei;
              // 得到新航点坐标 -- Cartesian3
              const newRPosition = ellipsoid.cartographicToCartesian(
                newGPositionCartographic
              );
              routePointEntity.position = newRPosition;

              // 垂地线实体
              // 求得两点中点
              const midPoint = Cesium.Cartesian3.midpoint(
                newGPosition,
                newRPosition,
                new Cesium.Cartesian3()
              );
              const groundLineEntity = viewer.entities.getById(
                "gl-" + routePointId
              );
              groundLineEntity.position = midPoint;
              groundLineEntity.polyline.positions = new Cesium.CallbackProperty(
                function () {
                  return [newGPosition, newRPosition];
                },
                false
              ); //防止闪烁，在移动的过程

              // 航点位置列表
              const routePositions = lineItem.routePositions;
              routePositions[routePointIndex] = newRPosition;
              // 更新航线
              updateRouteLine(routeplanId, routePositions);

              if (routePositions.length >= 2) {
                // 大于等于2个航点才需要更新距离
                if (routePointIndex === 0) {
                  // 第一个航点 -- 更新与后一个的距离
                  const nextPosition = routePositions[routePointIndex + 1];
                  const nextRoutePoint =
                    lineItem.routePoint[routePointIndex + 1];
                  const midPosition = Cesium.Cartesian3.midpoint(
                    newRPosition,
                    nextPosition,
                    new Cesium.Cartesian3()
                  );
                  const distance = Cesium.Cartesian3.distance(
                    newRPosition,
                    nextPosition
                  );
                  const distanceTextEntity = viewer.entities.getById(
                    "dt-" + nextRoutePoint.routePointId
                  );
                  distanceTextEntity.label.text = distance.toFixed(1) + t("42");
                  distanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return midPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  nextRoutePoint.pointDistance = distance;
                  nextPrevDistance = distance;
                } else if (routePointIndex === routePositions.length - 1) {
                  // 最后一个航点 -- 更新与前一个的距离
                  const prevPosition = routePositions[routePointIndex - 1];
                  const midPosition = Cesium.Cartesian3.midpoint(
                    prevPosition,
                    newRPosition,
                    new Cesium.Cartesian3()
                  );
                  const distance = Cesium.Cartesian3.distance(
                    prevPosition,
                    newRPosition
                  );
                  const distanceTextEntity = viewer.entities.getById(
                    "dt-" + routePointId
                  );
                  distanceTextEntity.label.text = distance.toFixed(1) + t("42");
                  distanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return midPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  lineItem.routePoint[routePointIndex].pointDistance = distance;
                  currPrevDistance = distance;
                } else {
                  // 中间航点 -- 更新前一个和后一个的距离
                  const prevPosition = routePositions[routePointIndex - 1];
                  const nextPosition = routePositions[routePointIndex + 1];
                  const nextRoutePoint =
                    lineItem.routePoint[routePointIndex + 1];

                  const prevMidPosition = Cesium.Cartesian3.midpoint(
                    prevPosition,
                    newRPosition,
                    new Cesium.Cartesian3()
                  );
                  const prevDistance = Cesium.Cartesian3.distance(
                    prevPosition,
                    newRPosition
                  );

                  const nextMidPosition = Cesium.Cartesian3.midpoint(
                    newRPosition,
                    nextPosition,
                    new Cesium.Cartesian3()
                  );
                  const nextDistance = Cesium.Cartesian3.distance(
                    newRPosition,
                    nextPosition
                  );

                  const prevDistanceTextEntity = viewer.entities.getById(
                    "dt-" + routePointId
                  );
                  prevDistanceTextEntity.label.text =
                    prevDistance.toFixed(1) + t("42");
                  prevDistanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return prevMidPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  lineItem.routePoint[routePointIndex].pointDistance =
                    prevDistance;

                  const nextDistanceTextEntity = viewer.entities.getById(
                    "dt-" + nextRoutePoint.routePointId
                  );
                  nextDistanceTextEntity.label.text =
                    nextDistance.toFixed(1) + t("42");
                  nextDistanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return nextMidPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  nextRoutePoint.pointDistance = nextDistance;
                  currPrevDistance = prevDistance;
                  nextPrevDistance = nextDistance;
                }
              }

              leftMoveCallback(true, {
                routeplanId: routeplanId,
                routePointId: routePointId,
                position: newRPosition,
              });

              newPosition = newRPosition;
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
          } else if (entityType === "rp") {
            // 航点
            toggleLockCamera(false);
            const entityCartographic = ellipsoid.cartesianToCartographic(
              entityDrag.id.position._value
            );
            routePointHeight = entityCartographic.height;
            handler.setInputAction((downE) => {
              if (moveType === "rp_mouseMove_alt") {
                return;
              }
              moveType = "rp_mouseMove";
              // 起始点射线
              const startRay = viewer.camera.getPickRay(downE.startPosition);
              // 结束点射线
              const endRay = viewer.camera.getPickRay(downE.endPosition);
              // 结束点相机的位置（即结束点射线的源点）
              const cameraCartesian = endRay.origin;
              //相机的高度
              const cameraHeight =
                ellipsoid.cartesianToCartographic(cameraCartesian).height;
              // 结束点射线地面投影点
              const groundCartesian = viewer.camera.pickEllipsoid(
                downE.endPosition,
                ellipsoid
              );
              // 相机到地面投影点的距离
              const cameraToGroundDistance = Cesium.Cartesian3.distance(
                groundCartesian,
                cameraCartesian
              );
              // 根据相似三角形定理得  射线原点到地面投影距离 / 实体点到地面投影距离 = 射线原点高度 / 实体点高度
              // 所以 实体点到地面投影距离 = 射线原点到地面投影距离 * 实体点高度 / 射线原点高度
              const pointToGroundDistance =
                (cameraToGroundDistance * routePointHeight) / cameraHeight;
              // 相机到实体点距离 = 相机到地面投影距离 - 实体点到地面投影距离
              const cameraToPointDistance =
                cameraToGroundDistance - pointToGroundDistance;
              // 根据射线定理，由一条射线和距离可得出唯一一点 -- 此处为结束点射线上的实体点位置
              const newRPosition = Cesium.Ray.getPoint(
                endRay,
                cameraToPointDistance,
                new Cesium.Cartesian3()
              );
              entityDrag.id.position = newRPosition;
              // 点的位置弧度
              const rPointCartographic =
                ellipsoid.cartesianToCartographic(newRPosition);
              // 高度置为0，即为垂地点的坐标
              rPointCartographic.height = 1;
              // 垂地点位置
              const gPointPosi =
                ellipsoid.cartographicToCartesian(rPointCartographic);

              // 垂地点实体
              const groundPointEntity = viewer.entities.getById(
                "gp-" + routePointId
              );
              groundPointEntity.position = gPointPosi;
              // 求得两点中点
              const midPoint = Cesium.Cartesian3.midpoint(
                gPointPosi,
                newRPosition,
                new Cesium.Cartesian3()
              );
              // 垂地线实体
              const groundLineEntity = viewer.entities.getById(
                "gl-" + routePointId
              );
              groundLineEntity.position = midPoint;
              groundLineEntity.polyline.positions = new Cesium.CallbackProperty(
                function () {
                  return [gPointPosi, newRPosition];
                },
                false
              ); //防止闪烁，在移动的过程

              // 航点位置列表
              const routePositions = lineItem.routePositions;
              routePositions[routePointIndex] = newRPosition;
              // 更新航线
              updateRouteLine(routeplanId, routePositions);

              if (routePositions.length >= 2) {
                // 大于等于2个航点才需要更新距离
                if (routePointIndex === 0) {
                  // 第一个航点 -- 更新与后一个的距离
                  const nextPosition = routePositions[routePointIndex + 1];
                  const nextRoutePoint =
                    lineItem.routePoint[routePointIndex + 1];
                  const midPosition = Cesium.Cartesian3.midpoint(
                    newRPosition,
                    nextPosition,
                    new Cesium.Cartesian3()
                  );
                  const distance = Cesium.Cartesian3.distance(
                    newRPosition,
                    nextPosition
                  );
                  const distanceTextEntity = viewer.entities.getById(
                    "dt-" + nextRoutePoint.routePointId
                  );
                  distanceTextEntity.label.text = distance.toFixed(1) + t("42");
                  distanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return midPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  nextRoutePoint.pointDistance = distance;
                  nextPrevDistance = distance;
                } else if (routePointIndex === routePositions.length - 1) {
                  // 最后一个航点 -- 更新与前一个的距离
                  const prevPosition = routePositions[routePointIndex - 1];
                  const midPosition = Cesium.Cartesian3.midpoint(
                    prevPosition,
                    newRPosition,
                    new Cesium.Cartesian3()
                  );
                  const distance = Cesium.Cartesian3.distance(
                    prevPosition,
                    newRPosition
                  );
                  const distanceTextEntity = viewer.entities.getById(
                    "dt-" + routePointId
                  );
                  distanceTextEntity.label.text = distance.toFixed(1) + t("42");
                  distanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return midPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  lineItem.routePoint[routePointIndex].pointDistance = distance;
                  currPrevDistance = distance;
                } else {
                  // 中间航点 -- 更新前一个和后一个的距离
                  const prevPosition = routePositions[routePointIndex - 1];
                  const nextPosition = routePositions[routePointIndex + 1];
                  const nextRoutePoint =
                    lineItem.routePoint[routePointIndex + 1];

                  const prevMidPosition = Cesium.Cartesian3.midpoint(
                    prevPosition,
                    newRPosition,
                    new Cesium.Cartesian3()
                  );
                  const prevDistance = Cesium.Cartesian3.distance(
                    prevPosition,
                    newRPosition
                  );

                  const nextMidPosition = Cesium.Cartesian3.midpoint(
                    newRPosition,
                    nextPosition,
                    new Cesium.Cartesian3()
                  );
                  const nextDistance = Cesium.Cartesian3.distance(
                    newRPosition,
                    nextPosition
                  );

                  const prevDistanceTextEntity = viewer.entities.getById(
                    "dt-" + routePointId
                  );
                  prevDistanceTextEntity.label.text =
                    prevDistance.toFixed(1) + t("42");
                  prevDistanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return prevMidPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  lineItem.routePoint[routePointIndex].pointDistance =
                    prevDistance;

                  const nextDistanceTextEntity = viewer.entities.getById(
                    "dt-" + nextRoutePoint.routePointId
                  );
                  nextDistanceTextEntity.label.text =
                    nextDistance.toFixed(1) + t("42");
                  nextDistanceTextEntity.position = new Cesium.CallbackProperty(
                    function () {
                      return nextMidPosition;
                    },
                    false
                  ); //防止闪烁，在移动的过程
                  nextRoutePoint.pointDistance = nextDistance;

                  currPrevDistance = prevDistance;
                  nextPrevDistance = nextDistance;
                }
              }

              leftMoveCallback(true, {
                routeplanId: routeplanId,
                routePointId: routePointId,
                position: newRPosition,
              });

              newPosition = newRPosition;
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            const groundCartographic = new Cesium.Cartographic(
              entityCartographic.longitude,
              entityCartographic.latitude,
              0
            );
            const groundCartesian =
              ellipsoid.cartographicToCartesian(groundCartographic);

            const groundPointEntity = viewer.entities.getById(
              "gp-" + routePointId
            );
            // 在模型上或地面上的点，用于设置高度文字中点的位置
            const groundOnPosition = groundPointEntity.position._value;
            // 航点实体 -- 调整高度
            handler.setInputAction(
              (event) => {
                moveType = "rp_mouseMove_alt";
                const startPosition = event.startPosition;
                const endPosition = event.endPosition;
                setCursorStyle("n-resize");
                if (
                  startPosition.x === endPosition.x &&
                  startPosition.y !== endPosition.y
                ) {
                  // 开始点射线
                  const startRay = viewer.camera.getPickRay(
                    event.startPosition
                  );
                  // 开始点射线原点位置
                  const startRayOrigin = startRay.origin;
                  // 实体点的起始位置
                  const entityPosi = entityDrag.id.position._value;
                  // 射线原点到实体点的距离
                  const originToPointDistance = Cesium.Cartesian3.distance(
                    startRayOrigin,
                    entityPosi
                  );
                  // 结束点射线
                  const endRay = viewer.camera.getPickRay(event.endPosition);
                  // 结束点射线原点位置
                  // const endRayOrigin = endRay.origin;
                  // 结束点射线实体点位置
                  const endPointCartesian = Cesium.Ray.getPoint(
                    endRay,
                    originToPointDistance,
                    new Cesium.Cartesian3()
                  );
                  const endPointCartographic =
                    ellipsoid.cartesianToCartographic(endPointCartesian);
                  const endPointHeight = endPointCartographic.height;
                  entityCartographic.height = endPointHeight;
                  const newRPosition =
                    ellipsoid.cartographicToCartesian(entityCartographic);
                  entityDrag.id.position = newRPosition;

                  // 垂地线实体
                  const groundLineEntity = viewer.entities.getById(
                    "gl-" + routePointId
                  );
                  const groundPosi = groundCartesian;
                  const distance = Cesium.Cartesian3.distance(
                    groundPosi,
                    newRPosition
                  );
                  const groundLMidPoint = Cesium.Cartesian3.midpoint(
                    groundOnPosition,
                    newRPosition,
                    new Cesium.Cartesian3()
                  );
                  groundLineEntity.position = groundLMidPoint;
                  groundLineEntity.label.text =
                    t("65") + distance.toFixed(1) + t("42");
                  groundLineEntity.polyline.positions =
                    new Cesium.CallbackProperty(function () {
                      return [groundPosi, newRPosition];
                    }, false); //防止闪烁，在移动的过程

                  // 航点位置列表
                  const routePositions = lineItem.routePositions;
                  routePositions[routePointIndex] = newRPosition;
                  // 更新航线
                  updateRouteLine(routeplanId, routePositions);

                  if (routePositions.length >= 2) {
                    // 大于等于2个航点才需要更新距离
                    if (routePointIndex === 0) {
                      // 第一个航点 -- 更新与后一个的距离
                      const nextPosition = routePositions[routePointIndex + 1];
                      const nextRoutePoint =
                        lineItem.routePoint[routePointIndex + 1];
                      const midPosition = Cesium.Cartesian3.midpoint(
                        newRPosition,
                        nextPosition,
                        new Cesium.Cartesian3()
                      );
                      const distance = Cesium.Cartesian3.distance(
                        newRPosition,
                        nextPosition
                      );
                      const distanceTextEntity = viewer.entities.getById(
                        "dt-" + nextRoutePoint.routePointId
                      );
                      distanceTextEntity.label.text =
                        distance.toFixed(1) + t("42");
                      distanceTextEntity.position = new Cesium.CallbackProperty(
                        function () {
                          return midPosition;
                        },
                        false
                      ); //防止闪烁，在移动的过程
                      nextRoutePoint.pointDistance = distance;
                      nextPrevDistance = distance;
                    } else if (routePointIndex === routePositions.length - 1) {
                      // 最后一个航点 -- 更新与前一个的距离
                      const prevPosition = routePositions[routePointIndex - 1];
                      const midPosition = Cesium.Cartesian3.midpoint(
                        prevPosition,
                        newRPosition,
                        new Cesium.Cartesian3()
                      );
                      const distance = Cesium.Cartesian3.distance(
                        prevPosition,
                        newRPosition
                      );
                      const distanceTextEntity = viewer.entities.getById(
                        "dt-" + routePointId
                      );
                      distanceTextEntity.label.text =
                        distance.toFixed(1) + t("42");
                      distanceTextEntity.position = new Cesium.CallbackProperty(
                        function () {
                          return midPosition;
                        },
                        false
                      ); //防止闪烁，在移动的过程
                      lineItem.routePoint[routePointIndex].pointDistance =
                        distance;
                      currPrevDistance = distance;
                    } else {
                      // 中间航点 -- 更新前一个和后一个的距离
                      const prevPosition = routePositions[routePointIndex - 1];
                      const nextPosition = routePositions[routePointIndex + 1];
                      const nextRoutePoint =
                        lineItem.routePoint[routePointIndex + 1];

                      const prevMidPosition = Cesium.Cartesian3.midpoint(
                        prevPosition,
                        newRPosition,
                        new Cesium.Cartesian3()
                      );
                      const prevDistance = Cesium.Cartesian3.distance(
                        prevPosition,
                        newRPosition
                      );

                      const nextMidPosition = Cesium.Cartesian3.midpoint(
                        newRPosition,
                        nextPosition,
                        new Cesium.Cartesian3()
                      );
                      const nextDistance = Cesium.Cartesian3.distance(
                        newRPosition,
                        nextPosition
                      );

                      const prevDistanceTextEntity = viewer.entities.getById(
                        "dt-" + routePointId
                      );
                      prevDistanceTextEntity.label.text =
                        prevDistance.toFixed(1) + t("42");
                      prevDistanceTextEntity.position =
                        new Cesium.CallbackProperty(function () {
                          return prevMidPosition;
                        }, false); //防止闪烁，在移动的过程
                      lineItem.routePoint[routePointIndex].pointDistance =
                        prevDistance;

                      const nextDistanceTextEntity = viewer.entities.getById(
                        "dt-" + nextRoutePoint.routePointId
                      );
                      nextDistanceTextEntity.label.text =
                        nextDistance.toFixed(1) + t("42");
                      nextDistanceTextEntity.position =
                        new Cesium.CallbackProperty(function () {
                          return nextMidPosition;
                        }, false); //防止闪烁，在移动的过程
                      nextRoutePoint.pointDistance = nextDistance;
                      currPrevDistance = prevDistance;
                      nextPrevDistance = nextDistance;
                    }
                  }

                  leftMoveCallback(true, {
                    routeplanId: routeplanId,
                    routePointId: routePointId,
                    position: newRPosition,
                  });

                  newPosition = newRPosition;
                }
              },
              Cesium.ScreenSpaceEventType.MOUSE_MOVE,
              Cesium.KeyboardEventModifier.ALT
            );
          } else if (entityType === "gl") {
            // 垂地线
            // toggleLockCamera(false);
            // const entityCartographic = ellipsoid.cartesianToCartographic(entityDrag.id.position._value);
            // routePointHeight = entityCartographic.height;
            // handler.setInputAction(downE => {
            //     // 起始点射线
            //     const startRay = viewer.camera.getPickRay(downE.startPosition);
            //     // 结束点射线
            //     const endRay = viewer.camera.getPickRay(downE.endPosition);
            //     // 结束点相机的位置（即结束点射线的源点）
            //     const cameraCartesian = endRay.origin;
            //     //相机的高度
            //     const cameraHeight = ellipsoid.cartesianToCartographic(cameraCartesian).height;
            //     // 结束点射线地面投影点
            //     const groundCartesian = viewer.camera.pickEllipsoid(downE.endPosition, ellipsoid)
            //     // 相机到地面投影点的距离
            //     const cameraToGroundDistance = Cesium.Cartesian3.distance(groundCartesian, cameraCartesian);
            //     // 根据相似三角形定理得  射线原点到地面投影距离 / 实体点到地面投影距离 = 射线原点高度 / 实体点高度
            //     // 所以 实体点到地面投影距离 = 射线原点到地面投影距离 * 实体点高度 / 射线原点高度
            //     const pointToGroundDistance = cameraToGroundDistance * routePointHeight / cameraHeight;
            //     // 相机到实体点距离 = 相机到地面投影距离 - 实体点到地面投影距离
            //     const cameraToPointDistance = cameraToGroundDistance - pointToGroundDistance;
            //     // 根据射线定理，由一条射线和距离可得出唯一一点 -- 此处为结束点射线上的实体点位置
            //     const newRPosition = Cesium.Ray.getPoint(endRay, cameraToPointDistance, new Cesium.Cartesian3());
            //     // newRPosition为
            //     entityDrag.id.position = newRPosition;
            // 垂地线实体
            // const groundLineEntity = viewer.entities.getById('gl-' + routePointId);
            // const groundPosi = groundCartesian;
            // const distance = Cesium.Cartesian3.distance(groundPosi, newRPosition);
            // const groundLMidPoint = Cesium.Cartesian3.midpoint(groundOnPosition, newRPosition, new Cesium.Cartesian3());
            // groundLineEntity.position = groundLMidPoint;
            // groundLineEntity.label.text = t('65') + distance.toFixed(1) + t('42');
            // groundLineEntity.polyline.positions = new Cesium.CallbackProperty(function () {
            //     return [groundPosi, newRPosition];
            // }, false); //防止闪烁，在移动的过程
            // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
          } else {
            // 其他实体
          }

          // 鼠标弹起事件
          handler.setInputAction((e) => {
            console.log(currPrevDistance);
            console.log(nextPrevDistance);
            handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
            handler.removeInputAction(
              Cesium.ScreenSpaceEventType.MOUSE_MOVE,
              Cesium.KeyboardEventModifier.ALT
            ); // 解除viewer的MOUSE_MOVE + ALT事件监听器
            handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_UP事件监听器
            handler.removeInputAction(
              Cesium.ScreenSpaceEventType.LEFT_UP,
              Cesium.KeyboardEventModifier.ALT
            ); // 解除viewer的LEFT_UP事件监听器
            // 重新设置实体可拖拽鼠标样式
            setCursorStyle(cursorStyle.value);
            // 解锁相机
            toggleLockCamera(true);
            // 位置改变了
            if (newPosition !== null) {
              // 航点数据
              const routePoint = lineItem.routePoint[routePointIndex];
              const routePositions = lineItem.routePositions;
              updateRouteLine(routeplanId, routePositions, false);
              // 弧度
              const pointCartographic =
                ellipsoid.cartesianToCartographic(newPosition);
              // 经度
              const longitude = Cesium.Math.toDegrees(
                pointCartographic.longitude
              );
              // 纬度
              const latitude = Cesium.Math.toDegrees(
                pointCartographic.latitude
              );
              //
              if (entityType === "rp" && moveType === "rp_mouseMove") {
                // 设置回原高度 -- 拖拽航点会有高度的细微丢失
                pointCartographic.height = routePointHeight;
                const pointCartesian =
                  ellipsoid.cartographicToCartesian(pointCartographic);
                entityDrag.id.position = pointCartesian;
              }
              // 绝对高
              const absoHei = pointCartographic.height;
              // 经纬高
              routePoint.longitude = longitude;
              routePoint.latitude = latitude;
              routePoint.absoHei = absoHei;

              const callbackObj = {
                routeplanId: routeplanId,
                currPoint: {
                  routePointId: routePointId,
                  longitude: longitude,
                  latitude: latitude,
                  absoHei: absoHei,
                  relaHei: routePoint.relaHei,
                  // pointDistance: routePoint.pointDistance,
                  // heading: routePoint.heading,
                },
              };
              if (currPrevDistance !== -1) {
                callbackObj.currPoint.pointDistance = currPrevDistance;
              }

              if (nextPrevDistance !== -1) {
                callbackObj.nextPoint = {};
                callbackObj.nextPoint.routePointId =
                  lineItem.routePoint[routePointIndex + 1].routePointId;
                callbackObj.nextPoint.pointDistance = nextPrevDistance;
              }

              /* 
                                重新计算航向
                                第一个航点：（1）改变本航点航向，指向下一航点
                                            （2）当航点数为2时，改变下一个航点航向，与本航点航向一致
                                第2-（n - 2）个航点：（1）改变上一个航点航向，指向本航点
                                                    （2）改变本航点航向，指向下一个航点
                                第n - 1个航点：（1）改变上一个航点航向，指向本航点
                                              （2）改变本航点航向，指向下一个航点
                                第n个航点：（1）改变上一个航点航向，指向本航点
                                         （2）改变本航点航向，航向与上一个航点一致
                                          (3) 当选中航点为倒数第二个航点时， 改变下一个航点航向，与本航点航向一致
                            */

              const pLen = lineItem.routePoint.length;
              // 需大于等于2个航点需要改变航点
              if (moveType && moveType !== "rp_mouseMove_alt" && pLen >= 2) {
                const currPointId = routePoint.routePointId;

                const prevPointIndex = routePointIndex - 1;
                const prevPoint = lineItem.routePoint[prevPointIndex];

                const nextPointIndex = routePointIndex + 1;
                const nextPoint = lineItem.routePoint[nextPointIndex];
                const nextPointPosi = lineItem.routePositions[nextPointIndex];
                if (routePointIndex === 0) {
                  // 第一个航点
                  const heading = getHeading(newPosition, nextPointPosi);
                  updateEntityHead(currPointId, heading);
                  // 修改
                  const degrees = heading * (180 / Math.PI);
                  callbackObj.currPoint.heading = degrees;
                  if (pLen === 2) {
                    const nextPointId = nextPoint.routePointId;
                    updateEntityHead(nextPointId, heading);
                  }
                } else if (routePointIndex === pLen - 1) {
                  // 最后一个航点
                  const prevPointId = prevPoint.routePointId;
                  const prevPointPosi = lineItem.routePositions[prevPointIndex];
                  const heading = getHeading(prevPointPosi, newPosition);
                  updateEntityHead(prevPointId, heading);
                  updateEntityHead(currPointId, heading);

                  const degrees = heading * (180 / Math.PI);

                  callbackObj.currPoint.heading = degrees;
                  callbackObj.prevPoint = {};
                  callbackObj.prevPoint.routePointId = prevPointId;
                  callbackObj.prevPoint.heading = degrees;
                } else {
                  // 中间航点
                  const prevPointId = prevPoint.routePointId;
                  const prevPointPosi = lineItem.routePositions[prevPointIndex];
                  const headingPrev = getHeading(prevPointPosi, newPosition);
                  updateEntityHead(prevPointId, headingPrev);
                  const headingCurr = getHeading(newPosition, nextPointPosi);
                  updateEntityHead(currPointId, headingCurr);

                  const degreesCurr = headingCurr * (180 / Math.PI);
                  const degreesPrev = headingPrev * (180 / Math.PI);

                  callbackObj.currPoint.heading = degreesCurr;
                  callbackObj.prevPoint = {};
                  callbackObj.prevPoint.routePointId = prevPointId;
                  callbackObj.prevPoint.heading = degreesPrev;
                  if (routePointIndex === pLen - 2) {
                    const nextPointId = nextPoint.routePointId;
                    updateEntityHead(nextPointId, headingCurr);
                  }
                }
              }

              // 查询新地点的地形高度
              const promise = Cesium.sampleTerrainMostDetailed(
                terrainProvider,
                [pointCartographic]
              );
              Promise.resolve(promise).then(function (updatedPositions) {
                // 绝对高 - 地形高 = 相对高
                const relaHei = absoHei - updatedPositions[0].height;
                routePoint.relaHei = relaHei;
                if (entityType === "rp" && moveType === "rp_mouseMove") {
                  pointCartographic.height = updatedPositions[0].height + 0.1;
                  viewer.entities.getById("gp-" + routePointId).position =
                    ellipsoid.cartographicToCartesian(pointCartographic);
                }
                const glEntity = viewer.entities.getById("gl-" + routePointId);
                glEntity.label.text = `海拔高度：${absoHei.toFixed(
                  1
                )}m\n相对高度：${relaHei.toFixed(1)}m`;
                glEntity.polyline.positions = [
                  ellipsoid.cartographicToCartesian(updatedPositions[0]),
                  newPosition,
                ];
                const updateType =
                  moveType === "rp_mouseMove_alt" ? "height" : "move";

                callbackObj.currPoint.relaHei = relaHei;
                leftUpCallback(updateType, callbackObj);
              });
            }
          }, Cesium.ScreenSpaceEventType.LEFT_UP);

          // document.addEventListener("keyup", function (e) {
          //     // console.log(e)
          //     if (e.keyCode === 18) {
          //         handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
          //         toggleLockCamera(true);
          //     }
          //     console.log('document keyup')
          // })

          // // 鼠标弹起事件
          handler.setInputAction(
            () => {
              handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
              handler.removeInputAction(
                Cesium.ScreenSpaceEventType.MOUSE_MOVE,
                Cesium.KeyboardEventModifier.ALT
              ); // 解除viewer的MOUSE_MOVE + ALT事件监听器
              handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_UP事件监听器
              handler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_UP,
                Cesium.KeyboardEventModifier.ALT
              ); // 解除viewer的LEFT_UP事件监听器
              // // 重新设置实体可拖拽鼠标样式
              setCursorStyle(cursorStyle.value);
              toggleLockCamera(true);
              if (newPosition !== null) {
                // 航点数据
                const routePoint = lineItem.routePoint[routePointIndex];
                const routePositions = lineItem.routePositions;
                updateRouteLine(routeplanId, routePositions, false);
                const pointCartographic =
                  ellipsoid.cartesianToCartographic(newPosition);
                const longitude = Cesium.Math.toDegrees(
                  pointCartographic.longitude
                );
                const latitude = Cesium.Math.toDegrees(
                  pointCartographic.latitude
                );
                const absoHei = pointCartographic.height;
                routePoint.longitude = longitude;
                routePoint.latitude = latitude;
                routePoint.absoHei = absoHei;

                const callbackObj = {
                  routeplanId: routeplanId,
                  currPoint: {
                    routePointId: routePointId,
                    longitude: longitude,
                    latitude: latitude,
                    absoHei: absoHei,
                    relaHei: routePoint.relaHei,
                  },
                };
                if (currPrevDistance !== -1) {
                  callbackObj.currPoint.pointDistance = currPrevDistance;
                }

                if (nextPrevDistance !== -1) {
                  callbackObj.nextPoint = {};
                  callbackObj.nextPoint.routePointId =
                    lineItem.routePoint[routePointIndex + 1].routePointId;
                  callbackObj.nextPoint.pointDistance = nextPrevDistance;
                }

                const promise = Cesium.sampleTerrainMostDetailed(
                  terrainProvider,
                  [pointCartographic]
                );
                Promise.resolve(promise).then(function (updatedPositions) {
                  const relaHei = absoHei - updatedPositions[0].height;
                  routePoint.relaHei = relaHei;
                  const glEntity = viewer.entities.getById(
                    "gl-" + routePointId
                  );
                  glEntity.label.text = `海拔高度：${absoHei.toFixed(
                    1
                  )}m\n相对高度：${relaHei.toFixed(1)}m`;
                  glEntity.polyline.positions = [
                    ellipsoid.cartographicToCartesian(updatedPositions[0]),
                    newPosition,
                  ];
                  const updateType =
                    moveType === "rp_mouseMove_alt" ? "height" : "move";
                  callbackObj.currPoint.relaHei = relaHei;
                  leftUpCallback(updateType, callbackObj);
                });
              }
            },
            Cesium.ScreenSpaceEventType.LEFT_UP,
            Cesium.KeyboardEventModifier.ALT
          );
        } else {
          // 恢复原有的样式
          setCursorStyle(cursorStyle.value);
          handler.setInputAction((downE) => {
            // 垂地点的新位置
            const startPosition = viewer.scene.pickPosition(
              downE.startPosition
            );
            const endPosition = viewer.scene.pickPosition(downE.endPosition);
            leftMoveCallback(false, {
              startPosition: startPosition,
              endPosition: endPosition,
            });
          }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

          // 鼠标弹起事件
          handler.setInputAction(() => {
            handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
            handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_UP事件监听器
          }, Cesium.ScreenSpaceEventType.LEFT_UP);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      // function altKeydownHandle(event){
      //     console.log(event)
      // }

      // document.addEventListener("keydown", altKeydownHandle)

      handler.setInputAction((e) => {
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        const entityDrag = viewer.scene.pick(e.position);
        if (entityDrag && entityDrag.id) {
          const entity = entityDrag.id;
          const entityType = entity.entityType;
          if (
            entityType === "rp" ||
            entityType === "gp" ||
            entityType === "gl"
          ) {
            const routeplanId = entity.routeplanId;
            const routePointId = entity.routePointId;
            const rightMenuBox = document.getElementById("rightMenuBox");
            rightMenuBox.style.display = "block";
            rightMenuBox.style.transform = `translate(${e.position.x}px, ${e.position.y}px)`;
            rightMenuBox.setAttribute("data-routeplanId", routeplanId);
            rightMenuBox.setAttribute("data-routePointId", routePointId);
          }
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

      handler.setInputAction((e) => {
        // 不允许双击选中实体
        viewer.trackedEntity = undefined;
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      callback && callback();
    }
    rememberLocation();
    return viewer;
  }

  /* 销毁cesium viewer */
  function destroyCesium(el) {
    document.querySelector("#" + el).innerHTML = "";
    viewer.entities.removeAll();
    viewer = undefined;
  }

  /**
   * @description: 将图片和文字合成新图标使用（参考Cesium源码）
   * @param {*} url：图片地址
   * @param {*} label：文字
   * @param {*} size：画布大小
   * @param {*} fontSize: 字号大小
   * @return {*} 返回canvas
   */
  function combineIconAndLabel(url, label, size, fontSize = 30) {
    // 创建画布对象
    let canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    let ctx = canvas.getContext("2d");

    let promise = new Cesium.Resource.fetchImage(url).then((image) => {
      // 异常判断
      try {
        ctx.drawImage(image, 0, 0);
      } catch (e) {
        console.log(e);
      }

      // 渲染字体
      // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
      ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
      ctx.font = `bold ${fontSize}px Microsoft YaHei`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(label, size / 2, size / 2);

      return canvas;
    });
    return promise;
  }

  // 根据两个坐标点,获取Heading(朝向)
  function getHeading(pointA, pointB) {
    //建立以点A为原点，X轴为east,Y轴为north,Z轴朝上的坐标系
    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
    //向量AB
    const positionvector = Cesium.Cartesian3.subtract(
      pointB,
      pointA,
      new Cesium.Cartesian3()
    );
    //因transform是将A为原点的eastNorthUp坐标系中的点转换到世界坐标系的矩阵
    //AB为世界坐标中的向量
    //因此将AB向量转换为A原点坐标系中的向量，需乘以transform的逆矩阵。
    const vector = Cesium.Matrix4.multiplyByPointAsVector(
      Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()),
      positionvector,
      new Cesium.Cartesian3()
    );
    //归一化
    const direction = Cesium.Cartesian3.normalize(
      vector,
      new Cesium.Cartesian3()
    );
    //heading
    const heading =
      Math.atan2(direction.y, direction.x) - Cesium.Math.PI_OVER_TWO;
    const radian = Cesium.Math.TWO_PI - Cesium.Math.zeroToTwoPi(heading);
    return radian;
  }

  /**
   * 创建垂地点
   * @param {* 世界坐标位置} position   -- Cartesian3
   * @param {* 航线id} routeplanId   -- string
   * @param {* 航点id} routePointId   -- string
   */
  function createGroundPoint(position, routeplanId, routePointId) {
    const id = "gp-" + routePointId;
    const entity = viewer.entities.add({
      id: id,
      position: position,
      billboard: {
        image: require("./../../asset/img/routeLine/groundCircle.png"),
        scale: 0.5,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        HeightReference: Cesium.HeightmapEncoding.CLAMP_TO_GROUND,
      },
      draggable: true,
      entityType: "gp",
      routeplanId: routeplanId,
      routePointId: routePointId,
    });
  }

  /**
   * 创建航点
   * @param {* 世界坐标位置} position   -- Cartesian3
   * @param {* 航线id} routeplanId   -- string
   * @param {* 航点id} routePointId   -- string
   * @param {* 数字} number   -- number | string
   * @param {* 航向} heading   -- radian（弧度可转角度）
   * @param {* 是否选中} isChoose   -- boolean
   */
  function createRoutePoint(
    position,
    routeplanId,
    routePointId,
    number,
    heading,
    isChoose = true
  ) {
    const id = "rp-" + routePointId;
    const entity = viewer.entities.add({
      id: id,
      position: position,
      orientation: Cesium.Transforms.headingPitchRollQuaternion(
        position,
        new Cesium.HeadingPitchRoll(
          heading,
          Cesium.Math.toRadians(0),
          Cesium.Math.toRadians(0)
        )
      ),
      billboard: {
        image: require("./../../asset/img/routeLine/route_icon.png"),
        scale: 1,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        // color: Cesium.Color.fromCssColorString('#00BFFF')
      },
      model: {
        uri: "public/model/chassis.glb",
        // uri: 'public/model/chassis_active.glb',
        scale: 1,
        minimumPixelSize: 50,
      },
      draggable: true,
      entityType: "rp",
      routeplanId: routeplanId,
      routePointId: routePointId,
      number: number,
    });
    let imageUrl = "";
    if (isChoose === true) {
      if (lastChooseRoutId !== "") {
        const lastEntity = viewer.entities.getById("rp-" + lastChooseRoutId);
        if (lastEntity) {
          lastEntity.model.uri = "public/model/chassis.glb";
          combineIconAndLabel(
            require("./../../asset/img/routeLine/route_icon.png"),
            lastEntity.number,
            32,
            14
          ).then((res) => {
            lastEntity.billboard.image = res;
          });
        }
      }
      lastChooseRoutId = routePointId;
      imageUrl = require("./../../asset/img/routeLine/route_icon_light.png");
      entity.model.uri = "public/model/chassis_active.glb";
    } else {
      imageUrl = require("./../../asset/img/routeLine/route_icon.png");
    }
    combineIconAndLabel(imageUrl, number, 32, 14).then((res) => {
      console.log(res);
      entity.billboard.image = res;
    });
  }

  /**
   * 创建垂地线
   * @param {* 垂地点位置} groundP   -- Cartesian3
   * @param {* 航点位置} routeP   -- Cartesian3
   * @param {* 航线id} routeplanId   -- string
   * @param {* 航点id} routePointId   -- string
   * @param {* 航点高度} height   -- number
   * @param {* 文本是否显示} labelShow   -- boolean
   */
  function createGroundLine(
    groundP,
    routeP,
    routeplanId,
    routePointId,
    routeHeight,
    relaHei,
    labelShow = true
  ) {
    const id = "gl-" + routePointId;
    // 求得两点中点
    const midPoint = Cesium.Cartesian3.midpoint(
      groundP,
      routeP,
      new Cesium.Cartesian3()
    );
    const entity = viewer.entities.add({
      id: id,
      position: midPoint,
      polyline: {
        positions: [groundP, routeP],
        material: new Cesium.PolylineDashMaterialProperty({
          color: Cesium.Color.fromCssColorString("#ffffff"),
          dashLength: 8,
        }),
        depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
          color: Cesium.Color.fromCssColorString("#fff000"),
          dashLength: 8,
        }),
      },
      label: {
        show: labelShow,
        text: `海拔高度：${routeHeight.toFixed(
          1
        )}m\n相对高度：${relaHei.toFixed(1)}m`,
        font: "normal 12px MicroSoft YaHei",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.7),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 5000),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        // horizontalOrigin: Cesium.HorizontalOrigin.LEFT
      },
      draggable: true,
      entityType: "gl",
      routeplanId: routeplanId,
      routePointId: routePointId,
    });
    showlabelEntity.push(entity);
  }

  /* 放大地球 */
  function enlargeEarth() {
    let position = viewer.camera.position;
    let cameraHeight =
      viewer.scene.globe.ellipsoid.cartesianToCartographic(position).height;
    // 每次缩小 20 倍，参数可改
    let moveRate = cameraHeight / 5.0;
    viewer.camera.moveForward(moveRate);
  }

  /* 缩小地球 */
  function shrinkEarth() {
    let position = viewer.camera.position;
    let cameraHeight =
      viewer.scene.globe.ellipsoid.cartesianToCartographic(position).height;
    // 每次缩小 20 倍，参数可改
    let moveRate = cameraHeight / 5.0;
    viewer.camera.moveBackward(moveRate);
  }

  /**
   * 创建距离文本s
   * @param {* 文本位置} position   -- Cartesian3
   * @param {* 航线id} routeplanId   -- string
   * @param {* 航点id} routePointId   -- string
   * @param {* 文本} text   -- string
   * @param {* 是否显示} show   -- boolean
   */
  function createText(position, routeplanId, routePointId, text, show = true) {
    const id = "dt-" + routePointId;
    // 求得两点中点
    const entity = viewer.entities.add({
      id: id,
      position: position,
      label: {
        show: show,
        text: text.toFixed(1) + t("42"),
        font: "normal 12px MicroSoft YaHei",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 5000),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      },
      entityType: "dt",
      routeplanId: routeplanId,
      routePointId: routePointId,
    });
    showlabelEntity.push(entity);
  }

  const lineArr = []; //航线列表
  /**
   * 更新航线
   * @param {* 航线id} routeplanId
   * @param {* 航线线段点位列表} routePositions
   * @param {* 是否是动态折线} isDynamic
   */
  function updateRouteLine(routeplanId, routePositions, isDynamic = true) {
    const routeLineEntity = viewer.entities.getById("routeLine-" + routeplanId);
    if (routeLineEntity) {
      // 存在航线 -- 直接刷新
      if (isDynamic) {
        routeLineEntity.polyline.positions = new Cesium.CallbackProperty(
          function () {
            return routePositions;
          },
          false
        ); //防止闪烁，在移动的过程
      } else {
        // 动态折线无法使用depthFailMaterial（深度检测失败材质），也就是地形遮挡折线无法出现
        routeLineEntity.polyline.positions = routePositions;
      }
    } else {
      // 不存在航线 -- 创建航线
      const lineColor = Cesium.Color.fromCssColorString("#1DFF5D");
      viewer.entities.add({
        id: "routeLine-" + routeplanId,
        polyline: {
          width: 4,
          positions: routePositions,
          material: lineColor,
          depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
            color: lineColor,
          }),
        },
      });
    }
  }

  /**
   * 定位航线
   * @param {* 航线id} routeplanId
   */
  function zoomToRouteLine(routeplanId) {
    if (!viewer) return false;
    const routeLineEntity = viewer.entities.getById("routeLine-" + routeplanId);
    if (routeLineEntity) {
      viewer.flyTo(routeLineEntity, {
        offset: new Cesium.HeadingPitchRange(
          0,
          Cesium.Math.toRadians(-15),
          200
        ),
        duration: 2,
      });
    }
  }

  /* 视锥体虚线 */
  function createDashArrowLine(id, positions, color, width = 1) {
    // 虚线箭头材质
    const polyline = viewer.entities.add({
      id: id,
      polyline: {
        positions: positions,
        width: width,
        material: new ufi3d.PolylineDashArrowMaterialProperty({
          color: Cesium.Color.fromCssColorString(color),
          gapColor: Cesium.Color.TRANSPARENT,
        }),
      },
    });
    return polyline;
  }

  let frustum = null;
  let frustumCamera = null;
  const frustumFar = 100; //视锥体拍摄距离
  const frustumFov = 100; //视锥体拍摄角度（垂直/水平）
  /* 创建视锥体 */
  function createFrustum(
    longitude,
    latitude,
    height,
    heading,
    pitch,
    roll,
    fovAngle
  ) {
    const origin = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
    const headingPitchRoll = new Cesium.HeadingPitchRoll(
      Cesium.Math.toRadians(heading),
      Cesium.Math.toRadians(pitch),
      Cesium.Math.toRadians(roll)
    );
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(
      origin,
      headingPitchRoll
    );

    frustum = new ufi3d.CreateFrustum(viewer, {
      position: origin,
      orientation: orientation,
      fov: frustumFov / fovAngle,
      near: 0.001,
      far: 100,
      angle1: 100,
      angle2: 100,
      faceColor: "rgba(255, 204, 26, 0.3)",
      outlineColor: "#FFFF00",
    });

    frustumCamera = new Cesium.Camera(viewer.scene);
    frustumCamera.flyTo({
      destination: origin,
      orientation: {
        heading: Cesium.Math.toRadians(Number(heading) + 90),
        pitch: Cesium.Math.toRadians(Number(pitch) + 90),
        roll: Cesium.Math.toRadians(roll),
      },
      duration: 0,
    });
    const direction = frustumCamera.direction;
    const ray = new Cesium.Ray(origin, direction);
    const endposition = Cesium.Ray.getPoint(
      ray,
      frustumFar,
      new Cesium.Cartesian3()
    );
    createDashArrowLine(
      "dashArrowFrustumLine",
      [origin, endposition],
      "#FFFF00",
      8
    );
  }

  /* 修改视锥体参数 */
  function updateFrustum(
    longitude,
    latitude,
    height,
    heading,
    pitch,
    roll,
    fovAngle
  ) {
    const origin = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
    const headingPitchRoll = new Cesium.HeadingPitchRoll(
      Cesium.Math.toRadians(heading),
      Cesium.Math.toRadians(pitch),
      Cesium.Math.toRadians(roll)
    );
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(
      origin,
      headingPitchRoll
    );

    frustum.updateStyle({
      position: origin,
      orientation: orientation,
      fov: frustumFov / fovAngle,
    });
    frustumCamera.flyTo({
      destination: origin,
      orientation: {
        heading: Cesium.Math.toRadians(Number(heading) + 90),
        pitch: Cesium.Math.toRadians(Number(pitch) + 90),
        roll: Cesium.Math.toRadians(roll),
      },
      duration: 0,
    });
    const direction = frustumCamera.direction;
    const ray = new Cesium.Ray(origin, direction);
    const endposition = Cesium.Ray.getPoint(
      ray,
      frustumFar,
      new Cesium.Cartesian3()
    );
    viewer.entities.getById("dashArrowFrustumLine").show = true;
    viewer.entities.getById("dashArrowFrustumLine").polyline.positions = [
      origin,
      endposition,
    ];
  }

  /**
   * 根据航点id修改航向角
   * @param {* 航点id} routePointId
   * @param {* 航向} heading -- radian(弧度)
   * @param {* 数值类型} numType -- （radian 弧度 | angle 角度）
   */
  function updateEntityHead(routePointId, heading, numType = "radian") {
    const routePointEntity = viewer.entities.getById("rp-" + routePointId);
    if (routePointEntity) {
      const center = routePointEntity.position._value;
      routePointEntity.orientation =
        Cesium.Transforms.headingPitchRollQuaternion(
          center,
          new Cesium.HeadingPitchRoll(
            numType === "radian" ? heading : Cesium.Math.toRadians(heading),
            Cesium.Math.toRadians(0),
            Cesium.Math.toRadians(0)
          )
        );
    }
  }

  /**
   * 添加一个航点  -- 通过垂足点世界坐标添加
   * @param {* 航线id} routeplanId  -- uuid
   * @param {* 空间坐标} position  -- Cartesian3
   * @param {* 航点类型} routeType  -- string（route-航点 | takeOffPoint-起飞点）
   * @param {* 高度模式} heightType  -- string（absu-绝对高度模式 | rela-相对高度模式）
   * @param {* 高度数值} heightNum  -- number
   * @param {* 序号} serialNum  -- number  (不传就是默认最后一个点)
   * @return 航点信息
   */
  function addRoutePoint(
    routeplanId,
    position,
    routeType,
    heightType,
    heightNum,
    serialNum
  ) {
    let routePoint = [];
    let routePositions = [];
    const item = lineArr.find((i) => {
      return (i.routeplanId = routeplanId);
    });
    if (item) {
      routePoint = item.routePoint;
      routePositions = item.routePositions;
    } else {
      // 不存在该航线
      lineArr.push({
        routeplanId: routeplanId,
        routePoint: [],
        routePositions: [],
      });
      routePoint = lineArr[lineArr.length - 1].routePoint;
      routePositions = lineArr[lineArr.length - 1].routePositions;
    }
    clearLabelShow();
    if (routeType === "route") {
      // 普通航点
      // 椭球
      const ellipsoid = viewer.scene.globe.ellipsoid;
      // 转为弧度
      const cartographic = ellipsoid.cartesianToCartographic(position); //转弧度
      // 地形高
      const terrainHei = cartographic.height;
      // 航点的高度
      let routePointHei = 0;
      let relaHei = 0;
      if (heightType === "absu") {
        // 绝对高度模式 -- 直接为航点的高度
        routePointHei = heightNum;
        relaHei = heightNum - terrainHei;
      } else {
        // 相对高度模式 -- 绝对高度 = 相对高度 + 地形高
        routePointHei = heightNum + terrainHei;
        relaHei = heightNum;
      }
      cartographic.height = routePointHei;
      // 航点的Cartesian3世界坐标
      const routePosition = ellipsoid.cartographicToCartesian(cartographic); //转世界坐标
      if (routePositions.length > 0) {
        const lastRoutePosition = routePositions[routePositions.length - 1]; //最后一个点
        if (
          routePosition.x === lastRoutePosition.x &&
          routePosition.y === lastRoutePosition.y &&
          routePosition.z === lastRoutePosition.z
        ) {
          // 防止与上个点坐标重复 -- 疯狂点击
          return false;
        }
      }
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      // 航点的id
      const routePointId = uuidv4();
      createGroundPoint(position, routeplanId, routePointId);
      createGroundLine(
        position,
        routePosition,
        routeplanId,
        routePointId,
        routePointHei,
        relaHei
      );
      const returnRouteInfo = {
        routePointId: routePointId, //航点id
        longitude: longitude, // 经度
        latitude: latitude, //纬度
        absoHei: routePointHei, //绝对高
        relaHei: relaHei, // 相对高
        pointDistance: 0, //与上个点的距离
        heading: 0, //飞行棋偏航角
      };
      currPointId = routePointId;
      if (serialNum) {
        createRoutePoint(
          routePosition,
          routeplanId,
          routePointId,
          serialNum,
          0
        );
        updateRouteLine(routeplanId, routePositions, false);
      } else {
        routePositions.push(routePosition);
        let heading = 0;
        if (routePositions.length >= 2) {
          const prevPosition = routePositions[routePositions.length - 2];
          const midPosition = Cesium.Cartesian3.midpoint(
            prevPosition,
            routePosition,
            new Cesium.Cartesian3()
          );
          const distance = Cesium.Cartesian3.distance(
            prevPosition,
            routePosition
          );
          createText(midPosition, routeplanId, routePointId, distance);
          returnRouteInfo.pointDistance = distance;
          heading = getHeading(
            routePositions[routePositions.length - 2],
            routePosition
          );
          const lastPointItem = routePoint[routePoint.length - 1];
          updateEntityHead(lastPointItem.routePointId, heading);
          const degrees = heading * (180 / Math.PI);
          returnRouteInfo.heading = degrees;
        }
        createRoutePoint(
          routePosition,
          routeplanId,
          routePointId,
          routePoint.length + 1,
          heading
        );
        updateRouteLine(routeplanId, routePositions, false);
        routePoint.push(returnRouteInfo);
      }
      return returnRouteInfo;
    } else {
      // 起始点
      const returnRouteInfo = {
        routePointId: 10, //航点id
        longitude: 10, // 经度
        latitude: 10, //纬度
        absoHei: 10, //绝对高
        relaHei: 10, // 相对高
        preDistance: 10, //与上个点的距离
        heading: 0, //飞行棋偏航角
      };
      return returnRouteInfo;
    }
  }

  /*
   * 批量增加航点
   */
  /**
   * 批量增加航点  -- 通过经纬度位置添加
   * @param {* 航线id} routeplanId  -- uuid
   * @param {* 航点列表} routeList  -- Array
   * @return 航点信息
   */
  function batchAddRoutePoint(routeplanId, routeList) {
    let routePoint = [];
    let routePositions = [];
    const item = lineArr.find((i) => {
      return (i.routeplanId = routeplanId);
    });
    if (item) {
      routePoint = item.routePoint;
      routePositions = item.routePositions;
    } else {
      // 不存在该航线
      lineArr.push({
        routeplanId: routeplanId,
        routePoint: [],
        routePositions: [],
      });
      routePoint = lineArr[lineArr.length - 1].routePoint;
      routePositions = lineArr[lineArr.length - 1].routePositions;
    }

    const routeLen = routePositions.length;
    for (let i = 0; i < routeList.length; i++) {
      const item = routeList[i];
      const lng = item.lng; //经度
      const lat = item.lat; //纬度
      const alt = item.alt; //高度
      const relaHei = item.relAlt; //相对高
      const routePointId = item.id; //航点id

      const routePosi = Cesium.Cartesian3.fromDegrees(lng, lat, alt); //航点位置 - 笛卡尔坐标
      // 地形高
      const terrainHei = Number(alt) - Number(relaHei);
      const footPosi = Cesium.Cartesian3.fromDegrees(lng, lat, terrainHei); //垂足点位置 - 笛卡尔坐标
      createGroundPoint(footPosi, routeplanId, routePointId); //创建垂足点
      createGroundLine(
        footPosi,
        routePosi,
        routeplanId,
        routePointId,
        alt,
        relaHei,
        false
      ); //创建垂足线

      const returnRouteInfo = {
        routePointId: routePointId, //航点id
        longitude: lng, // 经度
        latitude: lat, //纬度
        absoHei: alt, //绝对高
        relaHei: relaHei, // 相对高
        pointDistance: 0, //与上个点的距离
        heading: 0, //飞行棋偏航角
      };
      const routeSerialNum = routeLen + i + 1;
      routePositions.push(routePosi);
      let heading = 0;
      if (routePositions.length >= 2) {
        const prevPosition = routePositions[routePositions.length - 2];
        const midPosition = Cesium.Cartesian3.midpoint(
          prevPosition,
          routePosi,
          new Cesium.Cartesian3()
        );
        const distance = Cesium.Cartesian3.distance(prevPosition, routePosi);
        createText(midPosition, routeplanId, routePointId, distance, false);
        returnRouteInfo.pointDistance = distance;
        heading = getHeading(prevPosition, routePosi);
        const lastPointItem = routePoint[routePoint.length - 1];
        updateEntityHead(lastPointItem.routePointId, heading);
        const degrees = heading * (180 / Math.PI);
        returnRouteInfo.heading = degrees;
      }
      createRoutePoint(
        routePosi,
        routeplanId,
        routePointId,
        routeSerialNum,
        heading,
        false
      );
      updateRouteLine(routeplanId, routePositions, false);
      routePoint.push(returnRouteInfo);
    }
  }

  /**
   * 获取所有航线
   * @return 航线id列表 Array
   */
  function getAllLine() {
    const lineIdArr = [];
    for (let i = 0; i < lineArr.length; i++) {
      lineIdArr.push(lineArr[i].routeplanId);
    }
    return lineIdArr;
  }

  /**
   * 获取航线下航点信息
   * @param {* 航线id} routeplanId  -- uuid
   * @return 航点列表 Array
   */
  function getLinePoint(routeplanId) {
    const index = lineArr.findIndex((i) => {
      return i.routeplanId === routeplanId;
    });
    if (index !== -1) {
      return lineArr[index].routePoint;
    } else {
      return [];
    }
  }

  /**
   * 删除航线
   * @param {* 航线id} routeplanId  -- uuid
   * @return 删除成功与否 boolean
   */
  function delLine(routeplanId) {
    if (!routeplanId) {
      return false;
    }
    const index = lineArr.findIndex((i) => {
      return i.routeplanId === routeplanId;
    });
    if (index !== -1) {
      const lineItem = lineArr[index];
      const routePoint = lineItem.routePoint;
      for (let i = 0; i < routePoint.length; i++) {
        const routePointId = routePoint[i].routePointId;
        viewer.entities.removeById("gp-" + routePointId);
        viewer.entities.removeById("rp-" + routePointId);
        viewer.entities.removeById("gl-" + routePointId);
        viewer.entities.removeById("dt-" + routePointId);
      }
      viewer.entities.removeById("routeLine-" + lineItem.routeplanId);
      lineArr.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }

  /**
   * 删除航线中的航点
   * @param {* 航线id} routeplanId  -- uuid
   * @param {* 航点id} routePointId  -- uuid
   * @return 删除成功与否 boolean
   */
  function delLinePoint(routeplanId, routePointId) {
    if (!routeplanId || !routePointId) {
      return false;
    }
    // 线段
    const line = lineArr.find((i) => {
      return i.routeplanId === routeplanId;
    });
    // console.log(line)
    if (line) {
      // 点
      const index = line.routePoint.findIndex((i) => {
        return i.routePointId === routePointId;
      });
      // console.log(index)
      if (index === -1) {
        return false;
      }
      viewer.entities.removeById("gp-" + routePointId);
      viewer.entities.removeById("rp-" + routePointId);
      viewer.entities.removeById("gl-" + routePointId);
      if (index === line.routePoint.length - 1) {
        // 最后一个
        viewer.entities.removeById("dt-" + routePointId);
      }
      if (index === 0) {
        // 第一个航点 -- 删除与后一个的距离
        const nextRoutePoint = line.routePoint[index + 1];
        if (nextRoutePoint) {
          // 有可能没有下一个航点
          viewer.entities.removeById("dt-" + nextRoutePoint.routePointId);
        }
      } else if (index === line.routePoint.length - 1) {
        // 最后一个航点 -- 删除与前一个的距离
        viewer.entities.removeById("dt-" + routePointId);
      } else {
        // 中间航点 -- 删除与前一个的距离，更新与后一个的距离
        viewer.entities.removeById("dt-" + routePointId);
        const nextRoutePoint = line.routePoint[index + 1];
        // 前一个点的位置
        const prevPosition = line.routePositions[index - 1];
        // 后一个点的位置
        const nextPosition = line.routePositions[index + 1];
        if (nextRoutePoint && prevPosition && nextPosition) {
          // 有可能没有下一个航点
          const midPosition = Cesium.Cartesian3.midpoint(
            prevPosition,
            nextPosition,
            new Cesium.Cartesian3()
          );
          const distance = Cesium.Cartesian3.distance(
            prevPosition,
            nextPosition
          );
          const nextRoutePointEntity = viewer.entities.getById(
            "dt-" + nextRoutePoint.routePointId
          );
          nextRoutePointEntity.position = midPosition;
          nextRoutePointEntity.label.text = distance.toFixed(1) + t("42");
          nextRoutePoint.pointDistance = distance;
        }
      }
      const delBeforeLen = line.routePoint.length;
      line.routePoint.splice(index, 1);
      line.routePositions.splice(index, 1);
      // 更新航线
      updateRouteLine(routeplanId, line.routePositions, false);
      // 更改后面的航点次序
      for (let i = index; i < line.routePoint.length; i++) {
        const routePointEntity = viewer.entities.getById(
          "rp-" + line.routePoint[i].routePointId
        );
        routePointEntity.number = i + 1;
        const iconType =
          line.routePoint[i].routePointId === currPointId
            ? "route_icon_light"
            : "route_icon";
        combineIconAndLabel(
          require("./../../asset/img/routeLine/" + iconType + ".png"),
          i + 1,
          32,
          14
        ).then((res) => {
          routePointEntity.billboard.image = res;
        });
      }
      /* 
                重新计算航向
                删除航点索引
                第一个航点：do nothing
                最后一个航点：（1）删除后的最后一个航点航向与上个点的航向一致
                中间航点：（1）上一个航点航低昂指向当前删除航点索引的航点
                        （2）如果是倒数第二个，当前索引航点航向与上个航点的航向一致
            */

      const callbackObj = {};

      const pLen = line.routePoint.length;
      // 删除后少于一个航点无需改变航向
      if (pLen >= 2) {
        if (index === 0) {
          // do nothing
        } else if (index === delBeforeLen - 1) {
          const currPoint = line.routePoint[pLen - 1];
          const currPointId = currPoint.routePointId;
          const currPointPosi = line.routePositions[pLen - 1];
          const prevPointPosi = line.routePositions[pLen - 2];

          const heading = getHeading(prevPointPosi, currPointPosi);
          updateEntityHead(currPointId, heading);

          callbackObj.currPoint = {};
          callbackObj.currPoint.routePointId = currPointId;
          const degrees = heading * (180 / Math.PI);
          callbackObj.currPoint.heading = degrees;
        } else {
          const currPointPosi = line.routePositions[index];

          const prevPoint = line.routePoint[index - 1];
          const prevPointId = prevPoint.routePointId;
          const prevPointPosi = line.routePositions[index - 1];
          const heading = getHeading(prevPointPosi, currPointPosi);
          updateEntityHead(prevPointId, heading);
          callbackObj.prevPoint = {};
          callbackObj.prevPoint.routePointId = prevPointId;
          const degrees = heading * (180 / Math.PI);
          callbackObj.prevPoint.heading = degrees;
          if (index === delBeforeLen - 2) {
            // 倒数第二个
            const currPoint = line.routePoint[index];
            const currPointId = currPoint.routePointId;
            updateEntityHead(currPointId, heading);

            callbackObj.currPoint = {};
            callbackObj.currPoint.routePointId = currPointId;
            callbackObj.currPoint.heading = degrees;
          }
        }
      }
      return callbackObj;
    } else {
      return false;
    }
  }

  /**
   * 根据线段id，点id获得点信息
   * @param {* 航线id} routeplanId  -- uuid
   * @param {* 航点id} routePointId  -- uuid
   * @return 点信息 object | undefined
   */
  function getPointById(routeplanId, routePointId) {
    if (!routeplanId || !routePointId) {
      return "undefined";
    }
    // 线段
    const line = lineArr.find((i) => {
      return i.routeplanId === routeplanId;
    });
    if (line) {
      // 点
      const point = line.routePoint.find((i) => {
        return i.routePointId === routePointId;
      });
      return point;
    } else {
      return "undefined";
    }
  }

  /**
   * 根据线段id获得线段长度
   * @param {* 航线id} routeplanId  -- uuid
   * @return 线段长度 number
   */
  function getLineLength(routeplanId) {
    if (!routeplanId) {
      return 0;
    }
    // 线段
    const line = lineArr.find((i) => {
      return i.routeplanId === routeplanId;
    });
    if (line) {
      // 点
      let totalLength = 0;
      const routePoint = line.routePoint;
      for (let i = 0; i < routePoint.length; i++) {
        totalLength += routePoint[i].pointDistance;
      }
      return totalLength;
    } else {
      return 0;
    }
  }

  /**
   * 修改点
   * @param {* 航线id} routeplanId  -- uuid
   * @param {* 航点id} routePointId  -- uuid
   * @param {* 要修改的数据对象} option  -- object
   *        @param  {* 要修改的数据对象} longitude  -- 经度
   *        @param  {* 要修改的数据对象} latitude  -- 纬度
   *        @param  {* 要修改的数据对象} absoHei  -- 绝对高
   *        @param  {* 要修改的数据对象} relaHei  -- 相对高
   *        @param  {* 要修改的数据对象} heading  -- 偏航角
   * @param callback  回调函数
   * @return 修改成功与否  boolean
   */
  function updatePoint(routeplanId, routePointId, option, callback) {
    // 线段
    const lineItem = lineArr.find((i) => {
      return i.routeplanId === routeplanId;
    });
    if (!lineItem) return false;
    // 点
    const routePointIndex = lineItem.routePoint.findIndex((i) => {
      return i.routePointId === routePointId;
    });
    if (routePointIndex === -1) return false;
    const point = lineItem.routePoint[routePointIndex];
    // 修改点的位置信息
    const ellipsoid = viewer.scene.globe.ellipsoid; //椭球体
    if (
      typeof option.longitude !== "undefined" &&
      typeof option.latitude !== "undefined" &&
      typeof option.absoHei !== "undefined" &&
      typeof option.relaHei !== "undefined"
    ) {
      // 修改地球上点的位置
      const groundCartographic = Cesium.Cartographic.fromDegrees(
        option.longitude,
        option.latitude
      );
      const promise = Cesium.sampleTerrainMostDetailed(terrainProvider, [
        groundCartographic,
      ]);
      Promise.resolve(promise).then(function (updatedPositions) {
        const terrainHei = updatedPositions[0].height;
        groundCartographic.height = terrainHei;
        const routeCartographic = Cesium.Cartographic.fromDegrees(
          option.longitude,
          option.latitude,
          option.absoHei
        );
        const groundCartesian =
          ellipsoid.cartographicToCartesian(groundCartographic);
        const newRPosition =
          ellipsoid.cartographicToCartesian(routeCartographic);
        const midPoint = Cesium.Cartesian3.midpoint(
          groundCartesian,
          newRPosition,
          new Cesium.Cartesian3()
        );

        const gpEntity = viewer.entities.getById("gp-" + routePointId);
        const rpEntity = viewer.entities.getById("rp-" + routePointId);
        const glEntity = viewer.entities.getById("gl-" + routePointId);

        gpEntity.position = groundCartesian;
        rpEntity.position = newRPosition;
        glEntity.position = midPoint;
        glEntity.polyline.positions = [groundCartesian, newRPosition];
        glEntity.label.text = `海拔高度：${option.absoHei.toFixed(
          1
        )}m\n相对高度：${option.relaHei.toFixed(1)}m`;

        const routePositions = lineItem.routePositions;
        routePositions[routePointIndex] = newRPosition;
        updateRouteLine(routeplanId, routePositions, false);

        if (routePositions.length >= 2) {
          // 大于等于2个航点才需要更新距离
          if (routePointIndex === 0) {
            // 第一个航点 -- 更新与后一个的距离
            const nextPosition = routePositions[routePointIndex + 1];
            const nextRoutePoint = lineItem.routePoint[routePointIndex + 1];
            const midPosition = Cesium.Cartesian3.midpoint(
              newRPosition,
              nextPosition,
              new Cesium.Cartesian3()
            );
            const distance = Cesium.Cartesian3.distance(
              newRPosition,
              nextPosition
            );
            const distanceTextEntity = viewer.entities.getById(
              "dt-" + nextRoutePoint.routePointId
            );
            distanceTextEntity.label.text = distance.toFixed(1) + t("42");
            distanceTextEntity.position = new Cesium.CallbackProperty(
              function () {
                return midPosition;
              },
              false
            ); //防止闪烁，在移动的过程
            nextRoutePoint.pointDistance = distance;
          } else if (routePointIndex === routePositions.length - 1) {
            // 最后一个航点 -- 更新与前一个的距离
            const prevPosition = routePositions[routePointIndex - 1];
            const midPosition = Cesium.Cartesian3.midpoint(
              prevPosition,
              newRPosition,
              new Cesium.Cartesian3()
            );
            const distance = Cesium.Cartesian3.distance(
              prevPosition,
              newRPosition
            );
            const distanceTextEntity = viewer.entities.getById(
              "dt-" + routePointId
            );
            distanceTextEntity.label.text = distance.toFixed(1) + t("42");
            distanceTextEntity.position = new Cesium.CallbackProperty(
              function () {
                return midPosition;
              },
              false
            ); //防止闪烁，在移动的过程
            lineItem.routePoint[routePointIndex].pointDistance = distance;
          } else {
            // 中间航点 -- 更新前一个和后一个的距离
            const prevPosition = routePositions[routePointIndex - 1];
            const nextPosition = routePositions[routePointIndex + 1];
            const nextRoutePoint = lineItem.routePoint[routePointIndex + 1];

            const prevMidPosition = Cesium.Cartesian3.midpoint(
              prevPosition,
              newRPosition,
              new Cesium.Cartesian3()
            );
            const prevDistance = Cesium.Cartesian3.distance(
              prevPosition,
              newRPosition
            );

            const nextMidPosition = Cesium.Cartesian3.midpoint(
              newRPosition,
              nextPosition,
              new Cesium.Cartesian3()
            );
            const nextDistance = Cesium.Cartesian3.distance(
              newRPosition,
              nextPosition
            );

            const prevDistanceTextEntity = viewer.entities.getById(
              "dt-" + routePointId
            );
            prevDistanceTextEntity.label.text = prevDistance.toFixed(1) + t("42");
            prevDistanceTextEntity.position = new Cesium.CallbackProperty(
              function () {
                return prevMidPosition;
              },
              false
            ); //防止闪烁，在移动的过程
            lineItem.routePoint[routePointIndex].pointDistance = prevDistance;

            const nextDistanceTextEntity = viewer.entities.getById(
              "dt-" + nextRoutePoint.routePointId
            );
            nextDistanceTextEntity.label.text = nextDistance.toFixed(1) + t("42");
            nextDistanceTextEntity.position = new Cesium.CallbackProperty(
              function () {
                return nextMidPosition;
              },
              false
            ); //防止闪烁，在移动的过程
            nextRoutePoint.pointDistance = nextDistance;
          }
        }

        // 同步航线对象中的数据
        point.longitude = option.longitude;
        point.latitude = option.latitude;
        // 相对高补差额
        point.relaHei = option.relaHei;
        point.absoHei = option.absoHei;

        /* 
                    重新计算航向
                    第一个航点：（1）改变本航点航向，指向下一航点
                                （2）当航点数为2时，改变下一个航点航向，与本航点航向一致
                    第2-（n - 2）个航点：（1）改变上一个航点航向，指向本航点
                                        （2）改变本航点航向，指向下一个航点
                    第n - 1个航点：（1）改变上一个航点航向，指向本航点
                                    （2）改变本航点航向，指向下一个航点
                    第n个航点：（1）改变上一个航点航向，指向本航点
                            （2）改变本航点航向，航向与上一个航点一致
                            (3) 当选中航点为倒数第二个航点时， 改变下一个航点航向，与本航点航向一致

                    面板修改数据为经纬度，则会传递callback函数，才需重新计算航向
                */

        if (callback) {
          const callbackObj = {
            routeplanId: routeplanId,
            currPoint: {
              routePointId: routePointId,
            },
          };

          const pLen = lineItem.routePoint.length;
          // 需大于等于2个航点需要改变航点
          if (pLen >= 2) {
            const currPointId = point.routePointId;

            const prevPointIndex = routePointIndex - 1;
            const prevPoint = lineItem.routePoint[prevPointIndex];

            const nextPointIndex = routePointIndex + 1;
            const nextPoint = lineItem.routePoint[nextPointIndex];
            const nextPointPosi = lineItem.routePositions[nextPointIndex];
            if (routePointIndex === 0) {
              // 第一个航点
              const heading = getHeading(newRPosition, nextPointPosi);
              updateEntityHead(currPointId, heading);
              point.heading = heading;
              // 修改
              const degrees = heading * (180 / Math.PI);
              callbackObj.currPoint.heading = degrees;
              if (pLen === 2) {
                const nextPointId = nextPoint.routePointId;
                updateEntityHead(nextPointId, heading);
                callbackObj.nextPoint = {};
                callbackObj.nextPoint.routePointId = nextPointId;
                callbackObj.nextPoint.heading = heading;
              }
            } else if (routePointIndex === pLen - 1) {
              // 最后一个航点
              const prevPointId = prevPoint.routePointId;
              const prevPointPosi = lineItem.routePositions[prevPointIndex];
              const heading = getHeading(prevPointPosi, newRPosition);
              updateEntityHead(prevPointId, heading);
              updateEntityHead(currPointId, heading);
              point.heading = heading;

              const degrees = heading * (180 / Math.PI);

              callbackObj.currPoint.heading = degrees;
              callbackObj.prevPoint = {};
              callbackObj.prevPoint.routePointId = prevPointId;
              callbackObj.prevPoint.heading = degrees;
            } else {
              // 中间航点
              const prevPointId = prevPoint.routePointId;
              const prevPointPosi = lineItem.routePositions[prevPointIndex];
              const headingPrev = getHeading(prevPointPosi, newRPosition);
              updateEntityHead(prevPointId, headingPrev);
              const headingCurr = getHeading(newRPosition, nextPointPosi);
              updateEntityHead(currPointId, headingCurr);
              point.heading = headingCurr;

              const degreesCurr = headingCurr * (180 / Math.PI);
              const degreesPrev = headingPrev * (180 / Math.PI);

              callbackObj.currPoint.heading = degreesCurr;
              callbackObj.prevPoint = {};
              callbackObj.prevPoint.routePointId = prevPointId;
              callbackObj.prevPoint.heading = degreesPrev;
              if (routePointIndex === pLen - 2) {
                const nextPointId = nextPoint.routePointId;
                updateEntityHead(nextPointId, headingCurr);
                callbackObj.nextPoint = {};
                callbackObj.nextPoint.routePointId = nextPointId;
                callbackObj.nextPoint.heading = headingCurr;
              }
            }
          }
          callback(callbackObj);
        }
      });
    } else {
      return false;
    }
  }

  /**
   * 打开点视角
   * @param {* 经度} longitude  -- number
   * @param {* 纬度} latitude  -- number
   * @param {* 高度} height  -- number
   * @param {* 航向角} heading  -- number
   * @param {* 俯仰角} pitch  -- number
   * @param {* 翻滚角} roll  -- number
   * @param {* 变焦倍数} fovAngle  -- number
   * @return
   */
  function openPointAngle(
    longitude,
    latitude,
    height,
    heading,
    pitch,
    roll,
    fovAngle = 1
  ) {
    if (!longitude && !latitude && !height && !heading && !pitch && !roll) {
      return;
    }
    if (fovAngle <= 0) {
      return;
    }
    if (frustum) {
      // 已存在视锥体 -- 修改参数
      updateFrustum(
        longitude,
        latitude,
        height,
        Number(heading) - 90,
        pitch,
        roll,
        fovAngle
      );
    } else {
      // 不存在视锥体 -- 创建视锥体
      createFrustum(
        longitude,
        latitude,
        height,
        Number(heading) - 90,
        pitch,
        roll,
        fovAngle
      );
    }
  }

  /* 关闭所有打开的点视角 (关闭视锥体) */
  function closeAngle() {
    if (frustum) {
      frustum.clear();
      viewer.entities.getById("dashArrowFrustumLine").show = false;
    }
  }

  /**
   * 相机定位
   * @param {* 位置} position  Array
   */
  function cameraPosition(position) {
    if (position.length < 0) {
      return;
    }
    const arr = CoordTransform.GCJ02ToWGS84(
      Number(position[0]),
      Number(position[1])
    );
    const center = Cesium.Cartesian3.fromDegrees(arr[0], arr[1], 1000);
    viewer.camera.flyTo({
      destination: center,
      orientation: {
        heading: Cesium.Math.toRadians(0),
        pitch: Cesium.Math.toRadians(-90.0),
        roll: 0.0,
      },
    });
  }

  const canMark = ref(false);
  const dragType = ref("");
  let currMarkPoint = null;
  let markName = "";
  let markColor = "#de2d2d";
  /**
   * 注册标记点事件
   * @param {* 回调函数} callback
   *      @param {* 经度} lng
   *      @param {* 纬度} lat
   *      @param {* 高度} height
   */
  function registerMarkPoint(markCallback, routeCallback) {
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    handler.setInputAction((e) => {
      // 返回具有' primitive'属性的对象（是否拾取到实体）
      if (canMark.value === true) {
        const position = viewer.scene.pickPosition(e.endPosition);
        const cartographic = Cesium.Cartographic.fromCartesian(
          position,
          viewer.scene.globe.ellipsoid
        );
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        if (dragType.value === "route") {
          // 航点拖拽
          routeCallback(lng, lat, cartographic.height);
        } else {
          // 标记点
          // const position =  viewer.camera.pickEllipsoid(e.endPosition, viewer.scene.globe.ellipsoid)
          if (currMarkPoint !== null) {
            currMarkPoint.position = position;
          } else {
            const color = Cesium.Color.fromCssColorString(markColor);
            currMarkPoint = viewer.entities.add({
              position: position,
              billboard: {
                scale: 0.5,
                image: require("./../../asset/img/centerPlan/map_mark.png"),
                color: color,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              },
              label: {
                text: markName,
                font: "12px sans-serif",
                fillColor: color,
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(14, -10),
              },
            });
          }
          // 位置回调
          markCallback(lng, lat, cartographic.height);
        }
        canMark.value = false;
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  /* 取消注册标记点事件 */
  function unRegisterMarkPoint() {
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
  }

  /**
   * 提交标记
   * @param {* 经度} lng
   * @param {* 纬度} lat
   * @param {* 回调函数} callback
   *      @param {* 高度} height
   */
  function comfirmMarkPoint(lng, lat, callback) {
    if (currMarkPoint) {
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const oldPosition = currMarkPoint.position._value;
      const hei = Cesium.Cartographic.fromCartesian(
        oldPosition,
        ellipsoid
      ).height;
      callback(hei);
      currMarkPoint = null;
    } else {
      if (lng && lat) {
        const color = Cesium.Color.fromCssColorString(markColor);
        const cartographic = Cesium.Cartographic.fromDegrees(
          Number(lng),
          Number(lat)
        );
        // 地形提供者
        const terrainProvider = setTerrain();
        const promise = Cesium.sampleTerrainMostDetailed(terrainProvider, [
          cartographic,
        ]);
        // 获取经纬度所在点高度
        Promise.resolve(promise).then(function (updatedPositions) {
          console.log(updatedPositions[0].height);
          const hei = updatedPositions[0].height;
          const position = Cesium.Cartesian3.fromDegrees(
            Number(lng),
            Number(lat),
            hei
          );
          viewer.entities.add({
            position: position,
            billboard: {
              scale: 0.5,
              image: require("./../../asset/img/centerPlan/map_mark.png"),
              color: color,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            },
            label: {
              text: markName,
              font: "12px sans-serif",
              fillColor: color,
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(14, -10),
            },
          });
          callback(hei);
          console.log(t("66"));
        });
      }
    }
  }

  /**
   * 修改当前标记信息
   * @param {* 选项} option
   *      @param {* 经度} lng
   *      @param {* 纬度} lat
   *      @param {* 文本} text
   *      @param {* 颜色} color
   */
  function updateCurrMark(option) {
    if (option.text) {
      markName = option.text;
    }
    if (option.color) {
      markColor = option.color;
    }
    if (currMarkPoint !== null) {
      if (option.lng && option.lat) {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const oldPosition = currMarkPoint.position._value;
        const cartographic = Cesium.Cartographic.fromCartesian(
          oldPosition,
          ellipsoid
        );
        const markHei = cartographic.height;
        currMarkPoint.position = Cesium.Cartesian3.fromDegrees(
          Number(option.lng),
          Number(option.lat),
          markHei,
          ellipsoid
        );
      }
      currMarkPoint.label.text = markName;
      const color = Cesium.Color.fromCssColorString(markColor);
      currMarkPoint.billboard.color = color;
      currMarkPoint.label.fillColor = color;
    }
  }

  /**
   * 定位影像图
   * @param {* 影像图id} id
   */
  function zoomToImagery(id) {
    customImagery.zoomToImagery(id);
  }

  /**
   * 定位影像图
   * @param {* 图层id} id
   */
  function zoomTo3DTiles(id) {
    pointCloud.jumpCloudPoint(id);
  }

  /**
   * 切换影像图显示方式
   * @param {* 影像图id} id
   * @param {* 是否显示} flag
   */
  function toggleImagery(id, flag) {
    customImagery.showHideImagery(id, flag);
  }

  /**
   * 切换图层显示方式
   * @param {* 图层id} id
   * @param {* 是否显示} flag
   */
  function toggle3DTiles(id, flag) {
    pointCloud.showHidePointCloud(id, flag);
  }

  let customImagery = null; //tif影像对象
  /**
   * 添加影像图
   * @param {* 影像图id} id
   * @param {* 影像图地址} addr
   * @param {* 影像图元信息} tifInfo
   */
  function addImagery(id, addr, tifInfo, isZoomTo) {
    if (!customImagery) {
      customImagery = new ufi3d.CustomImagery(viewer);
    }
    customImagery.addImagery(id, addr, tifInfo, isZoomTo);
  }

  let pointCloud = null; //点云对象
  /**
   * 添加图层
   * @param {* 图层id} id
   * @param {* 图层地址} addr
   * @param {* 是否定位} displayFlag
   */
  function add3DTiles(id, addr, displayFlag) {
    if (!pointCloud) {
      pointCloud = new ufi3d.PointCloud(viewer);
    }
    pointCloud.addPointCloud(id, addr, 0, displayFlag);
  }

  /**
   * 切换卫星图/行政图
   * @param {* 地图类型} mapType -- string （satellite-卫星图 | administration-行政图）
   */
  function updateMap(mapType) {
    let mapPath = "";
    if (mapType === "satellite") {
      // 卫星图
      mapPath = window.g.satelliteMapUrl;
    } else {
      //行政图
      mapPath = window.g.administrativeMapUrl;
    }
    viewer.imageryLayers.remove(viewer.imageryLayers._layers[0]);
    const imageryProvider = new Cesium.UrlTemplateImageryProvider({
      url: mapPath + "/{z}/{x}/{y}.png",
      tilingScheme: new Cesium.WebMercatorTilingScheme(),
    });
    viewer.imageryLayers.addImageryProvider(imageryProvider, 0);
  }

  let isFlying = false;
  /**
   * 切换2D/3D
   * @param {* 模式类型 } modeType -- string (2D | 3D)
   * @return 是否切换成功
   */
  function toggleSceneMode(modeType) {
    if (isFlying) return false;
    // 根据切换模式决定最终相机俯仰角度  -- -90为视角平行于地面  -15为近似垂直于海平面
    const finallyPitch = modeType === "2D" ? -90 : -15;

    // 弧度表示
    const pitchRadian = Cesium.Math.toRadians(finallyPitch);

    const camera = viewer.camera; // 相机
    const position = camera.position; // 相机位置
    const heading = camera.heading; // 相机朝向
    const pitch = camera.pitch; // 相机俯仰
    const roll = camera.roll; // 相机翻滚

    const pitchAngle = (pitch * 180) / Math.PI; // 相机俯仰角度表示

    // 计算屏幕中心点位置
    // 屏幕中心点二维坐标
    const center = new Cesium.Cartesian2(
      viewer.canvas.clientWidth / 2,
      viewer.canvas.clientHeight / 2
    );
    // 获取中心点的射线
    const ray = viewer.camera.getPickRay(center);
    // 根据射线和场景 获取 射线与椭球交叉的点（即中心点拾取到的三维坐标）
    const intersection = viewer.scene.globe.pick(ray, viewer.scene);
    isFlying = true;
    setTimeout(() => {
      isFlying = false;
    }, 2200);
    if (intersection === undefined) {
      // 没有拾取到  -- 此时中心点可能为天空
      viewer.camera.flyTo({
        destination: position,
        orientation: {
          heading: heading,
          pitch: pitchRadian,
          roll: roll,
        },
      });
    } else {
      // 拾取到  -- 判断俯仰角是否过于贴近地平线
      if (pitchAngle > -15) {
        // 过于贴近地面 -- 直接旋转相机
        console.log(pitchAngle);
        viewer.camera.flyTo({
          destination: position,
          orientation: {
            heading: heading,
            pitch: pitchRadian,
            roll: roll,
          },
        });
      } else {
        // 绕中心点进行俯仰切换
        const distance = Cesium.Cartesian3.distance(position, intersection);
        const entity = viewer.entities.add({
          show: true,
          position: intersection,
          // 不能去掉point，去掉无法执行flyTo
          point: {},
        });
        viewer.flyTo(entity, {
          offset: new Cesium.HeadingPitchRange(heading, pitchRadian, distance),
          duration: 2,
        });
        setTimeout(() => {
          viewer.entities.remove(entity);
        }, 3000);
      }
    }
    return true;
  }

  let measureDistanceObj = null; //测距对象
  /* 开始测距 */
  function startMeasureDistance() {
    if (measureDistanceObj) {
      measureDistanceObj.activate();
    } else {
      measureDistanceObj = new ufi3d.MeasureDistance(viewer);
      measureDistanceObj.activate();
    }
  }

  /* 结束测距 */
  function stopMeasureDistance() {
    if (measureDistanceObj) {
      measureDistanceObj.deactivate();
    }
  }

  /* 清除测距对象 */
  function clearMeasureDistance() {
    if (measureDistanceObj) {
      measureDistanceObj.clear();
    }
  }

  let measureAreaObj = null;
  /* 开始测面 */
  function startMeasureArea() {
    if (measureAreaObj) {
      measureAreaObj.activate();
    } else {
      measureAreaObj = new ufi3d.MeasureArea(viewer);
      measureAreaObj.activate();
    }
  }

  /* 结束测面 */
  function stopMeasureArea() {
    if (measureAreaObj) {
      measureAreaObj.deactivate();
    }
  }

  /* 清除测面对象 */
  function clearMeasureArea() {
    if (measureAreaObj) {
      measureAreaObj.clear();
    }
  }

  onBeforeUnmount(() => {
    measureDistanceObj = null;
    measureAreaObj = null;
    locationTimer = null;
  });

  return {
    initCesium,
    destroyCesium,
    setCursorStyle,
    addRoutePoint,
    batchAddRoutePoint,
    getAllLine,
    getLinePoint,
    delLine,
    delLinePoint,
    getPointById,
    getLineLength,
    openPointAngle,
    closeAngle,
    updatePoint,
    selectRoutePoint,
    cameraPosition,
    zoomToImagery,
    zoomTo3DTiles,
    toggleImagery,
    toggle3DTiles,
    addImagery,
    add3DTiles,
    updateMap,
    toggleSceneMode,
    startMeasureDistance,
    startMeasureArea,
    stopMeasureDistance,
    stopMeasureArea,
    clearMeasureArea,
    clearMeasureDistance,
    canMark,
    dragType,
    registerMarkPoint,
    unRegisterMarkPoint,
    comfirmMarkPoint,
    updateCurrMark,
    enlargeEarth,
    shrinkEarth,
    updateEntityHead,
    zoomToRouteLine,
  };
};

export default earthInterface;
