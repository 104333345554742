import { t } from '../../../languages';
/* 地图操作按钮模块 */
import airPointServer from "@/network/airPoint";
import { ElMessage } from "element-plus";
import {
  reactive,
  watch,
  ref,
  onMounted,
  nextTick,
  onBeforeUnmount,
} from "vue";
/**
 *
 * @param {* 鼠标样式} cursorStyle
 * @param {* 操作地图对应这些按钮的函数} earthFun
 * @returns
 */
export default function useEarthButton(cursorStyle, earthFun) {
  const buttonData = reactive({
    ischeck: "", //点击各个按钮操作判断
    isOnce: false, //航点第一次提示
    isNotips: false, //航点是否不在提示
    twoThreeId: "1", //3d选择按钮
    rangId: 0,

    punctuationLng: "", //经度
    punctuationLat: "", //纬度
    punctuationName: "", //名称
    punctuationColor: "#de2d2d", //标点color
    punctuationColorList: [
      "#de2d2d",
      "#de2db7",
      "#150fff",
      "#25eeb3",
      "#50ee25",
      "#eec125",
    ], //颜色列表
    punctuationIndex: 0, //标点索引

    layerFilterWord: "", //图层关键字
    layarList: [], //图层列表
    filterLayerList: [], //图层筛选列表
    visibleLayar: [], //已经实例化过的图层列表
    cesiumTimer: null,
    sceneMode: "3D", //2d/3d变量
  });
  /*2D-3d按钮不变数据 */
  const twoThreeNav = [
    {
      id: 1,
      name: t("144"),
      type: "satellite",
    },
    {
      id: 2,
      name: t("145"),
      type: "administration",
    },
  ];
  /* 测距按钮不变数据 */
  const rangNav = [
    {
      id: 1,
      name: t("146"),
    },
    {
      id: 2,
      name: t("147"),
    },
  ];

  let isShowPoint = ref(false); //航点面板显隐藏

  /* 地图按钮函数对象 */
  const mapButtonFun = {
    //选中航点
    checkAirPoint: () => {
      if (buttonData.ischeck !== "daohan") {
        buttonData.ischeck = "daohan";
        isShowPoint.value = true;
        cursorStyle.value = "crosshair"; //设置鼠标样式
      } else {
        cursorStyle.value = "default"; //设置鼠标样式
        buttonData.ischeck = "";
      }
    },

    //选中搜索
    checkSearch: () => {
      if (buttonData.ischeck !== "search") {
        buttonData.ischeck = "search";
      } else {
        buttonData.ischeck = "";
      }
    },
    // 搜索定位
    flyToPlace: (e) => {
      earthFun.cameraPosition(e);
    },

    //选中标点
    checkLoad: () => {
      if (buttonData.ischeck !== "load") {
        buttonData.ischeck = "load";
      } else {
        buttonData.ischeck = "";
      }
    },
    spotDrop: () => {
      // 设置允许拖拽
      earthFun.canMark.value = true;
    },
    // 改变标记点信息-同步到cesium地球
    updateMarkParam: () => {
      earthFun.updateCurrMark({
        lng: buttonData.punctuationLng,
        lat: buttonData.punctuationLat,
        text: buttonData.punctuationName,
        color: buttonData.punctuationColor,
      });
    },
    // 标点颜色选择
    colorChange: (item, index) => {
      buttonData.punctuationIndex = index;
      buttonData.punctuationColor = item;
      mapButtonFun.updateMarkParam();
    },
    //清空标点数据
    clearPunctuation: () => {
      buttonData.punctuationIndex = 0;
      buttonData.punctuationLng = "";
      buttonData.punctuationLat = "";
      buttonData.punctuationName = "";
      buttonData.punctuationColor = "";
    },
    //取消标点
    closePunctuation: () => {
      buttonData.ischeck = "";
    },
    // 确定标点
    punctuationSubmit: () => {
      // 提交标记，清除临时标记
      earthFun.comfirmMarkPoint(
        buttonData.punctuationLng,
        buttonData.punctuationLat,
        (height) => {
          // 在这里进行后台提交操作
        }
      );
      // mapButtonFun.clearPunctuation();
    },

    // 获取所有图层
    getAllLayers: async () => {
      const planRemembLayersJson = localStorage.getItem("planRemembLayers");
      const planRemembLayers =
        planRemembLayersJson !== null ? JSON.parse(planRemembLayersJson) : [];
      const res = await airPointServer.getAllLayers();
      if (res.resultStatus) {
        const layarData = res.resultData;
        buttonData.layarList = layarData.map((item) => {
          if (planRemembLayers.includes(item.layerId)) {
            // 显示图层
            item.display = true;
            nextTick(() => {
              mapButtonFun.changeLayarVisible(item, false);
            });
          } else {
            item.display = false;
          }
          return item;
        });
        buttonData.filterLayerList = buttonData.layarList;

        // nextTick(() => {
        // for (let i = 0; i < layarData.length; i++) {
        //   const item = layarData[i];
        //   const displayFlag = item.display;
        //   if (displayFlag) {
        //     const layerId = item.layerId;
        //     const layerUrl = item.layerUrl;
        //     const layerType = item.layerType;
        //     buttonData.visibleLayar.push(layerId);
        //     // // 初始化点云、b3dms或tif
        //     if (layerType === "TIF") {
        //       addImagery(layerId, layerUrl, item.tifMetaInfo, false);
        //     } else {
        //       add3DTiles(layerId, layerUrl, false);
        //     }
        //   }
        // }
        // });
      }
    },

    /* 筛选图层输入框改变 */
    layerChange: () => {
      if (buttonData.layerFilterWord === "") {
        buttonData.filterLayerList = buttonData.layarList;
      }
    },

    /* 筛选图层列表 */
    filterLayer: () => {
      buttonData.filterLayerList = buttonData.layarList.filter((item) => {
        if (item.layerName.indexOf(buttonData.layerFilterWord) !== -1) {
          return item;
        }
      });
    },
    //选中图层按钮
    checkLayer: () => {
      if (buttonData.ischeck !== "layer") {
        buttonData.ischeck = "layer";
      } else {
        buttonData.ischeck = "";
      }
    },
    /* 定位图层 */
    zoomToLayer: (item) => {
      const layerId = item.layerId;
      if (buttonData.visibleLayar.includes(layerId)) {
        if (item.layerType === "TIF") {
          earthFun.zoomToImagery(layerId);
        } else {
          earthFun.zoomTo3DTiles(layerId);
        }
      } else {
        ElMessage.warning(t("148"));
      }
    },
    /* 切换图层显示 */
    changeLayarVisible: (item, isZoomTo = true) => {
      const layerId = item.layerId;
      const isCheck = item.display;
      const layerUrl = item.layerUrl;
      const layerType = item.layerType;
      if (buttonData.visibleLayar.includes(layerId)) {
        // 选中过
        if (layerType === "TIF") {
          earthFun.toggleImagery(layerId, isCheck);
        } else {
          earthFun.toggle3DTiles(layerId, isCheck);
        }
      } else {
        // 没有选中过
        buttonData.visibleLayar.push(layerId);
        // 初始化点云、b3dms或tif
        if (layerType === "TIF") {
          earthFun.addImagery(layerId, layerUrl, item.tifMetaInfo, isZoomTo);
        } else {
          earthFun.add3DTiles(layerId, layerUrl, isZoomTo);
        }
      }
    },

    //选中2D-3d
    checkD: () => {
      if (buttonData.ischeck !== "3d") {
        buttonData.ischeck = "3d";
      } else {
        buttonData.ischeck = "";
      }
    },
    //选中2d-3d面板按钮
    twoThreeChange: (item) => {
      if (buttonData.twoThreeId == item.id) {
        return;
      } else {
        buttonData.twoThreeId = item.id;
        earthFun.updateMap(item.type);
      }
    },
    /* 切换2d/3d */
    sceneModeChange: () => {
      const flag = earthFun.toggleSceneMode(buttonData.sceneMode);
      if (!flag) return;
      buttonData.sceneMode = buttonData.sceneMode === "2D" ? "3D" : "2D";
    },

    //选中尺
    checkRouler: () => {
      if (buttonData.ischeck !== "rouler") {
        buttonData.ischeck = "rouler";
      } else {
        buttonData.ischeck = "";
      }
    },
    // 选中测绘按钮
    rangChange: (item) => {
      buttonData.rangId = item.id;
      cursorStyle.value = "crosshair"; //设置地图鼠标样式
      if (item.id == 1) {
        earthFun.stopMeasureArea();
        earthFun.startMeasureDistance();
      } else {
        earthFun.stopMeasureDistance();
        earthFun.startMeasureArea();
      }
    },

    //放大/缩小(in-放大 | out-缩小)
    checkZoom: (type) => {
      if (type === "in") {
        earthFun.enlargeEarth();
      } else {
        earthFun.shrinkEarth();
      }
    },
    // 长按放大/缩小
    cesiumLoPress: (type) => {
      clearInterval(buttonData.cesiumTimer);
      buttonData.cesiumTimer = setInterval(() => {
        type === "in" && earthFun.enlargeEarth();
        type === "out" && earthFun.shrinkEarth();
      }, 250);
    },
    // 松开
    release: () => {
      clearInterval(buttonData.cesiumTimer);
    },
  };

  /* 监听选中按钮，清空绘制的操作 */
  watch(
    () => buttonData.ischeck,
    (newValue, oldValue) => {
      if (oldValue === "rouler") {
        earthFun.stopMeasureDistance();
        earthFun.stopMeasureArea();
        cursorStyle.value = "default";
        buttonData.rangId = 0;
      }
      if (oldValue === "daohan") {
        cursorStyle.value = "default";
      }
    }
  );

  /* 记住已选择的图层id */
  function rememberLayers() {
    const layers = [];
    for (let i = 0; i < buttonData.filterLayerList.length; i++) {
      if (buttonData.filterLayerList[i].display === true) {
        layers.push(buttonData.filterLayerList[i].layerId);
      }
    }
    localStorage.setItem("planRemembLayers", JSON.stringify(layers));
  }

  onMounted(() => {
    mapButtonFun.getAllLayers(); //获取图层
    //航点提示popover
    if (!localStorage.getItem("isTips")) {
      buttonData.isOnce = true;
      //绑定事件
      const app = document.getElementsByClassName("airLine")[0].parentNode;
      app.addEventListener(
        //点击任何地方是的提示消失，只触发一次
        "click",
        function () {
          if (buttonData.isNotips) {
            localStorage.setItem("isTips", true);
          }
          buttonData.isOnce = false;
        },
        { once: true }
      );
    }
    window.addEventListener("unload", rememberLayers);
  });

  onBeforeUnmount(() => {
    // localStorage.getItem('planRemembLayers');
    window.removeEventListener("unload", rememberLayers);
  });

  return {
    buttonData,
    twoThreeNav,
    rangNav,
    mapButtonFun,
    isShowPoint,
  };
}
