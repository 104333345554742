<template>
  <!-- 地图 -->
  <div
    id="cesiumContainer"
    style="width: 100%; height: 100%"
    @dragover.prevent
    @drop.prevent="spotDrop($event)"
    @contextmenu.prevent
    @click="hideRightMenuBox"
  >
    <div class="right_menu_box" id="rightMenuBox">
      <ul class="right_menu">
        <li @click="rightDelPoint">{{ t('210') }}</li>
      </ul>
    </div>
  </div>

  <!-- 左侧栏 -->
  <div
    class="airLine"
    :style="{ height: isShowAirline ? 'calc(100% - 0.25rem)' : '7rem' }"
  >
    <!-- 操作按钮 -->
    <div class="operation">
      <button class="hover_opacity" @click="goBack">
        <span class="iconfont">&#xe6ba;</span>
        <span class="title">{{ t('176') }}</span>
      </button>
      <button class="hover_opacity" @click="save('save')">
        <span class="iconfont">&#xe626;</span>
        <span class="title">{{ t('225') }}</span>
      </button>
    </div>
    <!-- 收缩 -->
    <div class="shrink hover_opacity" @click="changeHide">
      <el-icon
        :style="{
          transform: isShowAirline ? 'rotate(-90deg)' : 'rotate(90deg)',
        }"
        ><DArrowRight
      /></el-icon>
    </div>
    <!-- 新建航线 -->
    <div
      class="newlyBuildAir"
      :style="{
        overflowY: isShowAirline ? 'auto' : 'hidden',
      }"
      :class="{ activeBg: isShowAirline }"
    >
      <div class="airName" :class="{ activeBg: !isShowAirline }">
        <el-input
          :placeholder="t('226')"
          v-model.trim="routePlanName"
          @blur="saveRoutePlanName($event)"
          :maxlength="50"
        ></el-input>
      </div>
      <transition name="el-zoom-in-top">
        <div
          v-if="isShowAirline"
          :class="{ activeBg: !isShowAirline }"
          style="transition: all 0.4s linear"
        >
          <!-- <div class="aerocraft">
            <p class="aerocraft-p">{{ t('227') }}</p>
            <el-select>
              <el-option
                v-for="item in aerocraftList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select>
              <el-option
                v-for="item in loadList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div> -->
          <el-collapse v-model="openLoad">
            <el-collapse-item :title="t('228')" name="load">
              <el-select
                v-bind:model-value="loadSetting.panTilt"
                class="holderSelect"
              >
                <el-option
                  v-for="item in loadList"
                  :key="item"
                  :label="item.name"
                  :value="item.value"
                  @click="changeCamera(item.value)"
                  :placeholder="t('229')"
                ></el-option>
              </el-select>
              <div>{{ t('230') }}</div>
              <el-checkbox-group v-model="loadSetting.routePlanImgType">
                <el-checkbox
                  v-for="(item, index) in lCheckBox"
                  :label="item.type"
                  :key="index"
                  :checked="true"
                  >{{ item.name }}</el-checkbox
                ></el-checkbox-group
              >
            </el-collapse-item>
          </el-collapse>
          <div class="airData">
            <div>
              <span>{{ statistic.airWay || 0 }}m</span><br />
              <span>{{ t('231') }}</span>
            </div>
            <div>
              <span>{{ estimatTime || 0 }}</span
              ><br />
              <span>{{ t('232') }}</span>
            </div>
            <div>
              <span>{{ statistic.amount || 0 }}</span
              ><br />
              <span>{{ t('233') }}</span>
            </div>
            <div>
              <span>{{ statistic.picture || 0 }}</span
              ><br />
              <span>{{ t('234') }}</span>
            </div>
          </div>
          <!-- <div
            class="setFlypoint flex-fsb"
            :style="{ cursor: isFlypoint ? 'pointer' : '' }"
            @click="openFlyPanel"
          >
            <div>{{ t('235') }}(AGL)</div>
            <div
              v-show="!isFlypoint"
              @click="setingFlypoint"
              @click.stop=""
              class="changeFlypoint"
            >
              <el-icon color="#09ACFF"><MapLocation /></el-icon>
              <span>{{ t('236') }}</span>
            </div>
            <div
              v-show="isFlypoint"
              class="changeFlypoint"
              @click="setingFlypoint"
              @click.stop=""
            >
              <el-icon color="#09ACFF"><Delete /></el-icon>
              <span>{{ t('237') }}</span>
            </div>
          </div>
          <transition name="el-zoom-in-top">
            <div class="flyPointPanel" v-show="selectsetFly">
              <div>
                <span>{{ t('73') }}</span>
                <el-input v-model="flyLongitude"></el-input>
                <span>{{ t('74') }}</span>
                <el-input v-model="flyLatitude"></el-input>
              </div>
              <div class="sector flex-fac">
                <div class="box s1">
                  <p><img src="@/asset/img/control-box/upcontrol.png" /></p>
                </div>
                <div class="box s2">
                  <p>
                    <img src="@/asset/img/control-box/rightcontrol.png" />
                  </p>
                </div>
                <div class="box s3">
                  <p><img src="@/asset/img/control-box/downcontrol.png" /></p>
                </div>
                <div class="box s4">
                  <p><img src="@/asset/img/control-box/leftcontrol.png" /></p>
                </div>
                <div class="center"></div>
              </div>
              <p style="font-size: 0.875rem; margin-top: 1.5rem">
                海拔高度（EGM96）
              </p>
              <div class="altitude" style="width: 18.7rem">
                <span
                  v-for="item in reduces"
                  :key="item"
                  @click="flyUpdateHeight(item)"
                  >{{ item }}</span
                >
                <input type="number" v-model="flyHeight" />
                <span style="padding: 0.6875rem 0">m</span>
                <span
                  v-for="item in add"
                  :key="item"
                  @click="flyUpdateHeight(item)"
                  >+{{ item }}</span
                >
              </div>
            </div>
          </transition>
          <div class="switch flex-sb">
            <span>{{ t('238') }}</span>
            <el-switch v-model="startPoint" />
          </div> -->
          <!-- 加个边距 因为上一个暂时注释 -->
          <div class="flex-a" style="margin-top: 0.3125rem">
            {{ t('239') }}（ALT）<el-tooltip placement="bottom">
              <template #content
                >{{ t('240') }}<br />{{ t('241') }}<br />,{{ t('242') }}</template
              >
              <el-icon color="#09ACFF" style="cursor: pointer"
                ><Warning
              /></el-icon>
            </el-tooltip>
          </div>
          <div class="altitude">
            <span
              tabindex="0"
              hidefocus="true"
              v-for="item in reduces"
              :key="item"
              :class="{ isDisabled: relativeHeight <= 0 }"
              @click="operationalData('relativeHeight', item)"
              >{{ item }}</span
            >
            <input
              type="number"
              v-model="relativeHeight"
              @blur="relativeHeightChange('relativeHeight', relativeHeight)"
            />
            <span style="padding: 0.6875rem 0">m</span>
            <span
              v-for="item in add"
              :key="item"
              :class="{ isDisabled: relativeHeight === 1500 }"
              @click="operationalData('relativeHeight', item)"
              >+{{ item }}</span
            >
          </div>
          <div class="heightModel">
            <span>{{ t('243') }}</span>
            <el-select v-model="heightModel" @change="changeHeightModel">
              <el-option
                v-for="item in airHeightModel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div style="margin-top: 0.875rem; margin-bottom: 1.0625rem">
              <p v-if="heightModel === 'ABSOLUTE_HEIGHT'">{{ t('244') }}（WGS84）</p>
              <p v-else>{{ t('245') }}（WGS86）</p>
              <div class="altitude" style="width: 17.375rem">
                <span
                  v-for="item in reduces"
                  :key="item"
                  @click="airHeighChange(item)"
                  >{{ item }}</span
                >
                <input
                  type="number"
                  v-model="altitudeHeight"
                  @blur="airHeighBlur"
                />
                <span style="padding: 0.6875rem 0">m</span>
                <span
                  v-for="item in add"
                  :key="item"
                  @click="airHeighChange(item)"
                  >+{{ item }}</span
                >
              </div>
            </div>
          </div>
          <!-- 加个边距 因为上一个暂时注释 -->
          <div class="flySpeed" style="margin-top: 0.3125rem">
            <div class="flex-sb">
              <span>{{ t('246') }}</span>
              <div>
                <input type="number" v-model="airspeed" @blur="airSpeedBlur" />
                m/s
              </div>
            </div>
            <div style="height: 1.5625rem; margin-top: 0.5rem">
              <button
                class="hover_opacity buttonS"
                @click="airspeedChange('reduce')"
                :disabled="airspeed === 1"
              >
                -
              </button>
              <el-slider
                :min="1"
                :max="15"
                v-model="airspeed"
                @click="airspeedSlider"
                @change="airspeedSlider"
              ></el-slider>
              <button
                class="hover_opacity buttonS"
                @click="airspeedChange('add')"
                :disabled="airspeed === 15"
              >
                +
              </button>
            </div>
          </div>
          <div class="leftFooter">
            <p class="flex-a">
              {{ t('247') }}
              <el-tooltip
                placement="bottom"
                raw-content
                popper-class="titleTip"
              >
                <template #content>
                  <span>{{ t('248') }}。</span><br />
                  <span
                    >{{ t('249') }}。</span
                  ><br />
                  <span
                    >{{ t('250') }}。</span
                  ><br />
                  <span
                    >{{ t('251') }}。</span
                  >
                </template>
                <el-icon
                  color="#09ACFF"
                  style="cursor: pointer; margin-left: 6px"
                  ><Warning />
                </el-icon>
              </el-tooltip>
            </p>
            <!-- @change="airYawChange" -->
            <el-select v-model="airYaw" @change="updateModelAngle">
              <el-option
                v-for="item in airYawSele"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
            <p class="flex-a">
              {{ t('252') }}
              <el-tooltip
                placement="bottom"
                raw-content
                popper-class="titleTip"
              >
                <template #content>
                  <span>
                    {{ t('253') }}
                  </span
                  ><br />
                  <span>
                    {{ t('254') }}
                  </span
                  >
                </template>
                <el-icon
                  color="#09ACFF"
                  style="cursor: pointer; margin-left: 6px"
                  ><Warning />
                </el-icon>
              </el-tooltip>
            </p>
            <el-select v-model="pitch" @change="updateModelAngle">
              <el-option :label="t('255')" :value="t('255')"></el-option>
              <el-option :label="t('256')" :value="t('256')"></el-option>
            </el-select>
            <p>{{ t('257') }}</p>
            <!-- @change="wayPointChange" -->
            <el-select v-model="wayPoint">
              <el-option
                v-for="item in airPointSele"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
            <p class="flex-a">
              {{ t('258') }}（ALT)<el-tooltip placement="bottom">
                <template #content>{{ t('259') }}。</template>
                <el-icon
                  color="#09ACFF"
                  style="cursor: pointer; margin-left: 6px"
                  ><Warning
                /></el-icon>
              </el-tooltip>
            </p>
            <div class="altitude">
              <span
                v-for="item in reduces"
                :key="item"
                @click="operationalData('courseReversal', item)"
                :class="{ isDisabled: courseReversal == 0 }"
                >{{ item }}</span
              >
              <input
                type="number"
                v-model="courseReversal"
                @blur="relativeHeightChange('courseReversal', courseReversal)"
              />
              <span style="padding: 0.6875rem 0">m</span>
              <span
                v-for="item in add"
                :key="item"
                :class="{ isDisabled: courseReversal == 1500 }"
                @click="operationalData('courseReversal', item)"
                >+{{ item }}</span
              >
            </div>
            <p class="flex-a">
              {{ t('260') }}<el-tooltip
                placement="bottom"
                raw-content
                popper-class="titleTip"
              >
                <template #content>
                  <span>
                    {{ t('261') }}
                     </span
                  ><br />
                  <span>
                    {{ t('262') }}
                    </span
                  ><br />
                  <span
                    >{{ t('263') }}。</span
                  ><br />
                  <span
                    >{{ t('264') }}。</span
                  >
                </template>
                <el-icon
                  color="#09ACFF"
                  style="cursor: pointer; margin-left: 6px"
                  ><Warning
                /></el-icon>
              </el-tooltip>
            </p>
            <!-- @change="completeChange" -->
            <el-select v-model="completeAction">
              <el-option
                v-for="item in sucessAction"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
            <p>{{ t('265') }}</p>
            <!-- @change="incontrolChange" -->
            <el-select v-model="incontrollable.incontrollableMode">
              <el-option
                v-for="item in runAway"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
            <p class="flex-a">
              {{ t('266') }}<el-tooltip placement="bottom">
                <template #content
                  >{{ t('267') }}。<br />
                  {{ t('268') }}<br />
                  {{ t('269') }}<br
                /></template>
                <el-icon
                  color="#09ACFF"
                  style="cursor: pointer; margin-left: 6px"
                  ><Warning
                /></el-icon>
              </el-tooltip>
            </p>
            <el-select
              style="margin-bottom: 6.3125rem"
              v-model="incontrollable.controlAction"
            >
              <!-- @change="controlChange" -->
              <el-option
                v-for="item in controlAction"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!-- 右侧栏 -->
  <div class="airPoint">
    <!-- 地图按钮操作 -->
    <div class="iconButton">
      <ul class="iconButtonB">
        <!-- 航点 -->
        <el-popover
          ref="popoverP"
          placement="left"
          :visible="isOnce"
          :width="270"
        >
          <template #reference>
            <div>
              <el-tooltip
                :content="t('270')"
                placement="top"
                effect="light"
                popper-class="tip-class"
              >
                <li @click="checkAirPoint">
                  <img
                    v-show="ischeck !== 'daohan'"
                    src="@/asset/img/airlinePlan/daohan.png"
                  />
                  <img
                    v-show="ischeck === 'daohan'"
                    src="@/asset/img/airlinePlan/active-daohan.png"
                  />
                </li>
              </el-tooltip>
            </div>
          </template>
          <div class="addPoint">
            <p>{{ t('270') }}</p>
            <div>{{ t('271') }}</div>
            <el-checkbox :label="t('272')" v-model="isNotips" size="large" />
          </div>
        </el-popover>
        <!-- 搜索 -->
        <el-tooltip
          :content="t('273')"
          placement="top"
          effect="light"
          popper-class="tip-class"
        >
          <li @click="checkSearch">
            <img
              v-show="ischeck !== 'search'"
              src="@/asset/img/airlinePlan/search.png"
            />
            <img
              v-show="ischeck === 'search'"
              src="@/asset/img/airlinePlan/active-search.png"
            />
          </li>
        </el-tooltip>
        <!-- 定点 -->
        <el-popover
          placement="left"
          popper-class="nopadding_popover"
          trigger="click"
          :visible="ischeck === 'load'"
          @hide="clearPunctuation"
        >
          <template #reference>
            <div>
              <el-tooltip
                :content="t('274')"
                placement="top"
                effect="light"
                popper-class="tip-class"
              >
                <li @click="checkLoad">
                  <img
                    v-show="ischeck !== 'load'"
                    src="@/asset/img/airlinePlan/load.png"
                  />
                  <img
                    v-show="ischeck === 'load'"
                    src="@/asset/img/airlinePlan/active-load.png"
                  />
                </li>
              </el-tooltip>
            </div>
          </template>
          <el-form>
            <div class="popover_content">
              <div class="content_top">
                <div style="margin: 0">{{ t('275') }}</div>
              </div>
              <div class="line"></div>
              <el-form-item>
                <div>{{ t('276') }}</div>
                <div class="flex-sb">
                  <div style="margin-right: 0.3125rem">
                    <span>{{ t('73') }}</span>
                    <el-input
                      type="number"
                      :placeholder="t('277')"
                      v-model.number="punctuationLng"
                      @blur="updateMarkParam"
                    /><br />
                    <span>{{ t('74') }}</span>
                    <el-input
                      type="number"
                      :placeholder="t('278')"
                      @blur="updateMarkParam"
                      v-model.number="punctuationLat"
                    />
                  </div>
                  <img
                    src="@/asset/img/airlinePlan/ears.png"
                    draggable="true"
                    @dragstart="changeDragType('mark')"
                  />
                </div>
              </el-form-item>
              <div class="line"></div>
              <el-form-item>
                <div>
                  <div>{{ t('279') }}</div>
                  <div>
                    <span>{{ t('280') }}</span>
                    <el-input
                      :placeholder="t('281')"
                      class="biaozu"
                      @blur="updateMarkParam"
                      v-model.trim="punctuationName"
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
              <div class="line"></div>
              <el-form-item>
                <div>{{ t('282') }}</div>
                <div class="flex-sb colorSelect">
                  <span>{{ t('283') }}</span>
                  <div
                    :style="{ background: item }"
                    v-for="(item, index) in punctuationColorList"
                    :class="index == punctuationIndex ? 'border_bg' : ''"
                    :key="index"
                    @click="colorChange(item, index)"
                  ></div>
                </div>
              </el-form-item>
              <div class="line"></div>
              <el-form-item>
                <div style="display: flex; justify-content: flex-end">
                  <el-button @click="closePunctuation">{{ t('217') }}</el-button>
                  <el-button @click="punctuationSubmit">{{ t('284') }}</el-button>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </el-popover>
        <!-- 图层 -->
        <el-popover
          placement="left"
          popper-class="nopadding_popover"
          trigger="click"
          :visible="ischeck === 'layer'"
        >
          <template #reference>
            <div>
              <el-tooltip
                :content="t('285')"
                placement="top"
                effect="light"
                popper-class="tip-class"
              >
                <li @click="checkLayer">
                  <img
                    v-show="ischeck !== 'layer'"
                    src="@/asset/img/airlinePlan/layer.png"
                  />
                  <img
                    v-show="ischeck === 'layer'"
                    src="@/asset/img/airlinePlan/active-layer.png"
                  />
                </li>
              </el-tooltip>
            </div>
          </template>
          <div class="popover_content_layer">
            <div class="popover_reset">
              <el-input
                type="text"
                :placeholder="t('286')"
                clearable
                v-model="layerFilterWord"
                @change="layerChange"
              ></el-input>
              <el-icon class="iconSeach" @click="filterLayer"
                ><Search
              /></el-icon>
              <div class="layerList">
                <div
                  class="flex-sb"
                  style="margin-top: 1.5rem"
                  v-for="item in filterLayerList"
                  :key="item.layerId"
                  @click.self="zoomToLayer(item)"
                >
                  <span @click="zoomToLayer(item)">{{ item.layerName }}</span>
                  <el-checkbox
                    size="large"
                    v-model="item.display"
                    @change="changeLayarVisible(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-popover>
        <!-- 2d-3d按钮 -->
        <el-tooltip
          :content="t('287')"
          placement="top"
          effect="light"
          popper-class="tip-class"
        >
          <li @click="checkD">
            <img v-if="ischeck === '3d'" src="@/asset/img/airlinePlan/2d.png" />
            <img
              v-show="ischeck !== '3d'"
              src="@/asset/img/airlinePlan/twoThree.png"
            />
          </li>
        </el-tooltip>
        <!-- 测绘-->
        <el-tooltip
          :content="t('288')"
          placement="top"
          effect="light"
          popper-class="tip-class"
        >
          <li @click="checkRouler">
            <img
              v-show="ischeck !== 'rouler'"
              src="@/asset/img/airlinePlan/rouler.png"
            />
            <img
              v-show="ischeck === 'rouler'"
              src="@/asset/img/airlinePlan/active-rouler.png"
            />
          </li>
        </el-tooltip>
        <!-- 放大缩小 -->
        <el-tooltip
          :content="t('289')"
          placement="top"
          effect="light"
          popper-class="tip-class"
        >
          <li
            class="hover_opacity"
            @click="checkZoom('in')"
            @mousedown="cesiumLoPress('in')"
            @mouseup="release"
            @dragend="release"
          >
            <img src="@/asset/img/airlinePlan/big.png" />
          </li>
        </el-tooltip>

        <el-tooltip
          :content="t('290')"
          placement="top"
          effect="light"
          popper-class="tip-class"
        >
          <li
            class="hover_opacity"
            @click="checkZoom('out')"
            @mousedown="cesiumLoPress('out')"
            @mouseup="release"
            @dragend="release"
          >
            <img src="@/asset/img/airlinePlan/small.png" />
          </li>
        </el-tooltip>
      </ul>
      <!-- 搜索面板 -->
      <div class="cesiumSearch" v-if="ischeck === 'search'">
        <MapSearch @flyToPlace="flyToPlace" />
      </div>
      <!-- 2d-3d面板 -->
      <div class="two_three flex-a" v-if="ischeck === '3d'">
        <div
          class="list"
          :class="item.id == twoThreeId ? 'list_bg' : ''"
          v-for="item in twoThreeNav"
          :key="item.id"
          @click="twoThreeChange(item)"
        >
          {{ item.name }}
        </div>
        <div class="list" @click="sceneModeChange">
          {{ sceneMode }}
        </div>
      </div>
      <!-- 测距面板 -->
      <div class="stadio flex-a" v-if="ischeck === 'rouler'">
        <div
          class="list"
          :class="item.id == rangId ? 'list_bg' : ''"
          v-for="item in rangNav"
          :key="item.id"
          @click="rangChange(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 动作 -->
    <transition name="el-fade-in-linear">
      <div class="action" v-show="isAddaction">
        <div class="flex-sb actionTitle">
          <span>{{ t('291') }}</span>
          <span class="hover_opacity"
            ><el-icon
              :size="15"
              style="vertical-align: middle"
              @click="openAddAction(false)"
              ><CloseBold /></el-icon
          ></span>
        </div>
        <div class="model">{{ t('292') }} I:  {{ loadSetting.panTilt }}</div>
        <div class="actionB">
          <div>
            <p style="margin-bottom: 0.9375rem; margin-top: 1.125rem">
              {{ t('293') }}
            </p>
            <div
              class="addAction flex-sb hover_opacity"
              @click="addActions('HOVER', 10)"
            >
              <span>{{ t('294') }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
            <div
              class="addAction flex-sb hover_opacity"
              @click="addActions('FLY_YAW_ANGLE', 0)"
            >
              <span>{{ t('295') }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
          </div>
          <div>
            <p style="margin-bottom: 0.9375rem; margin-top: 1.125rem">
              {{ t('296') }}
            </p>
            <div
              class="addAction flex-sb hover_opacity"
              @click="addActions('PTZ_YAW_ANGLE', 0)"
            >
              <span>{{ t('297') }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
            <div
              class="addAction flex-sb hover_opacity"
              @click="addActions('PTZ_PITCH_ANGLE', 0)"
            >
              <span>{{ t('298') }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
          </div>
          <div>
            <p style="margin-bottom: 0.9375rem; margin-top: 1.125rem">
              {{ t('299') }}
            </p>
            <div
              class="addAction flex-sb hover_opacity"
              v-for="item in loadAction"
              :key="item.value"
              @click="addActions(item.value, 'loads')"
            >
              <span>{{ item.desc }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 航点 -->
    <transition name="el-fade-in-linear">
      <div class="pointPanel" v-if="isShowPoint && routePoint.length > 0">
        <div class="pointTitle flex-a">
          <span class="hover_opacity">
            <el-icon :size="15" @click="changePointId('desc')"
              ><ArrowLeft /></el-icon
          ></span>
          <span class="pointName">{{ t('300') }}{{ pointIndex + 1 }}</span>
          <span class="hover_opacity"
            ><el-icon :size="15" @click="changePointId('add')"
              ><ArrowRight /></el-icon
          ></span>
          <span class="hover_opacity" style="margin: 0 1.75rem"
            ><el-icon
              :size="15"
              @click="deletePoint"
              :class="{ isDisabled: incompleteDelete }"
            >
              <Delete /> </el-icon
          ></span>
          <div class="line"></div>
          <span
            class="hover_opacity"
            @click="
              isShowPoint = false;
              isAddaction = false;
            "
          >
            <el-icon :size="15"><CloseBold /></el-icon
          ></span>
        </div>
        <div class="pointPanelB">
          <div class="steeringWheel">
            <div>
              <span>{{ t('73') }}</span>
              <el-input
                type="number"
                v-model.number="longitude"
                @input="checkData(longitude, 'longitude', 'pointLog')"
              ></el-input>
              <span>{{ t('74') }}</span>
              <el-input
                type="number"
                v-model.number="latitude"
                @input="checkData(latitude, 'latitude', 'pointLat')"
              ></el-input>
            </div>
            <div class="sector flex-fac">
              <div class="box s1" @click="updateData('pointLat', 0.000001)">
                <p><img src="@/asset/img/control-box/upcontrol.png" /></p>
              </div>
              <div class="box s2" @click="updateData('pointLog', 0.000001)">
                <p><img src="@/asset/img/control-box/rightcontrol.png" /></p>
              </div>
              <div class="box s3" @click="updateData('pointLat', -0.000001)">
                <p><img src="@/asset/img/control-box/downcontrol.png" /></p>
              </div>
              <div class="box s4" @click="updateData('pointLog', -0.000001)">
                <p><img src="@/asset/img/control-box/leftcontrol.png" /></p>
              </div>
              <div
                class="center"
                draggable="true"
                @dragstart="changeDragType('route')"
              ></div>
            </div>
          </div>
          <div class="altitudeHeight">
            <div class="followairLine">
              <span>{{ t('244') }}（EGM96）</span>
              <el-checkbox
                v-model="routePoint[pointIndex].isRoute"
                @change="followRouteH(true)"
                >{{ t('301') }}</el-checkbox
              >
            </div>
            <div>
              <img
                style="width: 1.0625rem; height: 4.625rem; margin-right: 2px"
                src="@/asset/img/airlinePlan/heightLine.png"
              />
              <div>
                <div class="altitude" style="width: 17.5rem">
                  <span
                    tabindex="0"
                    hidefocus="true"
                    v-for="item in reduces"
                    :key="item"
                    :class="{ isDisabled: routePoint[pointIndex].isRoute }"
                    @click="updateHeight('pointAltitude', item)"
                    >{{ item }}</span
                  >
                  <input
                    type="number"
                    v-model="routePoint[pointIndex].pointAltitude"
                    :disabled="routePoint[pointIndex].isRoute"
                    :class="{ isDisabled: routePoint[pointIndex].isRoute }"
                    :onchange="(isAltitude = true)"
                    :step="0.1"
                    @blur="
                      lostBlur(
                        routePoint[pointIndex].pointAltitude,
                        'pointAltitude',
                        $event
                      )
                    "
                  />
                  <span style="padding: 0.6875rem 0">m</span>
                  <span
                    :class="{ isDisabled: routePoint[pointIndex].isRoute }"
                    v-for="item in add"
                    :key="item"
                    @click="updateHeight('pointAltitude', item)"
                    >+{{ item }}</span
                  >
                </div>
                <div style="margin-top: 1px">{{ t('302') }}（EGM96）</div>
                <div class="altitude" style="width: 17.5rem">
                  <span
                    v-for="item in reduces"
                    :key="item"
                    :class="{
                      isDisabled:
                        routePoint[pointIndex].isRoute ||
                        routePoint[pointIndex].pointRelative <= 0,
                    }"
                    @click="updateHeight('pointRelative', item)"
                    >{{ item }}</span
                  >
                  <input
                    type="number"
                    v-model="routePoint[pointIndex].pointRelative"
                    :disabled="routePoint[pointIndex].isRoute"
                    :class="{ isDisabled: routePoint[pointIndex].isRoute }"
                    :onchange="(isRelative = true)"
                    :step="0.1"
                    @blur="
                      lostBlur(
                        routePoint[pointIndex].pointRelative,
                        'pointRelative',
                        $event
                      )
                    "
                  />
                  <span style="padding: 0.6875rem 0">m</span>
                  <span
                    v-for="item in add"
                    :key="item"
                    @click="updateHeight('pointRelative', item)"
                    :class="{ isDisabled: routePoint[pointIndex].isRoute }"
                    >+{{ item }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="flex-sb"
            style="height: 1.4375rem; margin-bottom: 0.6875rem"
          >
            <span>{{ t('303') }}</span> -->
          <!-- <el-checkbox>{{ t('301') }}</el-checkbox> -->
          <!-- </div>
          <el-select v-model="routePoint[pointIndex].routePointType">
            <el-option
              v-for="item in routePointList"
              :key="item.value"
              :value="item.value"
              :label="item.desc"
            ></el-option>
          </el-select> -->
          <div style="margin-top: 1.25rem">
            <p>{{ t('304') }}</p>
            <div
              class="pointAction flex-sb hover_opacity"
              @click="openAddAction()"
            >
              <span>{{ t('291') }}</span>
              <el-icon color="white" :size="14"><Plus /></el-icon>
            </div>
          </div>
          <!--下边拖拽事件
             @dragover.prevent="handleDragOver($event)" 
            -->

          <!-- :draggable="isDraggable" -->
          <div
            v-for="(item, index) in routePoint[pointIndex].addAction"
            :key="index"
            :draggable="isDraggable"
            @dragstart="handleDragStart(index)"
            @dragenter="handleDragEnter($event, index)"
            @dragend="handleDragEnd($event)"
            @click="checkAction = index"
            :class="{ actionActive: checkAction === index }"
          >
            <div class="flyHover" v-if="item.actionType === 'HOVER'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('294') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    @focus="getOldParam(index)"
                    @blur="updateStopParam(index, 'slider')"
                  />
                  <span style="margin-right: 0.5rem; margin-left: 0.3125rem"
                    >s</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)">
                    <Delete />
                  </el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 1"
                  @click="updateStopParam(index, 'button', -1)"
                >
                  -
                </button>
                <el-slider
                  class="pointSlider"
                  v-model="item.actionParam"
                  :max="30"
                  :min="1"
                  @mousedown.capture="getOldParam(index)"
                  @change="updateStopParam(index, 'slider')"
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 30"
                  @click="updateStopParam(index, 'button', 1)"
                >
                  +
                </button>
              </div>
            </div>
            <div class="flyYaw" v-if="item.actionType === 'FLY_YAW_ANGLE'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('295') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    @input="
                      updataFAngle(item.actionParam, item.cameraAngle, index)
                    "
                  />
                  <span style="margin-right: 0.5rem; margin-left: 0.3125rem"
                    >°</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)">
                    <Delete />
                  </el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === -180"
                  @click="
                    item.actionParam -= 1;
                    updataFAngle(item.actionParam, item.cameraAngle, index);
                  "
                >
                  -
                </button>
                <el-slider
                  :max="180"
                  :min="-180"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @click="
                    updataFAngle(item.actionParam, item.cameraAngle, index)
                  "
                  @change="
                    updataFAngle(item.actionParam, item.cameraAngle, index)
                  "
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 180"
                  @click="
                    item.actionParam += 1;
                    updataFAngle(item.actionParam, item.cameraAngle, index);
                  "
                >
                  +
                </button>
              </div>
              <div class="flex-sb coordinate">
                <div class="coordinateImg">
                  <img src="@/asset/img/airlinePlan/yawAngle.png" />
                  <img
                    src="@/asset/img/airlinePlan/fly.png"
                    :style="{
                      transform: 'rotate(' + item.actionParam + 'deg)',
                    }"
                  />
                  <div
                    class="cameraImg"
                    :style="{
                      transform:
                        'rotate(' +
                        (item.cameraAngle + item.actionParam) +
                        'deg)',
                    }"
                  >
                    <img src="@/asset/img/airlinePlan/camera.png" />
                  </div>
                </div>
                <img
                  style="width: 0.9375rem; height: 0.5rem"
                  src="@/asset/img/airlinePlan/camera.png"
                />
                <span
                  >{{ t('305') }}：{{ item.cameraAngle + item.actionParam }}°</span
                >
              </div>
            </div>
            <div class="flyHover" v-if="item.actionType === 'PTZ_YAW_ANGLE'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('297') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    @input="updataYCamera(index, item.actionParam)"
                  />
                  <span style="margin-right: 0.5rem; margin-left: 0.3125rem"
                    >°</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === -180"
                  @click="
                    item.actionParam -= 1;
                    updataYCamera(index, item.actionParam);
                  "
                >
                  -
                </button>
                <el-slider
                  :max="180"
                  :min="-180"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @click="updataYCamera(index, item.actionParam)"
                  @change="updataYCamera(index, item.actionParam)"
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 180"
                  @click="
                    item.actionParam += 1;
                    updataYCamera(index, item.actionParam);
                  "
                >
                  +
                </button>
              </div>
            </div>
            <div class="flyHover" v-if="item.actionType === 'PTZ_PITCH_ANGLE'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('298') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    :step="0.1"
                    @input="updatePitchAngle(index, item.actionParam)"
                  />
                  <span style="margin-right: 0.5rem; margin-left: 0.3125rem"
                    >°</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === -90"
                  @click="
                    item.actionParam -= 1;
                    updatePitchAngle(index, item.actionParam);
                  "
                >
                  -
                </button>
                <el-slider
                  :min="-90"
                  :max="0"
                  :step="0.1"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @change="updatePitchAngle(index, item.actionParam)"
                  @click="updatePitchAngle(index, item.actionParam)"
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 0"
                  @click="
                    item.actionParam += 1;
                    updatePitchAngle(index, item.actionParam);
                  "
                >
                  +
                </button>
              </div>
            </div>
            <div class="flyHover" v-if="item.actionType === 'PHOTOGRAPH'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('306') }}</span>
                </div>
                <el-icon :size="15" @click.stop="deleteAction(index)"
                  ><Delete
                /></el-icon>
              </div>

              <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="!item.isEdit"
              >
                <span>DJI_YYYYMMDDhhmm_XXX_{{ item.actionParam }}</span>
                <!-- <el-icon
                  :size="14"
                  class="editIcon"
                  @click="
                    item.isEdit = !item.isEdit;
                    updatePhoto = item.actionParam;
                  "
                  ><Edit />
                </el-icon> -->
              </div>
              <!-- <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="item.isEdit"
              >
                <el-input class="photograph" v-model="updatePhoto" />
                <el-icon
                  :size="14"
                  style="color: springgreen"
                  @click="
                    saveUpdate('editphoto', index);
                    item.isEdit = !item.isEdit;
                  "
                  ><Check
                /></el-icon>
                <el-icon
                  :size="14"
                  style="color: red"
                  @click="item.isEdit = !item.isEdit"
                  ><Close
                /></el-icon>
              </div> -->
            </div>
            <div class="flyHover" v-if="item.actionType === 'START_VIDEO'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('307') }}</span>
                </div>
                <el-icon :size="15" @click.stop="deleteAction(index)"
                  ><Delete
                /></el-icon>
              </div>

              <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="!item.isEdit"
              >
                <span>DJI_YYYYMMDDhhmm_XXX_{{ item.actionParam }}</span>
                <!-- <el-icon
                  :size="14"
                  class="editIcon"
                  @click="
                    item.isEdit = !item.isEdit;
                    updateVideo = item.actionParam;
                  "
                  ><Edit />
                </el-icon> -->
              </div>
              <!-- <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="item.isEdit"
              >
                <el-input class="photograph" v-model="updateVideo" />
                <el-icon
                  :size="14"
                  style="color: springgreen"
                  @click="
                    saveUpdate('editvideo', index);
                    item.isEdit = !item.isEdit;
                  "
                  ><Check
                /></el-icon>
                <el-icon
                  :size="14"
                  style="color: red"
                  @click="item.isEdit = !item.isEdit"
                  ><Close
                /></el-icon>
              </div> -->
            </div>
            <div
              class="flex-sb flyHover"
              v-if="item.actionType === 'END_VIDEO'"
            >
              <div>
                <span class="iconfont" @mousedown="isDraggable = true"
                  >&#xe63d;</span
                >
                <span style="margin-left: 0.75rem">{{ t('308') }}</span>
              </div>
              <el-icon :size="15" @click.stop="deleteAction(index)"
                ><Delete
              /></el-icon>
            </div>
            <div class="flyHover" v-if="item.actionType === 'CAMERA_ZOOM'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('309') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    style="margin-right: 20px"
                    v-model="item.actionParam"
                    @blur="
                      item.actionParam =
                        item.actionParam === '' ? 1 : item.actionParam
                    "
                  />
                  <el-icon :size="15" @click.stop="deleteAction(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === zoomRatio.min"
                  @click="
                    item.actionParam -= 1;
                    updateZoom(index, item.actionParam);
                  "
                >
                  -
                </button>
                <el-slider
                  :max="zoomRatio.max"
                  :min="zoomRatio.min"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @change="updateZoom(index, item.actionParam)"
                  @click="updateZoom(index, item.actionParam)"
                ></el-slider>
                <button
                  :disabled="item.actionParam === zoomRatio.max"
                  class="hover_opacity buttonS"
                  @click="
                    item.actionParam += 1;
                    updateZoom(index, item.actionParam);
                  "
                >
                  +
                </button>
              </div>
            </div>
            <!-- <div class="flyHover" v-if="item.actionType === 'CREATE_FOLDER'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('310') }}</span>
                </div>
                <el-icon :size="15" @click.stop="deleteAction(index)"
                  ><Delete
                /></el-icon>
              </div>
              <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="!item.isEdit"
              >
                 <span>DJI_YYYYMMDDhhmm_XXX_{{ item.actionParam }}</span>
                 <el-icon
                  :size="14"
                  class="editIcon"
                  @click="
                    item.isEdit = !item.isEdit;
                    updateFolder = item.actionParam;
                  "
                  ><Edit />
                </el-icon> 
              </div>
               <div
                class="flex-sb"
                style="margin-top: 1.0625rem"
                v-show="item.isEdit"
              >
                <el-input class="photograph" v-model="updateFolder" />
                <el-icon
                  :size="14"
                  style="color: springgreen"
                  @click="
                    saveUpdate('', index);
                    item.isEdit = !item.isEdit;
                  "
                  ><Check
                /></el-icon>
                <el-icon
                  :size="14"
                  style="color: red"
                  @click="item.isEdit = !item.isEdit"
                  ><Close
                /></el-icon>
              </div>
            </div> -->
            <div class="flyHover" v-if="item.actionType === 'PHOTO_INTERVAL'">
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('311') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    @blur="
                      item.actionParam =
                        item.actionParam === '' ? 0 : item.actionParam
                    "
                    @input="changePicValue"
                  />
                  <span style="margin-right: 0.5rem; margin-left: 0.3125rem"
                    >s</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 1"
                  @click="
                    item.actionParam -= 1;
                    changePicValue();
                  "
                >
                  -
                </button>
                <el-slider
                  :max="30"
                  :min="1"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @change="changePicValue()"
                  @click="changePicValue()"
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 30"
                  @click="
                    item.actionParam += 1;
                    changePicValue();
                  "
                >
                  +
                </button>
              </div>
            </div>
            <div
              class="flyHover"
              v-if="item.actionType === 'PHOTO_EQUIDISTANCE'"
            >
              <div class="flex-sb">
                <div>
                  <span class="iconfont" @mousedown="isDraggable = true"
                    >&#xe63d;</span
                  >
                  <span style="margin-left: 0.75rem">{{ t('312') }}</span>
                </div>
                <div>
                  <input
                    type="number"
                    v-model="item.actionParam"
                    @blur="
                      item.actionParam =
                        item.actionParam === '' ? 0 : item.actionParam
                    "
                    @input="changePicValue()"
                  />
                  <span style="margin-right: 0.3125rem; margin-left: 0.3125rem"
                    >m</span
                  >
                  <el-icon :size="15" @click.stop="deleteAction(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
              <div style="margin-top: 0.875rem" class="flex-sb">
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 1"
                  @click="
                    item.actionParam -= 1;
                    changePicValue();
                  "
                >
                  -
                </button>
                <el-slider
                  :max="30"
                  :min="1"
                  class="pointSlider"
                  v-model="item.actionParam"
                  @change="changePicValue()"
                  @click="changePicValue()"
                ></el-slider>
                <button
                  class="hover_opacity buttonS"
                  :disabled="item.actionParam === 30"
                  @click="
                    item.actionParam += 1;
                    changePicValue();
                  "
                >
                  +
                </button>
              </div>
            </div>
            <div
              class="flex-sb flyHover"
              v-if="item.actionType === 'END_PHOTO'"
            >
              <div>
                <span class="iconfont" @mousedown="isDraggable = true"
                  >&#xe63d;</span
                >
                <span style="margin-left: 0.75rem">{{ t('313') }}</span>
              </div>
              <el-icon :size="15" @click.stop="deleteAction(index)"
                ><Delete
              /></el-icon>
            </div>
          </div>
          <!-- 新增div -->
          <div
            class="flySpeed"
            v-if="airYaw === t('314') && pointIndex !== 0"
          >
            <div class="flex-sb" style="margin-top: 1.75rem">
              <span>{{ t('315') }}</span>
              <div>
                <!-- <el-checkbox style="margin-right: 8px; vertical-align: middle"
                  >{{ t('301') }}</el-checkbox
                > -->
                <input
                  type="number"
                  style="vertical-align: middle"
                  v-model="routePoint[pointIndex].efficiencyPointYaw"
                  @blur="
                    lostBlur(
                      routePoint[pointIndex].efficiencyPointYaw,
                      'efficiencyPointYaw',
                      $event
                    )
                  "
                />
                °
              </div>
            </div>
            <div style="margin-top: 0.5rem" class="flex-sb">
              <button
                class="hover_opacity buttonS"
                :disabled="routePoint[pointIndex].efficiencyPointYaw === -180"
                @click="updateData('efficiencyPointYaw', -1)"
              >
                -
              </button>
              <el-slider
                class="pointSlider"
                :max="180"
                :min="-180"
                v-model="routePoint[pointIndex].efficiencyPointYaw"
                @change="updateModelAngle"
                @click="updateModelAngle"
              ></el-slider>
              <button
                class="hover_opacity buttonS"
                :disabled="routePoint[pointIndex].efficiencyPointYaw === 180"
                @click="updateData('efficiencyPointYaw', 1)"
              >
                +
              </button>
            </div>
          </div>
          <div
            class="flySpeed"
            v-show="pitch === t('256') && pointIndex !== 0"
          >
            <div class="flex-sb" style="margin-top: 1.75rem">
              <span>{{ t('298') }}</span>
              <div>
                <!-- <el-checkbox
                  style="margin-right: 0.5rem; vertical-align: middle"
                  >{{ t('301') }}</el-checkbox
                > -->
                <input
                  type="number"
                  style="vertical-align: middle"
                  v-model="routePoint[pointIndex].angleOfPitch"
                  @blur="
                    lostBlur(
                      routePoint[pointIndex].angleOfPitch,
                      'angleOfPitch',
                      $event
                    )
                  "
                />
                °
              </div>
            </div>
            <div style="margin-top: 0.5rem" class="flex-sb">
              <!-- :class="{ isDisabled: isWayPoitStop }"
              :disabled="isWayPoitStop" -->
              <button
                class="hover_opacity buttonS"
                :disabled="routePoint[pointIndex].angleOfPitch === -120"
                @click="updateData('angleOfPitch', -1)"
              >
                -
              </button>
              <el-slider
                class="pointSlider"
                :max="30"
                :min="-120"
                v-model="routePoint[pointIndex].angleOfPitch"
                @change="updateModelAngle"
                @click="updateModelAngle"
              ></el-slider>
              <button
                class="hover_opacity buttonS"
                :disabled="routePoint[pointIndex].angleOfPitch === 30"
                @click="updateData('angleOfPitch', 1)"
              >
                +
              </button>
            </div>
          </div>
          <div
            class="flex-sb"
            style="margin-top: 1.25rem; margin-bottom: 0.625rem"
          >
            <span>{{ t('316') }}</span>
            <!-- <el-checkbox>{{ t('301') }}</el-checkbox> -->
          </div>
          <el-select v-model="routePoint[pointIndex].airRotate">
            <el-option
              v-for="item in airRotateList"
              :key="item.value"
              :value="item.value"
              :label="item.desc"
            ></el-option>
          </el-select>
          <div class="flySpeed">
            <div class="flex-sb" style="margin-top: 1.75rem">
              <span>{{ t('246') }}</span>
              <div>
                <el-checkbox
                  style="margin-right: 0.5rem; vertical-align: middle"
                  v-model="routePoint[pointIndex].speed.isRoute"
                  @change="followRouteS"
                  >{{ t('301') }}</el-checkbox
                >
                <input
                  :disabled="routePoint[pointIndex].speed.isRoute"
                  :class="{ isDisabled: routePoint[pointIndex].speed.isRoute }"
                  type="number"
                  style="vertical-align: middle"
                  v-model="routePoint[pointIndex].speed.flySpeed"
                  @blur="
                    lostBlur(
                      routePoint[pointIndex].speed.flySpeed,
                      'speed',
                      $event
                    )
                  "
                  @input="changeFlyTime"
                />m/s
              </div>
            </div>
            <div style="margin-top: 0.5rem" class="flex-sb">
              <button
                class="hover_opacity buttonS"
                :disabled="
                  routePoint[pointIndex].speed.flySpeed === 1 ||
                  routePoint[pointIndex].speed.isRoute
                "
                :class="{ isDisabled: routePoint[pointIndex].speed.isRoute }"
                @click="updatePointSpeed(-1)"
              >
                -
              </button>
              <el-slider
                class="pointSlider"
                :max="15"
                :min="1"
                v-model="routePoint[pointIndex].speed.flySpeed"
                :disabled="routePoint[pointIndex].speed.isRoute"
                :class="{ isDisabled: routePoint[pointIndex].speed.isRoute }"
                @click="changeFlyTime"
                @change="changeFlyTime"
              ></el-slider>
              <button
                class="hover_opacity buttonS"
                :disabled="
                  routePoint[pointIndex].speed.flySpeed === 15 ||
                  routePoint[pointIndex].speed.isRoute
                "
                :class="{ isDisabled: routePoint[pointIndex].speed.isRoute }"
                @click="updatePointSpeed(1)"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 提示栏 -->
  <div
    class="tipsColumn"
    v-if="tipsList.length !== 0"
    @click="isTips = !isTips"
  >
    <div class="tipsTitle">
      <span class="iconfont">&#xe685;</span>
      <span style="margin-right: 0.5rem">{{ tipsList[0].tipMessage }}</span>
    </div>
    <transition name="el-zoom-in-top">
      <div class="tipsPanel" v-show="isTips">
        <div>
          <div
            v-for="(item, index) in tipsList"
            :key="item.tipId"
            class="flex-fsb tipDetails"
            style="margin-top: 1rem"
          >
            <div class="iconfont">&#xe685;</div>
            <div class="titles">
              <span style="margin-right: 0.5rem">{{ t('317') }}{{ index + 1 }}:</span>
              <span>{{ item.tipMessage }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- 弹出框 -->
  <transition
    enter-active-class="animate__animated animate__slideInRight"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div v-if="isBack" class="goBack">
      <span
        >{{ t('318') }}。</span
      >
      <div style="text-align: right">
        <button @click="quxiao">{{ t('217') }}</button>
        <button @click="noSave">{{ t('319') }}</button>
        <button @click="backSave">{{ t('225') }}</button>
      </div>
    </div>
  </transition>
  <div class="modelDialog">
    <el-dialog
      v-model="modelDialog"
      :title="t('130')"
      width="35%"
      center
      @close="closeDialog"
    >
      <div>
        {{ t('320') }}
      </div>
      <br />
      <div>
        {{ t('321') }}
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">{{ t('217') }}</el-button>
          <el-button type="primary" @click="keepPointH">{{ t('322') }}</el-button>
          <el-button type="primary" @click="recalCulate">{{ t('323') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 相机切换提示框 -->
    <el-dialog
      v-model="cameraDialog"
      :title="t('130')"
      width="20%"
      center
      @close="cameraDialog = false"
    >
      <div>{{ t('324') }}！</div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cameraDialog = false">{{ t('217') }}</el-button>
          <el-button type="primary" @click="determineCanmera">{{ t('284') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { t } from '../../languages';
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
  toRaw,
  onMounted,
  watch,
  onBeforeUnmount,
} from "vue";
import {
  Delete,
  // MapLocation,
  DArrowRight,
  Plus,
  // Check,
  // Close,
  Search,
  ArrowLeft,
  ArrowRight,
  CloseBold,
  // Edit,
  Warning,
} from "@element-plus/icons-vue";
import { ElNotification, ElMessage } from "element-plus";
import "animate.css";
import earthInterface from "./earth-interface";
import { v4 as uuidv4 } from "uuid";
import MapSearch from "@/views/command-center/components/mapSearch.vue";
import airPointServer from "@/network/airPoint";
import useEarthButton from "./hook/useEarthButton";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: { MapSearch },
  setup() {
    const route = useRoute();
    //地图变量参数
    let routeplanId = ""; //创建线段id
    const earthData = reactive({
      pointType: "route", //添加的航点类型 （route-航点 | takeOffPoint-起飞点）
    });
    const routeObj = reactive({
      egm96Height: 100,
      wgs84Height: 100,
    }); //航线对象

    //地图中鼠标样式
    const cursorStyle = ref("default");

    /**
     * 移动或增加航点数据赋值函数
     * @param {*被赋值的点对象} receiveObj
     * @param {*地图点对象} earthObj
     * @param {*是否为添加点操作} isAdd
     */
    function updatePointObj(receiveObj, earthObj, isAdd) {
      isAdd && (receiveObj.routePointId = earthObj.routePointId); //航点id

      receiveObj.pointLog = earthObj.longitude; // 经度
      receiveObj.pointLat = earthObj.latitude; //纬度

      earthObj.pointDistance !== undefined &&
        (receiveObj.pointDistance = earthObj.pointDistance.toFixed(1) * 1); //与上个点的距离

      receiveObj.pointAltitude = earthObj.absoHei.toFixed(1) * 1; //绝对高
      receiveObj.pointRelative = earthObj.relaHei.toFixed(1) * 1; // 相对高

      if (earthObj.heading !== undefined) {
        receiveObj.pointYaw = changeAgele(earthObj.heading);
      }

      airPointData.longitude = earthObj.longitude.toFixed(9) * 1;
      airPointData.latitude = earthObj.latitude.toFixed(9) * 1;

      //添加时--格外操作
      if (isAdd) {
        if (airPlaneData.routePoint.length > 0) {
          //上一个航点数据
          const beforPoint =
            airPlaneData.routePoint[airPlaneData.routePoint.length - 1];
          //继承上一个航点高度是否跟随航线
          receiveObj.isRoute = beforPoint.isRoute;

          //继承上一个航点的飞行速度数据
          receiveObj.speed.isRoute = beforPoint.speed.isRoute;
          receiveObj.speed.flySpeed = beforPoint.speed.flySpeed;
        }
        airPlaneData.routePoint.push(receiveObj); //添加航点对象数据
        pointIndex.value = airPlaneData.routePoint.length - 1; //修改要显示航点的下标

        airPlaneData.statistic.picture =
          publicComPhoto(0, photoActionList.length) + photoNum; //计算照片
      }
      isAltitude.value = false; //阻止监听绝对和相对高变化
      isRelative.value = false;

      if (receiveObj.pointRelative < 20) {
        addWarningTip("addTips", pointIndex.value, "too-low");
      } else {
        addWarningTip("clearTips", pointIndex.value, "too-low");
      }

      // 左面板
      // 航线长度
      getRouteLength();
      // 航点数量
      routePointLength();
    }

    /**
     * 鼠标左击回调
     * @param {*判断是否是点击航点还是其他} flag
     * @param {*放回的点或坐标数据} option
     */
    function leftClickCallback(flag, option) {
      //航线id
      if (routeplanId === "") {
        const uuid = uuidv4();
        routeplanId = uuid;
      }
      if (flag) {
        isShowPoint.value = true;

        //点击航点查找对应的index下标
        for (let [index, item] of airPlaneData.routePoint.entries()) {
          item.routePointId === option.routePointId &&
            (pointIndex.value = index);
        }

        //显示选中航点效果
        selectRoutePoint(
          airPlaneData.routePoint[pointIndex.value].routePointId
        );

        //更新航点面板经纬度的数据
        airPointData.longitude =
          airPlaneData.routePoint[pointIndex.value].pointLog.toFixed(9) * 1;
        airPointData.latitude =
          airPlaneData.routePoint[pointIndex.value].pointLat.toFixed(9) * 1;
      } else {
        // 没选中航点且点击添加航点
        if (buttonData.ischeck === "daohan") {
          isShowPoint.value = true;

          //查找上一个航点
          let lastPointLen = airPlaneData.routePoint.length - 1;
          let routeInfo = null;
          //上个点没有跟随航线
          if (
            lastPointLen >= 0 &&
            !airPlaneData.routePoint[lastPointLen].isRoute
          ) {
            if (airPlaneData.heightModel === "ABSOLUTE_HEIGHT") {
              //绝对高度下加点
              routeInfo = addRoutePoint(
                routeplanId,
                option.position,
                earthData.pointType,
                "absu",
                airPlaneData.routePoint[lastPointLen].pointAltitude
              );
            } else {
              //相对高度下加点
              routeInfo = addRoutePoint(
                routeplanId,
                option.position,
                earthData.pointType,
                "rela",
                airPlaneData.routePoint[lastPointLen].pointRelative
              );
            }
          } else {
            //第一个点，或上个点跟随航线
            if (airPlaneData.heightModel === "ABSOLUTE_HEIGHT") {
              //绝对高度下加点
              routeInfo = addRoutePoint(
                routeplanId,
                option.position,
                earthData.pointType,
                "absu",
                airPlaneData.altitudeHeight
              );
            } else {
              //相对高度下加点
              routeInfo = addRoutePoint(
                routeplanId,
                option.position,
                earthData.pointType,
                "rela",
                airPlaneData.altitudeHeight
              );
            }
          }

          // if()

          objectParams.addAction = []; //清空上一个航点浅拷贝的对象
          objectParams.speed = {
            flySpeed: airPlaneData.airspeed,
            isRoute: true, //是否跟随航线
          };

          //拷贝航点数据对象
          let pointParams = Object.assign({}, objectParams);
          routeInfo && updatePointObj(pointParams, routeInfo, true);

          //修改上一个点的飞行航向角--
          if (pointIndex.value > 0) {
            airPlaneData.routePoint[pointIndex.value - 1].pointYaw =
              changeAgele(routeInfo.heading);
          }
        }
      }
    }

    function leftMoveCallback() {}

    //拖动或调整航点确定位置后的回调
    async function leftUpCallback(flage, option) {
      for (let [index, item] of airPlaneData.routePoint.entries()) {
        if (item.routePointId === option.currPoint.routePointId) {
          pointIndex.value = index;
          updatePointObj(item, option.currPoint, false);

          //判断是拖动航点还是调整高度
          if (flage === "height") {
            airPlaneData.routePoint[pointIndex.value].isRoute = false;
          } else {
            if (option.nextPoint !== undefined) {
              //下个点距离
              airPlaneData.routePoint[pointIndex.value + 1].pointDistance =
                option.nextPoint.pointDistance;
            }
            if (option.prevPoint !== undefined) {
              //上个点的航线值
              airPlaneData.routePoint[pointIndex.value - 1].pointYaw =
                option.prevPoint.heading;
            }
          }

          changePointAngle(); //打开最新视锥体
          //显示选中航点效果
          selectRoutePoint(
            airPlaneData.routePoint[pointIndex.value].routePointId
          );
        }
      }

      //计算照片
      airPlaneData.statistic.picture =
        publicComPhoto(0, photoActionList.length) + photoNum;
    }

    const tipsList = ref([]);
    const tipObj = {
      "too-low": (id) => {
        return `近地提示】${
          id + 1
        }# 航点高度距离参考地形过近（<20m），请注意飞行安全。`;
      },
      "too-far": () => {},
    };
    /* 添加航点相对高过近提示 */
    function addWarningTip(type, id, tipType) {
      const index = tipsList.value.findIndex((i) => {
        return i.pointId === id && i.tipType === tipType;
      });
      switch (type) {
        // 提示
        case "addTips":
          if (index === -1) {
            tipsList.value.push({
              pointId: id,
              tipType: tipType,
              tipMessage: tipObj[tipType](id),
            });
          }
          break;
        // 清除提示
        case "clearTips":
          if (index !== -1) {
            tipsList.value.splice(index, 1);
          }
          break;
        case "clearPointTips":
          for (let i = 0; i < tipsList.value.length; i++) {
            if (tipsList.value[i].pointId === id) {
              tipsList.value.splice(i, 1);
              i--; //删除后下一个元素顶上来，则没有判断到
            }
          }
          for (let item of tipsList.value) {
            if (item.pointId > id) {
              item.pointId -= 1;
            }
          }
          break;
        default:
          break;
      }
    }

    /* 设置标签输入框数据 */
    function setButtonData(lng, lat, height) {
      buttonData.punctuationLng = lng;
      buttonData.punctuationLat = lat;
    }

    /* 设置当前航点经纬度 */
    function setRouteLngLat(X, Y) {
      airPlaneData.routePoint[pointIndex.value].pointLog = X;
      airPlaneData.routePoint[pointIndex.value].pointLat = Y;
      airPointData.longitude = X.toFixed(9) * 1;
      airPointData.latitude = Y.toFixed(9) * 1;
    }

    const {
      initCesium,
      setCursorStyle,
      addRoutePoint,
      getAllLine,
      getLinePoint,
      delLine,
      delLinePoint,
      getPointById,
      getLineLength,
      openPointAngle,
      closeAngle,
      destroyCesium,
      selectRoutePoint,
      updatePoint,
      cameraPosition,
      zoomToImagery,
      zoomTo3DTiles,
      toggleImagery,
      toggle3DTiles,
      addImagery,
      add3DTiles,
      updateMap,
      toggleSceneMode,
      startMeasureDistance,
      startMeasureArea,
      stopMeasureDistance,
      stopMeasureArea,
      canMark,
      dragType,
      registerMarkPoint,
      unRegisterMarkPoint,
      comfirmMarkPoint,
      updateCurrMark,
      enlargeEarth,
      shrinkEarth,
      updateEntityHead,
      batchAddRoutePoint,
      zoomToRouteLine,
    } = earthInterface(
      routeObj,
      leftClickCallback,
      cursorStyle,
      leftMoveCallback,
      leftUpCallback
    );

    onMounted(() => {
      initCesium("cesiumContainer", () => {
        registerMarkPoint(setButtonData, setRouteLngLat);
        autoAirlineName();
      });
    });

    /* 高度加减公共静态数据 */
    const calculation = {
      add: [1, 10, 100],
      reduces: [-100, -10, -1],
    };

    /* 左上角按钮 */
    //返回
    let isBack = ref(false);
    let router = useRouter();

    //返回按钮函数对象
    const revertFun = {
      goBack: () => {
        saveElnotfy && saveElnotfy.close();
        if (isSupportEdit && airPlaneData.routePoint.length > 0 && isAnewSave) {
          isBack.value = true;
        } else {
          router.go(-1);
        }
      },
      //取消
      quxiao: () => {
        isBack.value = false;
      },

      //不保存
      noSave: () => {
        isBack.value = false;
        router.go(-1);
      },

      //退出界面-保存接口
      backSave: function () {
        if (airPlaneData.routePlanName === "") {
          ElMessage.warning(t("325"));
          return;
        }
        isBack.value = false;
        save("backSave");
        // let routeJson = JSON.stringify(airPlaneData);
        // let data = { routeId, routeJson };
        // airPointServer.saveAirPoint(data).then((res) => {
        //   if (res.resultStatus) {
        //     saveElnotfy = this.saveEffect();
        //     router.go(-1);
        //   }
        // });
      },

      saveEffect: function () {
        return ElNotification({
          title: t("130"),
          dangerouslyUseHTMLString: true,
          message: "<div class='saveContent>保存成功</div>",
          duration: 2000,
          customClass: "notifyStyle",
          type: "success",
        });
      },
    };

    //保存
    let saveElnotfy = null; //ElNotification对象--用于关闭
    let routeId = null; //存放保存后，接口放回的id
    let isAnewSave = false; //是否重新保存
    let anewWatch = null; //保存的监听对象

    onMounted(() => {
      anewWatch = saveWatch();
    });
    //有map的数组转换成string类型
    function mapTransformArrstr(list) {
      const strList = list.map((item) => {
        item = JSON.stringify([...item]);
        return item;
      });
      return strList;
    }

    let isSupportEdit = true; //支持二次编辑
    async function save(saveType) {
      if (!isSupportEdit) {
        ElMessage.warning(t("326"));
        return;
      }
      if (airPlaneData.routePoint.length < 2) {
        ElMessage.warning(t("327"));
        return;
      }
      if (airPlaneData.routePlanName === "") {
        ElMessage.warning(t("325"));
        return;
      }
      const res = await airPointServer.validationName({
        routeName: airPlaneData.routePlanName,
        routeId,
      });
      if (!res.resultStatus) {
        return;
      }
      const strPitchAllList = mapTransformArrstr(pitchAllList);
      const strFlyAllList = mapTransformArrstr(flyAllList);
      const strZoomAllList = mapTransformArrstr(zoomAllList);

      const additionalInfo = JSON.stringify({
        routeplanId,
        airLineTime,
        pointAtTime,
        photoNum,
        photoActionList,
        pitchAllList: strPitchAllList,
        flyAllList: strFlyAllList,
        zoomAllList: strZoomAllList,
        //reactive数据
        airPointData,
        //ref数据
        estimatTime: estimatTime.value,
        tipsList: tipsList.value,
      });
      isBack.value = false;
      isAnewSave = false;
      let routeJson = JSON.stringify(airPlaneData);
      let data = { routeId, routeJson, additionalInfo };
      airPointServer.saveAirPoint(data).then((res) => {
        if (res.resultStatus) {
          if (saveType === "save") {
            revertFun.saveEffect();
            routeId = res.resultData;
            anewWatch && anewWatch();
            anewWatch = saveWatch();
          } else {
            saveElnotfy = revertFun.saveEffect();
            router.go(-1);
          }
        }
      });
    }

    //监听整个航线是否变化
    function saveWatch() {
      return watch(airPlaneData, () => {
        isAnewSave = true;
        anewWatch && anewWatch();
        anewWatch = null;
      });
    }

    //收缩左边栏
    let isShowAirline = ref(true);
    function changeHide() {
      isShowAirline.value = !isShowAirline.value;
    }

    /* 航线面板模块 */
    let airPlaneData = reactive({
      routePlanName: "",
      loadSetting: {
        panTilt: "M30 Camera",
        routePlanImgType: [],
      }, //负载设置
      statistic: {
        airWay: 0,
        amount: 0,
        expectedTime: "",
        picture: 0,
      }, //统计信息

      heightModel: "ABSOLUTE_HEIGHT", //高度模式
      altitudeHeight: 100, //绝对高度模式-高度值
      // groundHeight: 100, //相对高度模式-地面高度

      airYaw: "AUTO", //飞行器偏航角模式
      pitch: t("255"), //俯仰角模式
      wayPoint: "STRAIGH_FLIGHT", //航点模式
      completeAction: "AUTOMATIC", //完成动作
      incontrollable: {
        incontrollableMode: "CONTINUE_ROUTE", //失控方式
        controlAction: "COURSE_REVERSAL", //失控动作
      }, //失控
      updateTime: "", //更新时间（创建时间）
      relativeHeight: 20, //安全高度
      airspeed: 10, //飞行速度
      courseReversal: 100, //返航高度
      routePoint: [], //航点列表数组
    });

    // 左边操作类型 --shidie(代码者)start
    let openLoad = ref("load"); //负载页面默认打开

    const cameraZoom = {
      "M30 Camera": {
        min: 2,
        max: 200,
      },
      "Mavic3 Camera": {
        min: 1,
        max: 28,
      },
      "御2 Camera": {
        min: 1,
        max: 32,
      },
      "M3T Camera": {
        min: 1,
        max: 56,
      },
      "M3E Camera": {
        min: 1,
        max: 56,
      },
    };

    const lCheckObj = {
      "M30 Camera": [
        {
          name: t("328"),
          type: "WIDE_ANGLE",
        },
        {
          name: t("329"),
          type: "ZOOM",
        },
        {
          name: t("330"),
          type: "INFRARED",
        },
      ],
      "Mavic3 Camera": [
        {
          name: t("328"),
          type: "WIDE_ANGLE",
        },
        {
          name: t("331"),
          type: "LONG_FOCUS",
        },
        // {
        //   name: t("329"),
        //   type: "ZOOM",
        // },
        // {
        //   name: t("330"),
        //   type: "INFRARED",
        // },
      ],
      "御2 Camera": [
        {
          name: t("330"),
          type: "INFRARED",
        },
        {
          name: t("332"),
          type: "VISIBLE_LIGHT",
        },
      ],
      "M3T Camera": [
        {
          name: t("328"),
          type: "WIDE_ANGLE",
        },
        {
          name: t("331"),
          type: "LONG_FOCUS",
        },
        {
          name: t("333"),
          type: "THERMAL_IMAGING",
        },
      ],
      "M3E Camera": [
        {
          name: t("328"),
          type: "WIDE_ANGLE",
        },
        {
          name: t("331"),
          type: "LONG_FOCUS",
        },
        {
          name: t("333"),
          type: "THERMAL_IMAGING",
        },
      ],
    };

    const leftState = reactive({
      // isWayPoitStop: true, //联动航点类型
      // isFlySeeped: false, //联动飞行速度
      loadList: [
        { name: "M30 Camera", value: "M30 Camera" },
        { name: "Mavic3 Camera", value: "Mavic3 Camera" },
        { name: "御2 Camera", value: "御2 Camera" },
        { name: "M3T Camera", value: "M3T Camera" },
        { name: "M3E Camera", value: "M3E Camera" },
      ], //云台下拉选择列表
      lCheckBox: [...lCheckObj["M30 Camera"]],
      zoomRatio: cameraZoom["M30 Camera"], //变焦倍数
      // 飞行器偏航角模式
      airYawSele: [
        {
          name: t("334"),
          type: "AUTO",
        },
        {
          name: t("335"),
          type: "USE_WAYPOINT_HEADING",
        },
        {
          name: t("336"),
          type: t("336"),
        },
        {
          name: t("314"),
          type: t("314"),
        },
      ],
      // 航点模式
      airPointSele: [
        {
          name: t("337"),
          type: "STRAIGH_FLIGHT",
        },
        {
          name: t("338"),
          type: "WAYPOINT_STOP",
        },
        {
          name: t("339"),
          type: "COORDINATED_TURN",
        },
      ],
      // 完成动作
      sucessAction: [
        {
          name: t("340"),
          type: "AUTOMATIC",
        },
        {
          name: t("341"),
          type: "AUTOCHTHONOUS_LANDING",
        },
        {
          name: t("342"),
          type: "AIRWAY_POINT_HOVER",
        },
        {
          name: t("343"),
          type: "EXIT_AIRWAY",
        },
      ],
      // 飞行中失控方式
      runAway: [
        {
          name: t("344"),
          type: "CONTINUE_ROUTE",
        },
        {
          name: t("345"),
          type: "CONTROL_ACTIONS",
        },
      ],
      // 失控动作
      controlAction: [
        {
          name: t("89"),
          type: "COURSE_REVERSAL",
        },
        {
          name: t("346"),
          type: "LANDING",
        },
        {
          name: t("294"),
          type: "HOVER",
        },
      ],

      airHeightModel: [
        {
          label: t("347"),
          value: "ABSOLUTE_HEIGHT",
        },
        {
          label: t("302"),
          value: "RELATIVE_HEIGHT",
        },
      ],

      //
    });
    /* shidie代码--end */

    /* 左边面板控制方法 */
    // 增加求航线长度
    function getRouteLength() {
      const routeLength = getLineLength(routeplanId);
      airPlaneData.statistic.airWay = routeLength.toFixed(1);
      // 预计执行时间
      getTime(routeLength);
    }
    let airLineTime = 0; //航线预计时间
    let pointAtTime = 0; //动作预计时间
    let estimatTime = ref("0s"); //总预计时间
    // 计算预计执行时间
    function getTime(routeLength) {
      if (routeLength > 0) {
        let speed = 0;
        for (let index in airPlaneData.routePoint) {
          speed += airPlaneData.routePoint[index].speed.flySpeed;
        }
        //获取平均速度
        speed = (speed / airPlaneData.routePoint.length).toFixed(2) * 1;

        //航点长度所需飞行时间
        airLineTime =
          routeLength / speed + 2 * (airPlaneData.routePoint.length - 1);

        airPlaneData.statistic.expectedTime = airLineTime + pointAtTime; //总时间
        conversionTime(airPlaneData.statistic.expectedTime); //换算总时间
      } else {
        airLineTime = 0; //一个航点
        estimatTime.value = "0s";
        airPlaneData.statistic.expectedTime = 0;
      }
    }

    //换算时间 （shidie代码--start）
    function conversionTime(speedTime) {
      if (speedTime < 60) {
        estimatTime.value = `${speedTime.toFixed(0)} s`;
      } else if (speedTime < 3600) {
        let ms = speedTime / 60; //换算成分
        let mm = parseInt(ms); //取整分
        let ss = ms % 1; //取余
        let ss_ss = ss * 60; //换算成秒
        estimatTime.value = `${mm} m ${ss_ss.toFixed(0)} s`;
      } else if (speedTime > 3600) {
        let hms = speedTime / 3600; //换算成时
        let hh = parseInt(hms); //取整时
        let mm = hms % 1; //取余分
        let mms = mm * 60; //换算成分
        let mm_mm = parseInt(mms); //取整
        let mmss = mms % 1; //分取余
        let mm_ss = mmss * 60; //换算成秒
        estimatTime.value = `${hh} h ${mm_mm} m ${mm_ss.toFixed(0)} s`;
      }
    }

    // 航点数量
    function routePointLength() {
      airPlaneData.statistic.amount = airPlaneData.routePoint.length;
    }
    let timerOut = null; //定时器返回值--用于解决change和click重复触发问题
    const methods = reactive({
      // 联动航点飞行速度公共函数
      flyspeedLinkage: function () {
        // 联动航点飞行速度
        if (pointIndex.value > -1) {
          for (let index in airPlaneData.routePoint) {
            if (airPlaneData.routePoint[index].speed.isRoute) {
              airPlaneData.routePoint[index].speed.flySpeed =
                airPlaneData.airspeed;
            }
          }
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum;
        }

        getTime(airPlaneData.statistic.airWay); //预计飞行时间
      },

      // 飞行速度
      airspeedChange: (type) => {
        if (type == "add") {
          airPlaneData.airspeed += 1;
        } else {
          airPlaneData.airspeed -= 1;
        }
        methods.flyspeedLinkage(); //联动航点飞行速度
      },

      // 滑块事件
      airspeedSlider: () => {
        timerOut && clearTimeout(timerOut);
        timerOut = setTimeout(() => {
          methods.flyspeedLinkage();
          timerOut = null;
        }, 0); //联动航点飞行速度
      },

      // 飞行速度输入事件
      airSpeedInput: () => {
        methods.flyspeedLinkage(); //联动航点飞行速度
      },

      //飞行速度失焦事件
      airSpeedBlur: (e) => {
        if (e.target.value == "") {
          airPlaneData.airspeed = 1;
        } else {
          airPlaneData.airspeed = Number(e.target.value).toFixed(1) * 1;
        }
        methods.flyspeedLinkage(); //联动航点飞行速度
      },
      // 安全起飞高度input事件
      relativeHeightChange: (type, e) => {
        if (e > 1500) {
          airPlaneData[type] = 1500;
        } else if (e < 0) {
          airPlaneData[type] = 0;
        } else if (e == "") {
          airPlaneData[type] = 0;
        }
      },
      // 失焦保存航线名称
      saveRoutePlanName: (e) => {
        if (e.target.value !== "") {
          localStorage.setItem("lastRoutePlanName", e.target.value);
        }
      },
      // // 飞行器偏航角模式
      // airYawChange: () => {
      //   console.log(airPlaneData);
      // },
      // // 航点类型
      // wayPointChange: () => {
      //   console.log(airPlaneData.wayPoint);
      // },
      // // 完成动作
      // completeChange: () => {
      //   console.log(airPlaneData.completeAction);
      // },
      // // 飞行中失控
      // incontrolChange: () => {
      //   console.log(airPlaneData.incontrollable.incontrollableMode);
      // },
      // // 失控动作
      // controlChange: () => {
      //   console.log(airPlaneData.incontrollable.controlAction);
      // },
    });
    /* shidie代码--end */

    let modelDialog = ref(false); //高度模式的弹出框判断
    let isHeightMode = ref(false); //下拉框的值是否要变化

    /* 航线面板函数对象 */
    const airLineFun = {
      /**
       * 航线面板--加减起飞、返航高度数据
       * @param {*高度类型变量} typeHeight
       * @param {*加减高度大小} size
       *
       */
      operationalData: (typeHeight, size) => {
        airPlaneData[typeHeight] = airPlaneData[typeHeight] * 1; //防止清空输入框数据变成string类型
        airPlaneData[typeHeight] += size;

        if (airPlaneData[typeHeight] > 1500) {
          airPlaneData[typeHeight] = 1500;
        }
        if (airPlaneData[typeHeight] < 0) {
          airPlaneData[typeHeight] = 0;
        }
      },

      /**
       * 航线高度加减
       * @param {*加减高度大小} size
       */
      airHeighChange: (size) => {
        airPlaneData.altitudeHeight = airPlaneData.altitudeHeight * 1; //防止清空输入框数据变成string类型
        airPlaneData.altitudeHeight += size;

        if (
          airPlaneData.heightModel === "RELATIVE_HEIGHT" &&
          airPlaneData.altitudeHeight < 0
        ) {
          size = size - airPlaneData.altitudeHeight;
          airPlaneData.altitudeHeight = 0;
          if (size === 0) {
            //L = y-(x+y)
            return;
          }
        }

        if (airPlaneData.routePoint?.length <= 0) {
          return;
        }

        tipsList.value.splice(0);
        //修改所有航点数据跟随航线的航点高度
        for (let i = 0; i < airPlaneData.routePoint?.length; i++) {
          if (airPlaneData.routePoint[i].isRoute) {
            airPlaneData.routePoint[i].pointAltitude += size; //更改跟随航线的航点高度

            if (pointIndex.value !== i) {
              //手动更改没有选中的航点--选中的有watch属性
              airPlaneData.routePoint[i].pointRelative += size;

              //对应修改地图中的航点
              airLineFun.publicUpdatePtH(
                airPlaneData.routePoint[i].pointLog,
                airPlaneData.routePoint[i].pointLat,
                airPlaneData.routePoint[i].pointAltitude,
                airPlaneData.routePoint[i].pointRelative,
                airPlaneData.routePoint[i].pointYaw,
                airPlaneData.routePoint[i].routePointId
              );
            }
          }

          //找tips警告
          if (airPlaneData.routePoint[i].pointRelative < 20) {
            addWarningTip("addTips", i, "too-low");
          }
        }
        airPointFun.updateModelAngle();
        getRouteLength(); //更新航线长度
      },

      //切换高度模式事件
      changeHeightModel: () => {
        if (airPlaneData.routePoint?.length > 0) {
          modelDialog.value = true;
        }
      },

      //关闭高度模式的弹窗事件
      closeDialog: () => {
        if (!isHeightMode.value) {
          if (airPlaneData.heightModel === "ABSOLUTE_HEIGHT") {
            airPlaneData.heightModel = "RELATIVE_HEIGHT";
          } else {
            airPlaneData.heightModel = "ABSOLUTE_HEIGHT";
          }
        }

        isHeightMode.value = false;
      },

      //保留之前航点高度
      keepPointH: () => {
        if (
          airPlaneData.heightModel === "RELATIVE_HEIGHT" &&
          airPlaneData.altitudeHeight < 0
        ) {
          airPlaneData.altitudeHeight = 0;
        }
        if (airPlaneData.routePoint?.length > 0) {
          for (let i = 0; i < airPlaneData.routePoint.length; i++) {
            airPlaneData.routePoint[i].isRoute = false;
          }
        }

        isHeightMode.value = true;
        modelDialog.value = false;
      },

      //重新计算之前的航点高度groundHeight: 100,
      recalCulate: () => {
        if (
          airPlaneData.heightModel === "RELATIVE_HEIGHT" &&
          airPlaneData.altitudeHeight < 0
        ) {
          airPlaneData.altitudeHeight = 0;
        }
        let pointArr = airPlaneData.routePoint;
        if (pointArr?.length > 0) {
          tipsList.value.splice(0);
          let difference = 0; //航线高度和航点高度之间差值
          if (airPlaneData.heightModel === "ABSOLUTE_HEIGHT") {
            for (let i = 0; i < pointArr.length; i++) {
              pointArr[i].isRoute = true; //跟随航线
              if (pointArr[i].pointAltitude !== airPlaneData.altitudeHeight) {
                difference =
                  pointArr[i].pointAltitude - airPlaneData.altitudeHeight; //计算绝对高度差值

                pointArr[i].pointAltitude = airPlaneData.altitudeHeight; //航线高度赋值给航点高度

                if (pointIndex.value !== i) {
                  difference !== 0 && (pointArr[i].pointRelative -= difference); //利用差值修改对应相对高度

                  //对应修改地图中的航点
                  airLineFun.publicUpdatePtH(
                    pointArr[i].pointLog,
                    pointArr[i].pointLat,
                    pointArr[i].pointAltitude,
                    pointArr[i].pointRelative,
                    pointArr[i].pointYaw,
                    pointArr[i].routePointId
                  );
                }
              }
              //找tips警告
              if (pointArr[i].pointRelative < 20) {
                addWarningTip("addTips", i, "too-low");
              }
            }
          } else {
            for (let i = 0; i < pointArr.length; i++) {
              pointArr[i].isRoute = true; //跟随航线
              if (pointArr[i].pointRelative !== airPlaneData.altitudeHeight) {
                difference =
                  pointArr[i].pointRelative - airPlaneData.altitudeHeight; //计算绝对高度差值

                pointArr[i].pointRelative = airPlaneData.altitudeHeight; //航线高度赋值给航点高度

                if (pointIndex.value !== i) {
                  difference !== 0 && (pointArr[i].pointAltitude -= difference); //利用差值修改对应相对高度

                  //对应修改地图中的航点
                  airLineFun.publicUpdatePtH(
                    pointArr[i].pointLog,
                    pointArr[i].pointLat,
                    pointArr[i].pointAltitude,
                    pointArr[i].pointRelative,
                    pointArr[i].pointYaw,
                    pointArr[i].routePointId
                  );
                }
              }
              //找tips警告
              if (pointArr[i].pointRelative < 20) {
                addWarningTip("addTips", i, "too-low");
              }
            }
          }
          airPointFun.updateModelAngle()
          getRouteLength(); //更新航线长度
        }

        isHeightMode.value = true;
        modelDialog.value = false;
      },

      cancel: () => {
        modelDialog.value = false;
      },

      //左边面板航线高度的input事件
      airHeighInput: () => {
        if (airPlaneData.routePoint?.length <= 0) {
          return;
        }
        //修改所有航点是跟随航线的高度
        let difference = 0;

        for (let i = 0; i < airPlaneData.routePoint?.length; i++) {
          if (airPlaneData.routePoint[i].isRoute) {
            if (
              airPlaneData.heightModel === "ABSOLUTE_HEIGHT" &&
              difference === 0
            ) {
              difference =
                airPlaneData.altitudeHeight * 1 -
                airPlaneData.routePoint[i].pointAltitude;
            }
            if (
              airPlaneData.heightModel === "RELATIVE_HEIGHT" &&
              difference === 0
            ) {
              difference =
                airPlaneData.altitudeHeight * 1 -
                airPlaneData.routePoint[i].pointRelative;
            }

            airPlaneData.routePoint[i].pointAltitude =
              (airPlaneData.routePoint[i].pointAltitude + difference).toFixed(
                1
              ) * 1; //更改跟随航线的航点高度
            if (pointIndex.value !== i) {
              //手动更改没有选中的航点--选中的有watch属性
              airPlaneData.routePoint[i].pointRelative =
                (airPlaneData.routePoint[i].pointRelative + difference).toFixed(
                  1
                ) * 1;

              //对应修改地图中的航点
              airLineFun.publicUpdatePtH(
                airPlaneData.routePoint[i].pointLog,
                airPlaneData.routePoint[i].pointLat,
                airPlaneData.routePoint[i].pointAltitude,
                airPlaneData.routePoint[i].pointRelative,
                airPlaneData.routePoint[i].pointYaw,
                airPlaneData.routePoint[i].routePointId
              );
            }
          }
        }
        //修改视角
        airPointFun.updateModelAngle();
        getRouteLength(); //更新航线长度
      },
      airHeighBlur: () => {
        if (
          airPlaneData.altitudeHeight == "" ||
          (airPlaneData.altitudeHeight < 0 &&
            airPlaneData.heightModel === "RELATIVE_HEIGHT")
        ) {
          airPlaneData.altitudeHeight = 0;
        }
        airLineFun.airHeighInput();
      },

      /**
       * 修改点位置的公共函数
       * @param {* 点经度} longitude
       * @param {* 点纬度} latitude
       * @param {* 点绝对高度} absoHei
       * @param {* 点相对高度} relaHei
       * @param {* 点偏航角} heading
       * @param {* 点id} routePointId
       */
      publicUpdatePtH: (
        longitude,
        latitude,
        absoHei,
        relaHei,
        heading,
        routePointId
      ) => {
        let option = {
          longitude,
          latitude,
          absoHei,
          relaHei,
          heading,
        };
        //修改点的位置
        updatePoint(routeplanId, routePointId, option);
      },
    };

    let flyPointData = reactive({
      //起飞点数据
      isFlypoint: false,
      selectsetFly: false,
      value1: "",
      flyLongitude: "", //起飞经度
      flyLatitude: "", //起飞纬度
      flyHeight: 0, //海拔高度
    });
    /* 起飞点函数对象 */
    const setflyFun = {
      //设置起飞点
      setingFlypoint: () => {
        flyPointData.isFlypoint = !flyPointData.isFlypoint;
        flyPointData.isFlypoint || (flyPointData.selectsetFly = false);
      },
      //打开起飞面板
      openFlyPanel: () => {
        flyPointData.isFlypoint &&
          (flyPointData.selectsetFly = !flyPointData.selectsetFly);
      },
      //起飞点加减高度
      flyUpdateHeight: (size) => {
        flyPointData.flyHeight = flyPointData.flyHeight * 1; //防止清空输入框数据变成string类型
        flyPointData.flyHeight += size;
      },
    };

    /* 地图操作hook */
    let earthFun = {
      cameraPosition,
      updateCurrMark,
      comfirmMarkPoint,
      zoomToImagery,
      zoomTo3DTiles,
      toggleImagery,
      toggle3DTiles,
      addImagery,
      add3DTiles,
      updateMap,
      toggleSceneMode,
      stopMeasureArea,
      startMeasureDistance,
      stopMeasureDistance,
      startMeasureArea,
      enlargeEarth,
      shrinkEarth,
      canMark,
    };
    const {
      buttonData,
      twoThreeNav,
      rangNav,
      mapButtonFun,
      isShowPoint,
      setPosition,
    } = useEarthButton(cursorStyle, earthFun);
    /* end */

    // //地图按钮弹窗右边距
    // let rightSize = ref(""); //绝对定位右边距
    // nextTick(() => {
    //   watchEffect(() => {
    //     if (isShowPoint.value && airPlaneData.routePoint.length > 0) {
    //       isAddaction.value || (rightSize.value = "412px");
    //       isAddaction.value && (rightSize.value = "632px");
    //     } else {
    //       rightSize.value = "82px";
    //     }
    //   });
    // });

    /* 航点面板模块 */
    // let isShowPoint = ref(false); //航点面板显隐藏

    //航点数组对象属性
    let objectParams = {
      routePointId: "", //航点id
      routePointType: "COMMON_WAYPOINT", //航点类型
      pointLog: "", //经度
      pointLat: "", //纬度
      pointAltitude: 100, //海拔高度
      pointRelative: 100, //相对高度
      pointDistance: "", //两点距离
      isRoute: true, //跟随航线
      pointYaw: "", //偏航角角度设置
      efficiencyPointYaw: 0, //高效模式的偏航角
      airRotate: "CLOCKWISE", //飞行器偏航角模式

      speed: {
        flySpeed: 10, //飞行速度
        isRoute: true, //是否跟随航线
      },

      angleOfPitch: 0, //云台俯仰角
      addAction: [], //动作数组
    };
    /* 航点下拉框静态数据 */
    const pointSLData = {
      routePointList: [
        //航点类型下拉框数组
        {
          value: "COMMON_WAYPOINT",
          desc: t("348"),
        },
        {
          value: "START_POINT",
          desc: t("349"),
        },
      ],
      airRotateList: [
        { value: "CLOCKWISE", desc: t("350") },
        { value: "ANTI_CLOCKWISE", desc: t("351") },
        { value: "AUTO", desc: t("334") },
      ],
    };

    let pointIndex = ref(-1); //当前航点下标
    let airPointData = reactive({
      longitude: 0, //显示航点的经纬度
      latitude: 0, //显示航点的纬度
    });
    const incompleteDelete = ref(false);

    /* 航点函数对象 */
    const airPointFun = {
      /**
       * 加减数据操作
       * @param {*数据变量} typeHeight
       * @param {*改变大小} size
       */
      updateHeight: (typeHeight, size) => {
        //跟随航线则不实现加减
        if (!airPlaneData.routePoint[pointIndex.value].isRoute) {
          airPlaneData.routePoint[pointIndex.value][typeHeight] *= 1; //防止输入框数据变成string类型加减出错
          airPlaneData.routePoint[pointIndex.value][typeHeight] =
            (
              airPlaneData.routePoint[pointIndex.value][typeHeight] + size
            ).toFixed(1) * 1;

          // if (
          //   typeHeight === "RELATIVE_HEIGHT" &&
          //   airPlaneData.altitudeHeight < 0
          // ) {
          //   size = size - airPlaneData.altitudeHeight;
          //   airPlaneData.altitudeHeight = 0;
          //   if (size === 0) {
          //     return;
          //   }
          // }

          //海拔高
          typeHeight === "pointAltitude" && (isAltitude.value = true);
          //相对高
          typeHeight === "pointRelative" && (isRelative.value = true);
        }
      },

      updateData: (typeHeight, size) => {
        if (typeHeight === "pointLat") {
          airPlaneData.routePoint[pointIndex.value][typeHeight] *= 1;
          airPlaneData.routePoint[pointIndex.value][typeHeight] += size;
          airPointData.latitude =
            airPlaneData.routePoint[pointIndex.value][typeHeight].toFixed(9) *
            1;
        } else if (typeHeight === "pointLog") {
          airPlaneData.routePoint[pointIndex.value][typeHeight] =
            airPlaneData.routePoint[pointIndex.value][typeHeight] * 1 + size;

          airPointData.longitude =
            airPlaneData.routePoint[pointIndex.value][typeHeight].toFixed(9) *
            1;
        } else {
          let rep = /[\.]/;
          const pre =
            Number(airPlaneData.routePoint[pointIndex.value][typeHeight]) * 1;
          airPlaneData.routePoint[pointIndex.value][typeHeight] = rep.test(pre)
            ? pre.toFixed(2)
            : pre;
          const nest =
            Number(airPlaneData.routePoint[pointIndex.value][typeHeight]) +
            Number(size);
          airPlaneData.routePoint[pointIndex.value][typeHeight] = rep.test(nest)
            ? nest.toFixed(2)
            : nest;
        }

        if (
          ["efficiencyPointYaw", "angleOfPitch"].includes(typeHeight) &&
          pointActionData.checkAction > -1
        ) {
          openAnyActionAngle(pointActionData.checkAction);
        }
      },

      //input框失焦事件
      lostBlur: (value, type, el) => {
        //输入框为空默认值0
        if (value === "") {
          if (type === "speed") {
            airPlaneData.routePoint[pointIndex.value].speed.flySpeed = 1;
          } else {
            airPlaneData.routePoint[pointIndex.value][type] = 0;
          }
        } else {
          el.target.value = Number(el.target.value);
        }

        if (
          ["efficiencyPointYaw", "angleOfPitch"].includes(type) &&
          pointActionData.checkAction > -1
        ) {
          openAnyActionAngle(pointActionData.checkAction);
        }
      },

      updateModelAngle: () => {
        if (pointActionData.checkAction > -1) {
          timerOut && clearTimeout(timerOut);
          timerOut = setTimeout(() => {
            openAnyActionAngle(pointActionData.checkAction);
            timerOut = null;
          }, 0);
        }
      },
      //经纬度输入框失去焦点
      checkData: (value, inputType, type) => {
        //输入框为空或小数点多余9位，则放回输入前的值
        if (value === "" || value.toString().split(".")[1]?.length > 9) {
          airPointData[inputType] =
            airPlaneData.routePoint[pointIndex.value][type].toFixed(9) * 1;
        } else {
          airPlaneData.routePoint[pointIndex.value][type] = value;
        }
      },

      /* 删除航点后的公共操作 index --删除点的坐标值 */
      publicDeleteP: (index) => {
        const delActionList = airPlaneData.routePoint[index].addAction;

        //减掉删除航点的动作面板时间
        pointAtTime -= delActionList.length;

        //删除航点中的动作有拍照
        for (let j in delActionList) {
          if (delActionList[j].actionType === "PHOTOGRAPH") {
            photoNum -= 1;
            airPlaneData.statistic.picture -= 1;
          }
        }

        airPlaneData.routePoint.splice(index, 1); //删除面板中该航点数据
        watchResult && watchResult(); //删除对应航点数据的监听

        //删除存放间隔拍照动作的对应数据
        for (let i = 0; i < photoActionList.length; i++) {
          if (photoActionList[i].pointIndex === index) {
            photoActionList.splice(i, 1);
            i--; //删除后下一个元素顶上来，则没有判断到
          }
        }
        for (let i in photoActionList) {
          if (photoActionList[i].pointIndex > index) {
            photoActionList[i].pointIndex -= 1;
          }
        }

        //删除过低提示
        addWarningTip("clearPointTips", index, "too-low");

        //计算照片
        if (airPlaneData.routePoint.length > 1) {
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum; //计算照片
        }

        pitchAllList.splice(index, 1); //删除对于航点的俯仰角动作
        flyAllList.splice(index, 1); //删除对于航点的飞行器动作
        zoomAllList.splice(index, 1); //删除对于航点的变焦动作

        //删除航点后，修改下标
        if (pointIndex.value >= airPlaneData.routePoint.length) {
          pointIndex.value = airPlaneData.routePoint.length - 1;
          //这里不需要重新调用最新视锥体，会触发监听
        } else {
          watchResult = pointLoadWt(pointIndex.value); // 绑定对应航点属性watch
          changePointAngle();
        }

        getRouteLength(); // 获取航线长度
        routePointLength(); // 航点数量

        //显示选中航点效果
        if (airPlaneData.routePoint.length > 0) {
          selectRoutePoint(
            airPlaneData.routePoint[pointIndex.value].routePointId
          );
        }
      },

      //删除航点
      deletePoint: async () => {
        if (!incompleteDelete.value && pointIndex.value > -1) {
          incompleteDelete.value = true;
          let res = await delLinePoint(
            routeplanId,
            airPlaneData.routePoint[pointIndex.value].routePointId
          );

          if (res) {
            airPointFun.publicDeleteP(pointIndex.value);
            publicUpdateYaw(res, pointIndex.value);
            incompleteDelete.value = false;
            ElMessage.success(t("352"));
          } else {
            incompleteDelete.value = false;
            ElMessage.error(t("353"));
          }
        }
      },

      //根据航线id和航点id获取航点信息
      changePointId: async (flag) => {
        if (flag === "desc") {
          if (pointIndex.value === 0) {
            pointIndex.value = airPlaneData.routePoint.length;
          }
          pointIndex.value -= 1;
        } else {
          pointIndex.value += 1;
          if (pointIndex.value === airPlaneData.routePoint.length) {
            pointIndex.value = 0;
          }
        }
        //修改选中航点效果
        selectRoutePoint(
          airPlaneData.routePoint[pointIndex.value].routePointId
        );

        //更新航点面板经纬度的数据
        airPointData.longitude =
          airPlaneData.routePoint[pointIndex.value].pointLog.toFixed(9) * 1;
        airPointData.latitude =
          airPlaneData.routePoint[pointIndex.value].pointLat.toFixed(9) * 1;
      },

      //海拔高度跟随按钮
      followRouteH: (flage = false) => {
        //选中跟随航线
        if (airPlaneData.routePoint[pointIndex.value].isRoute && flage) {
          if (airPlaneData.heightModel === "ABSOLUTE_HEIGHT") {
            airPlaneData.routePoint[pointIndex.value].pointAltitude =
              airPlaneData.altitudeHeight;
          } else {
            airPlaneData.routePoint[pointIndex.value].pointRelative =
              airPlaneData.altitudeHeight;
          }
        }
      },

      //点的飞行速度跟随按钮
      followRouteS: () => {
        //选中跟随航线
        if (airPlaneData.routePoint[pointIndex.value].speed.isRoute) {
          airPlaneData.routePoint[pointIndex.value].speed.flySpeed =
            airPlaneData.airspeed;
          //计算时间照片
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum;
          getTime(airPlaneData.statistic.airWay); //预计飞行时间
        }
      },

      //修改航点中的飞行速度
      updatePointSpeed: (value) => {
        airPlaneData.routePoint[pointIndex.value].speed.flySpeed += value;
        airPointFun.changeFlyTime();
      },
      //点的飞行速度改变时间
      changeFlyTime: () => {
        // if (airPlaneData.routePoint.length > 1) {
        timerOut && clearTimeout(timerOut);
        timerOut = setTimeout(() => {
          getTime(airPlaneData.statistic.airWay);
          //计算时间照片
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum;
          timerOut = null;
        }, 0);
        // }
      },

      //间隔拍照动作值改变照片数
      changePicValue: () => {
        if (pointIndex.value !== airPlaneData.routePoint.length - 1)
          timerOut && clearTimeout(timerOut);
        timerOut = setTimeout(() => {
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum; //计算照片 ;
          timerOut = null;
        }, 0);
      },
    };

    /**
     * 面板修改点后，修改受影响点的航向角
     * @param {*地图放回的航向值} yawObj
     * @param {*修改对应的下标} index
     */
    function publicUpdateYaw(yawObj, index = pointIndex.value) {
      if (yawObj.currPoint !== undefined) {
        airPlaneData.routePoint[index].pointYaw = changeAgele(
          yawObj.currPoint.heading
        );
      }
      if (yawObj.prevPoint !== undefined) {
        airPlaneData.routePoint[index - 1].pointYaw = changeAgele(
          yawObj.prevPoint.heading
        );
      }
      if (yawObj.nextPoint !== undefined) {
        airPlaneData.routePoint[index + 1].pointYaw = changeAgele(
          yawObj.nextPoint.heading
        );
      }
      //修改视角
      airPointFun.updateModelAngle();
    }

    //换算角度0-360换成-180-180;
    function changeAgele(heading) {
      heading = Number(heading);
      heading =
        heading > 180 ? (heading - 360).toFixed(2) * 1 : heading.toFixed(2) * 1;
      return heading;
    }

    //避免相对和绝对高度循环改变标识
    let isAltitude = ref(false);
    let isRelative = ref(false);

    /**
     * 航点-位置数据监听函数
     * @param {*航点下标} index
     */
    function pointLoadWt(index) {
      return watch(
        [
          () => airPlaneData.routePoint[index].pointAltitude, //海拔高
          () => airPlaneData.routePoint[index].pointRelative, //相对高
          () => airPlaneData.routePoint[index].pointLog, //经度
          () => airPlaneData.routePoint[index].pointLat, //纬度
        ],
        (
          [newAltitude, newRelative, newlongitude, newLatitude],
          [oldAltitude, oldRelative, oldlongitude, oldLatitude]
        ) => {
          //判断绝对高度是否改变
          if (newAltitude !== oldAltitude && isAltitude.value === true) {
            let res = newAltitude - oldAltitude;
            airPlaneData.routePoint[pointIndex.value].pointRelative =
              (
                airPlaneData.routePoint[pointIndex.value].pointRelative + res
              ).toFixed(1) * 1;
            isAltitude.value = false;
            isRelative.value = false;
          }
          //判断相对高度是否改变
          if (newRelative !== oldRelative && isRelative.value === true) {
            let res = newRelative - oldRelative;
            airPlaneData.routePoint[pointIndex.value].pointAltitude =
              (
                airPlaneData.routePoint[pointIndex.value].pointAltitude + res
              ).toFixed(1) * 1;

            isRelative.value = false;
            isAltitude.value = false;
          }
          if (newRelative !== oldRelative) {
            //航点提示
            if (newRelative < 20 && oldRelative >= 20) {
              addWarningTip("addTips", pointIndex.value, "too-low");
            }
            if (oldRelative < 20 && newRelative > 20) {
              addWarningTip("clearTips", pointIndex.value, "too-low");
            }
          }

          //如不是相对高引起的监听，需要修改点位置
          if (newRelative === oldRelative) {
            let option = {
              longitude: newlongitude,
              latitude: newLatitude,
              absoHei: newAltitude,
              relaHei: airPlaneData.routePoint[pointIndex.value].pointRelative,
              heading: airPlaneData.routePoint[pointIndex.value].pointYaw,
            };

            //注意--如果加上监听偏航角，则监听函数因为这个多调用一次
            if (newlongitude !== oldlongitude || newLatitude !== oldLatitude) {
              // publicUpdateYaw(yawObj, pointIndex.value);
              //修改点的位置
              updatePoint(
                routeplanId,
                airPlaneData.routePoint[pointIndex.value].routePointId,
                option,
                publicUpdateYaw
              );
            } else {
              //修改点的位置
              updatePoint(
                routeplanId,
                airPlaneData.routePoint[pointIndex.value].routePointId,
                option
              );
            }

            getRouteLength(); //更新航线长度
          }
        }
      );
    }

    //监听函数返回值
    let watchResult = null;

    /* 监听航点下标， */
    watch(pointIndex, (newValue, oldValue) => {
      if (newValue > -1) {
        changePointAngle(); //打开航点最新视锥体
        watchResult && watchResult(); //清空之前的watch
        watchResult = pointLoadWt(newValue); // 绑定对应航点属性watch
      } else {
        isAddaction.value = false; //关闭动作列表
        closeAngle();
      }
    });

    //打开/关闭 添加动作的面板
    function openAddAction(open = true) {
      //open是否打开
      isAddaction.value = open;
      buttonData.ischeck = "";
      cursorStyle.value = "default";
    }

    /**
     * 动作内容面板模块
     * 1.偏航角逻辑
     * 飞行器偏航角添加时，以该动作之前最近的飞行器偏航角角度为初始值 ，相机角度为 该动作之前最近的飞行器相机角度+云台偏航角角度
     * 飞行器相机角度存放的值由第一个云台偏航角一直相加到当前动作，但显示是将当前飞行器偏航角+飞行器相机角度
     * 当点击动作俯仰角则会打开，删除动作则会关闭，
     */
    const pointActionData = reactive({
      updatePhoto: "", //拍照修改值
      updateVideo: "", //录像修改值
      updateFolder: "", //文件夹值
      checkAction: -1, //选中的动作
    });

    //切换航点，关闭原本视锥体，并且开启新航点最后动作视锥体
    function changePointAngle() {
      const actionData = airPlaneData.routePoint[pointIndex.value].addAction;

      //前航点有选中动作，则先赋为初始值
      if (pointActionData.checkAction !== -1) {
        pointActionData.checkAction = -1;
      }

      //获取选中航点的动作列表中可打开视锥体的动作
      if (actionData.length > 0) {
        setTimeout(() => {
          pointActionData.checkAction = actionData.length - 1; //防止前后航点的动作打开视角的index相同，导致watch没就监听到
        }, 0);
      }
    }

    //监听动作内容选中的面板
    watch(
      () => pointActionData.checkAction,
      (newValue) => {
        if (newValue > -1) {
          openAnyActionAngle(newValue);
        }

        //删除动作时触发
        if (newValue < 0) {
          closeAngle();
          updateEntityHead(
            //没有动作，就打开默认轮盘
            airPlaneData.routePoint[pointIndex.value].routePointId,
            airPlaneData.routePoint[pointIndex.value].pointYaw,
            "angle"
          );
        }
      }
    );

    //打开任意动作的视锥体
    function openAnyActionAngle(actionIndex) {
      let actionData = airPlaneData.routePoint[pointIndex.value].addAction;
      //选中飞行器偏航角
      if (actionData[actionIndex].actionType === "FLY_YAW_ANGLE") {
        //获取经、纬、高度
        let { pointLog, pointLat, pointAltitude, routePointId } =
          airPlaneData.routePoint[pointIndex.value];

        //获取偏航角
        let heading =
          actionData[actionIndex].actionParam +
          actionData[actionIndex].cameraAngle;

        //获取俯仰角
        const pitch = pointActionFun.getPitch(actionIndex);

        const zoom = pointActionFun.getZoom(actionIndex); //获取变焦
        //打开航点视角
        openPointAngle(
          pointLog,
          pointLat,
          pointAltitude,
          heading,
          pitch - 90,
          0,
          zoom
        );
        //修改轮盘
        // updateEntityHead(routePointId, heading, "angle");
      } else if (actionData[actionIndex].actionType === "PTZ_YAW_ANGLE") {
        //选中云台偏航角
        //打开云台动偏航角作下的视锥体
        pointActionFun.ptzOpenAngle(
          actionIndex,
          actionData[actionIndex].actionParam
        );
      } else if (actionData[actionIndex].actionType === "PTZ_PITCH_ANGLE") {
        //选中云台俯仰角
        //打开云台动俯仰角作下的视锥体
        pointActionFun.updatePitchAngle(
          actionIndex,
          actionData[actionIndex].actionParam
        );
      } else if (actionData[actionIndex].actionType === "CAMERA_ZOOM") {
        //选中变焦动作
        //打开变焦动作下的视锥体
        pointActionFun.updateZoom(
          actionIndex,
          actionData[actionIndex].actionParam
        );
      } else {
        //选中其他动作
        //获取经、纬、高度
        const { pointLog, pointLat, pointAltitude } =
          airPlaneData.routePoint[pointIndex.value];

        const heading = pointActionFun.openOtherActionAngle(actionIndex);

        const pitch = pointActionFun.getPitch(actionIndex); //获取之前俯仰角
        const zoom = pointActionFun.getZoom(actionIndex); //获取变焦

        //调用地图--打开视锥体
        openPointAngle(
          pointLog,
          pointLat,
          pointAltitude,
          heading,
          pitch - 90,
          0,
          zoom
        );

        //修改轮盘
        // updateEntityHead(routePointId, heading, "angle");
      }
    }

    let pitchAllList = []; //云台俯仰角动作数据---结构(对象Map对象)
    let flyAllList = []; //飞行器动作数据---结构(对象Map对象)
    let zoomAllList = []; //变焦动作数据---结构(对象Map对象)
    //动作内容函数对象
    const pointActionFun = {
      //删除动作
      deleteAction: (index) => {
        const actionData = airPlaneData.routePoint[pointIndex.value].addAction;

        //删除航点对象里面对应动作数据
        let delValue = actionData.splice(index, 1); //删除动作元素
        pointActionData.checkAction = -1; //选中动作下标为-1，表示无

        if (delValue[0].actionType === "HOVER") {
          pointAtTime -= delValue[0].actionParam;
        } else {
          pointAtTime -= 1; //减少预计时间
        }
        airPlaneData.statistic.expectedTime = airLineTime + pointAtTime; //总时间
        conversionTime(airPlaneData.statistic.expectedTime); //换算总时间

        //删除动作为拍照
        if (delValue[0].actionType === "PHOTOGRAPH") {
          photoNum -= 1;
          airPlaneData.statistic.picture -= 1;
        }
        //删除动作为云台偏航
        if (delValue[0].actionType === "PTZ_YAW_ANGLE") {
          pointActionFun.deleteYunCauseFly(index, actionData);
        }

        //删除动作为俯仰角
        aloneActionListDelete(
          pitchAllList,
          delValue[0].actionType,
          "PTZ_PITCH_ANGLE",
          index
        );

        //删除动作为变焦
        aloneActionListDelete(
          zoomAllList,
          delValue[0].actionType,
          "CAMERA_ZOOM",
          index
        );

        // if (delValue[0].actionType === "PTZ_PITCH_ANGLE") {
        //   //改航点只有一个俯仰角 或删除动作是最后一个动作
        //   if (
        //     pitchAllList[pointIndex.value].size === 1 ||
        //     index === addAction.length
        //   ) {
        //     pitchAllList[pointIndex.value].delete(index);
        //   } else {
        //     pitchAllList[pointIndex.value].clear();
        //     addAction.forEach((item, indexes) => {
        //       item.actionType === "PTZ_PITCH_ANGLE" &&
        //         pitchAllList[pointIndex.value].set(indexes, item.actionParam);
        //     });
        //   }
        // } else {
        //   if (
        //     pitchAllList[pointIndex.value] !== undefined &&
        //     pitchAllList[pointIndex.value].size !== 0
        //   ) {
        //     pitchAllList[pointIndex.value].clear();
        //     addAction.forEach((item, indexes) => {
        //       item.actionType === "PTZ_PITCH_ANGLE" &&
        //         pitchAllList[pointIndex.value].set(indexes, item.actionParam);
        //     });
        //   }
        // }

        // 若删除动作为飞行器偏航角
        aloneActionListDelete(
          flyAllList,
          delValue[0].actionType,
          "FLY_YAW_ANGLE",
          index
        );
        // 删除的动作和间隔拍照相关
        if (typeArr.includes(delValue[0].actionType)) {
          let flage = null;
          //删除photoActionList中的动作
          for (let i = 0; i < photoActionList.length; i++) {
            if (
              photoActionList[i].pointIndex === pointIndex.value &&
              photoActionList[i].actionIndex === index
            ) {
              photoActionList.splice(i, 1);
              flage = i;
              break;
            }
          }

          if (flage !== null) {
            //动作index前移
            for (let j = flage; j < photoActionList.length; j++) {
              if (
                photoActionList[j].pointIndex === pointIndex.value &&
                photoActionList[j].actionIndex > index
              ) {
                photoActionList[j].actionIndex -= 1;
              }
            }
          }
          //计算时间照片
          airPlaneData.statistic.picture =
            publicComPhoto(0, photoActionList.length) + photoNum;
        } else {
          for (let j = 0; j < photoActionList.length; j++) {
            if (
              photoActionList[j].pointIndex === pointIndex.value &&
              photoActionList[j].actionIndex > index
            ) {
              photoActionList[j].actionIndex -= 1;
            }
            if (photoActionList[j].pointIndex > pointIndex.value) {
              break;
            }
          }
        }

        //如果删除动作后,使第一动作为飞行器,且为高效模式
        if (
          airPlaneData.airYaw === t("314") &&
          pointIndex.value !== 0 &&
          actionData.length > 0 &&
          actionData[0].actionType === "FLY_YAW_ANGLE"
        ) {
          airPlaneData.routePoint[pointIndex.value].efficiencyPointYaw =
            actionData[0].actionParam;
        }
      },

      //保存修改文件名称的值
      saveUpdate: (type, index) => {
        type === "editphoto"
          ? (airPlaneData.routePoint[pointIndex.value].addAction[
              index
            ].actionParam = pointActionData.updatePhoto)
          : type === "editvideo"
          ? (airPlaneData.routePoint[pointIndex.value].addAction[
              index
            ].actionParam = pointActionData.updateVideo)
          : (airPlaneData.routePoint[pointIndex.value].addAction[
              index
            ].actionParam = pointActionData.updateFolder);
      },

      /**
       *修改俯仰角调用打开视锥体
       * @param {*当前动作下标} index
       * @param {*当前俯仰角数值} pithcValue
       */
      updatePitchAngle: (index, pithcValue) => {
        if (timerOut) {
          clearTimeout(timerOut);
        }
        timerOut = setTimeout(() => {
          //获取经、纬、高度
          let { pointLog, pointLat, pointAltitude, routePointId } =
            airPlaneData.routePoint[pointIndex.value];

          const heading = pointActionFun.openOtherActionAngle(index);

          // let { pitch, isHaspitch } = pointActionFun.getPitch(index); //获取之前俯仰角
          // isHaspitch && (pitch += pithcValue); //如果之前有俯仰角，则需加上现在选中的
          // isHaspitch || (pitch = pithcValue); //如果之前没有，则直接赋值现在的，因为默认值不是0

          pitchAllList[pointIndex.value].set(index, pithcValue);
          const zoom = pointActionFun.getZoom(index); //获取变焦

          //调用地图--打开视锥体
          openPointAngle(
            pointLog,
            pointLat,
            pointAltitude,
            heading,
            pithcValue - 90,
            0,
            zoom
          );

          //修改轮盘
          // updateEntityHead(routePointId, heading, "angle");

          timerOut = null;
        }, 0);
      },

      /**
       *修改变焦调用打开视锥体
       * @param {*当前动作下标} index
       * @param {*当前变焦数值} zoomValue
       */
      updateZoom: (index, zoomValue) => {
        if (timerOut) {
          clearTimeout(timerOut);
        }
        timerOut = setTimeout(() => {
          //获取经、纬、高度
          let { pointLog, pointLat, pointAltitude, routePointId } =
            airPlaneData.routePoint[pointIndex.value];

          const heading = pointActionFun.openOtherActionAngle(index);
          const pitch = pointActionFun.getPitch(index);
          zoomAllList[pointIndex.value].set(index, zoomValue);

          //调用地图--打开视锥体
          openPointAngle(
            pointLog,
            pointLat,
            pointAltitude,
            heading,
            pitch - 90,
            0,
            zoomValue
          );

          timerOut = null;
        }, 0);
      },

      /**
       *飞行器偏航角改变时--视锥体对应变化
       * @param {*飞行器角度} flyAngle
       * @param {*相机角度} cameraAngle
       * @param {*当前动作下标} index
       */
      updataFAngle: (flyAngle, cameraAngle, index) => {
        timerOut && clearTimeout(timerOut);
        timerOut = setTimeout(() => {
          let { pointLog, pointLat, pointAltitude, routePointId } =
            airPlaneData.routePoint[pointIndex.value];
          if (index === 0 && pointIndex.value !== 0) {
            airPlaneData.routePoint[pointIndex.value].efficiencyPointYaw =
              flyAngle;
          }
          const pitch = pointActionFun.getPitch(index);
          const zoom = pointActionFun.getZoom(index);
          let heading = cameraAngle + flyAngle; //最终偏航角

          openPointAngle(
            pointLog,
            pointLat,
            pointAltitude,
            heading,
            pitch - 90,
            0,
            zoom
          );
          flyAllList[pointIndex.value].set(index, {
            actionParam: flyAngle,
            cameraAngle: cameraAngle,
          });
          //修改轮盘
          // updateEntityHead(routePointId, heading, "angle");

          timerOut = null;
        }, 0);
      },

      /**
       * 选中云台偏航角--开启视锥体
       * @param {* 当前动作下标} index
       * @param {* 当前动作值} value
       */
      //
      ptzOpenAngle: (index, value) => {
        let { pointLog, pointLat, pointAltitude, routePointId } =
          airPlaneData.routePoint[pointIndex.value];

        const pitch = pointActionFun.getPitch(index);
        const zoom = pointActionFun.getZoom(index);

        let heading = pointActionFun.openOtherActionAngle(index); //偏航角
        heading += value;
        // if (index > 0) {
        //   const { cameraAngle, flyAngle } = comAddAngle(index); //获取云台之前相机角度-飞机角度
        //   heading = cameraAngle + value + flyAngle;
        // } else {
        //   if (pointIndex.value === 0) {
        //     //第一个点第一个动作
        //     heading = value;
        //   } else {
        //     heading =
        //       value + airPlaneData.routePoint[pointIndex.value].pointYaw;
        //   }
        // }

        //打开航点视角--调用地图
        openPointAngle(
          pointLog,
          pointLat,
          pointAltitude,
          heading,
          pitch - 90,
          0,
          zoom
        );

        //修改轮盘
        // updateEntityHead(routePointId, heading, "angle");
      },

      /**
       * 云台偏航角改变--修改相机角度和视锥体
       * @param {* 当前动作下标} index
       * @param {* 当前动作值} value
       */
      updataYCamera: (index, value) => {
        timerOut && clearTimeout(timerOut);
        timerOut = setTimeout(() => {
          let actionData = airPlaneData.routePoint[pointIndex.value].addAction; //动作数组

          pointActionFun.ptzOpenAngle(index, value);

          //判断云台后面有无被影响的偏航角
          if (actionData.length - 1 === index) {
            return;
          }

          const { cameraAngle } = comAddAngle(index); //获取云台之前相机角度

          //设置到下个云台之前飞行器偏航角的相机角度
          for (let i = index + 1; i < actionData.length; i++) {
            //如果改变云台偏航角会影响全部，则注释下边if
            if (actionData[i].actionType === "PTZ_YAW_ANGLE") {
              i = actionData.length;
            }
            if (actionData[i].actionType === "FLY_YAW_ANGLE") {
              actionData[i].cameraAngle = cameraAngle + value; //相机角度
            }
          }
          timerOut = null;
        }, 0);
      },

      //删除云台偏航角后,对飞行器动作产生的影响
      deleteYunCauseFly: (index, actionData) => {
        if (actionData.length !== 0) {
          const { cameraAngle } = comAddAngle(index); //获取云台之前相机角度
          let isReSort = false;
          for (let i = index; i < actionData.length; i++) {
            //如果改变云台偏航角会影响全部，则注释下边if
            if (actionData[i].actionType === "PTZ_YAW_ANGLE") {
              break;
            }
            if (actionData[i].actionType === "FLY_YAW_ANGLE") {
              isReSort = true;
              actionData[i].cameraAngle = cameraAngle;
            }
          }
          if (isReSort) {
            //重新排序
            if (
              flyAllList[pointIndex.value] !== undefined &&
              flyAllList[pointIndex.value].size !== 0
            ) {
              flyAllList[pointIndex.value].clear();
              airPlaneData.routePoint[pointIndex.value].addAction.forEach(
                (item, indexes) => {
                  if (item.actionType === "FLY_YAW_ANGLE") {
                    flyAllList[pointIndex.value].set(indexes, {
                      actionParam: item.actionParam,
                      cameraAngle: item.cameraAngle,
                    });
                  }
                }
              );
            }
          }
        }
      },

      //获取当前动作之前的云台俯仰角
      getPitch: (index) => {
        let actionData = airPlaneData.routePoint[pointIndex.value].addAction;

        if (airPlaneData.pitch === t("256")) {
          let pitch = airPlaneData.routePoint[pointIndex.value].angleOfPitch;
          if (
            index !== 0 &&
            pitchAllList[pointIndex.value] !== undefined &&
            pitchAllList[pointIndex.value].size !== 0
          ) {
            for (let i = index - 1; i >= 0; i--) {
              if (actionData[i].actionType === "PTZ_PITCH_ANGLE") {
                pitch = actionData[i].actionParam;
                break;
              }
            }
          }
          return pitch;
        }
        //手动控制
        // let manualPitch = 0;
        // if (index === 0 && pointIndex.value === 0) {
        //   manualPitch = 0;
        // } else {
        //   if (
        //     index !== 0 &&
        //     pitchAllList[pointIndex.value] !== undefined &&
        //     pitchAllList[pointIndex.value].size !== 0
        //   ) {
        //     for (let i = index - 1; i >= 0; i--) {
        //       if (actionData[i].actionType === "PTZ_PITCH_ANGLE") {
        //         manualPitch = actionData[i].actionParam;
        //         return manualPitch;
        //       }
        //     }
        //   }
        //   if (Object.keys(pitchAllList).length > 0) {
        //     for (let i = pointIndex.value - 1; i >= 0; i--) {
        //       if (pitchAllList[i] !== undefined && pitchAllList[i].size !== 0) {
        //         manualPitch = [...pitchAllList[i].values()].slice(-1)[0];
        //         break;
        //       }
        //     }
        //   }
        // }
        //手动控制
        const manualPitch = pointActionFun.publicGetLastAction(
          pitchAllList,
          index,
          "PTZ_PITCH_ANGLE"
        );
        return manualPitch;
      },

      //获取当前动作之前的变焦
      getZoom: (index) => {
        let zoom = pointActionFun.publicGetLastAction(
          zoomAllList,
          index,
          "CAMERA_ZOOM"
        );

        zoom = zoom === 0 ? leftState.zoomRatio.min : zoom;
        return zoom;
      },

      publicGetLastAction: (list, index, type) => {
        const actionData = airPlaneData.routePoint[pointIndex.value].addAction;

        let params = 0;
        if (index === 0 && pointIndex.value === 0) {
          params = 0;
        } else {
          if (
            index !== 0 &&
            list[pointIndex.value] !== undefined &&
            list[pointIndex.value].size !== 0
          ) {
            for (let i = index - 1; i >= 0; i--) {
              if (actionData[i].actionType === type) {
                params = actionData[i].actionParam;
                return params;
              }
            }
          }
          if (Object.keys(list).length > 0) {
            for (let i = pointIndex.value - 1; i >= 0; i--) {
              if (list[i] !== undefined && list[i].size !== 0) {
                params = [...list[i].values()].slice(-1)[0];
                break;
              }
            }
          }
        }
        return params;
      },

      //除去偏航角的动作，根据模式获取偏航角
      openOtherActionAngle: (index) => {
        let heading = 0; //偏航角
        const { cameraAngle, flyAngle, isHasFly } = comAddAngle(index);
        switch (airPlaneData.airYaw) {
          case t("336"):
            if (!isHasFly) {
              //当前航点第一个,且该动作前没有偏航角
              if (pointIndex.value === 0) {
                heading = cameraAngle;
              }
              //不是第一个点,且偏航角单动作有值
              else if (
                pointIndex.value !== 0 &&
                Object.keys(flyAllList).length > 0
              ) {
                let isHasFindFly = false;
                for (let i = pointIndex.value - 1; i >= 0; i--) {
                  if (flyAllList[i] !== undefined && flyAllList[i].size !== 0) {
                    heading =
                      [...flyAllList[i].values()].slice(-1)[0].actionParam +
                      cameraAngle;
                    isHasFindFly = true;

                    break;
                  }
                }
                if (!isHasFindFly) {
                  heading =
                    cameraAngle +
                    airPlaneData.routePoint[pointIndex.value - 1].pointYaw;
                }
              } else {
                //不是第一个航点,且没有单动作列表
                heading =
                  cameraAngle +
                  airPlaneData.routePoint[pointIndex.value - 1].pointYaw;
              }
            } else {
              heading = cameraAngle + flyAngle;
            }
            break;
          case t("314"):
            if (!isHasFly) {
              if (pointIndex.value === 0) {
                heading = cameraAngle;
              } else {
                heading =
                  cameraAngle +
                  airPlaneData.routePoint[pointIndex.value].efficiencyPointYaw;
              }
            } else {
              heading = cameraAngle + flyAngle;
            }
            break;

          default:
            // if (index === 0) {
            //   if (pointIndex.value === 0) {
            //     heading = 0;
            //   } else {
            //     heading = airPlaneData.routePoint[pointIndex.value].pointYaw;
            //   }
            // } else {
            //   const { cameraAngle, flyAngle, isHasFly } = comAddAngle(index); //获取角度
            //   heading = cameraAngle + flyAngle;
            //   isHasFly ||
            //     (heading += airPlaneData.routePoint[pointIndex.value].pointYaw);
            // }
            if (!isHasFly) {
              if (pointIndex.value === 0) {
                heading = cameraAngle;
              } else {
                heading =
                  airPlaneData.routePoint[pointIndex.value - 1].pointYaw +
                  cameraAngle;
              }
            } else {
              heading = cameraAngle + flyAngle;
            }
            break;
        }
        return heading;
      },
    };

    /**
     *添加动作维护的单动作数组
     * @param {*[[Map,Map]]} list 动作数组
     * @param {*} params 动作对应值
     */
    function aloneActionListAdd(list, params) {
      if (list[pointIndex.value] === undefined) {
        list[pointIndex.value] = new Map([
          [airPlaneData.routePoint[pointIndex.value].addAction.length, params],
        ]);
      } else {
        list[pointIndex.value].set(
          airPlaneData.routePoint[pointIndex.value].addAction.length,
          params
        );
      }
    }

    /**
     * 删除动作修改维护的单动作数组
     * @param {*[[Map,Map]]} list 维护的单动作数组
     * @param {*} currentType 当前动作类型
     * @param {*} decisiontype 单动作的类型
     * @param {*} index 当前动作的索引
     */
    function aloneActionListDelete(list, currentType, decisiontype, index) {
      const addAction = airPlaneData.routePoint[pointIndex.value].addAction;

      //删除动作为该单动作列表类型,且单列表动作这航点只有一个该动作或删除动作是最后的动作
      const flage =
        currentType === decisiontype &&
        (list[pointIndex.value].size === 1 || index === addAction.length);

      if (flage) {
        list[pointIndex.value].delete(index);
      } else {
        //重新排序
        if (
          list[pointIndex.value] !== undefined &&
          list[pointIndex.value].size !== 0
        ) {
          list[pointIndex.value].clear();
          addAction.forEach((item, indexes) => {
            if (item.actionType === decisiontype) {
              decisiontype !== "FLY_YAW_ANGLE" &&
                list[pointIndex.value].set(indexes, item.actionParam);
              decisiontype === "FLY_YAW_ANGLE" &&
                list[pointIndex.value].set(indexes, {
                  actionParam: item.actionParam,
                  cameraAngle: item.cameraAngle,
                });
            }
          });
        }
      }
    }

    /* 拖拽功能 */
    let isDraggable = ref(false);
    let dragging = ref(null); //拖拽元素下标
    let ending = ref(null); //拖拽进入元素下标

    function handleDragStart(index) {
      dragging.value = index; //开始拖动时，暂时保存当前拖动的数据。
    }
    // function handleDragOver(e) {
    //   e.dataTransfer.dropEffect = "move";
    // }

    function handleDragEnter(e, index) {
      e.dataTransfer.dropEffect = "move"; //设置被拖拽节点，允许拖动效果
      ending.value = index;
    }

    function handleDragEnd(e) {
      e.dataTransfer.effectAllowed = "move"; //本次拖拉中允许的效果
      isDraggable.value = false;
      if (ending.value == dragging.value) return;

      var newsAction = [
        ...toRaw(airPlaneData.routePoint[pointIndex.value].addAction),
      ]; //拷贝一份数据进行交换操作。

      newsAction.splice(
        ending.value,
        0,
        ...newsAction.splice(dragging.value, 1)
      ); //交换位置

      airPlaneData.routePoint[pointIndex.value].addAction.splice(0);
      airPlaneData.routePoint[pointIndex.value].addAction.push(...newsAction);

      let actionList = airPlaneData.routePoint[pointIndex.value].addAction;

      //拖拽动作类型为飞行器偏航角
      if (actionList[ending.value].actionType === "FLY_YAW_ANGLE") {
        dragFlyAngle(ending.value); //修改飞行器偏航角动作数据
      }
      if (actionList[ending.value].actionType === "PTZ_YAW_ANGLE") {
        //拖拽动作类型为云台偏航角
        dragYunAngle(ending.value, dragging.value); //修改这云台原先位置和结束位置影响的列表动作数据
      }

      resetCustomActionList();

      //修改photoActionList中的动作 ---先删后重加
      let insert = null; //插入位置
      for (let i = 0; i < photoActionList.length; i++) {
        if (photoActionList[i].pointIndex > pointIndex.value) {
          break;
        }
        if (photoActionList[i].pointIndex === pointIndex.value) {
          photoActionList.splice(i, 1);
          insert = i;
          i--;
        }
      }

      airPlaneData.routePoint[pointIndex.value].addAction.forEach(
        (item, index) => {
          if (typeArr.includes(item.actionType)) {
            photoActionList.splice(insert, 0, {
              pointIndex: pointIndex.value,
              actionIndex: index,
              action: item,
            });
            insert++;
          }
        }
      );
      if (typeArr.includes(actionList[ending.value].actionType)) {
        //计算时间照片
        airPlaneData.statistic.picture =
          publicComPhoto(0, photoActionList.length) + photoNum;
      }

      pointActionData.checkAction = ending.value;

      nextTick(() => {
        dragging.value = null;
        ending.value = null;
      }); //清空
    }

    /*拖拽飞行器偏航角--改变 角度*/
    function dragFlyAngle(endIndex) {
      const { cameraAngle } = comAddAngle(endIndex); //获取拖拽后，当前动作之前的相机、飞行器偏航角
      let actionList = airPlaneData.routePoint[pointIndex.value].addAction;
      actionList[endIndex].cameraAngle = cameraAngle;
    }

    /*拖拽云台偏航角--改变 角度  */
    function dragYunAngle(endIndex, dragIndex) {
      let actionList = airPlaneData.routePoint[pointIndex.value].addAction;
      //从上->下
      if (endIndex > dragIndex) {
        const { cameraAngle } = comAddAngle(dragIndex); //上边云台
        const afterCameraAngle = actionList[endIndex].actionParam; //下边云台
        for (let i = dragIndex; i < endIndex; i++) {
          if (actionList[i].actionType === "PTZ_YAW_ANGLE") {
            break;
          }
          if (actionList[i].actionType === "FLY_YAW_ANGLE") {
            actionList[i].cameraAngle = cameraAngle; //相机角度
          }
        }
        for (let j = endIndex + 1; j < actionList.length; j++) {
          if (actionList[j].actionType === "PTZ_YAW_ANGLE") {
            break;
          }
          if (actionList[j].actionType === "FLY_YAW_ANGLE") {
            actionList[j].cameraAngle = afterCameraAngle; //相机角度
          }
        }
      } else {
        //从下到上
        const { cameraAngle } = comAddAngle(dragIndex + 1); //下边云台
        const beforeCameraAngle = actionList[endIndex].actionParam; //上边云台
        for (let i = endIndex + 1; i <= dragIndex; i++) {
          if (actionList[i].actionType === "PTZ_YAW_ANGLE") {
            break;
          }
          if (actionList[i].actionType === "FLY_YAW_ANGLE") {
            actionList[i].cameraAngle = beforeCameraAngle; //相机角度
          }
        }
        for (let j = dragIndex + 1; j < actionList.length; j++) {
          if (actionList[j].actionType === "PTZ_YAW_ANGLE") {
            break;
          }
          if (actionList[j].actionType === "FLY_YAW_ANGLE") {
            actionList[j].cameraAngle = cameraAngle; //相机角度
          }
        }
      }
    }

    //重新排序所有维护数组
    function resetCustomActionList() {
      const flage =
        (pitchAllList[pointIndex.value] !== undefined &&
          pitchAllList[pointIndex.value].size > 0) ||
        (flyAllList[pointIndex.value] !== undefined &&
          flyAllList[pointIndex.value].size > 0) ||
        (zoomAllList[pointIndex.value] !== undefined &&
          zoomAllList[pointIndex.value].size > 0);

      if (flage) {
        pitchAllList[pointIndex.value] !== undefined &&
          pitchAllList[pointIndex.value].clear();
        flyAllList[pointIndex.value] !== undefined &&
          flyAllList[pointIndex.value].clear();
        zoomAllList[pointIndex.value] !== undefined &&
          zoomAllList[pointIndex.value].clear();

        airPlaneData.routePoint[pointIndex.value].addAction.forEach(
          (item, index) => {
            switch (item.actionType) {
              case "FLY_YAW_ANGLE":
                flyAllList[pointIndex.value].set(index, {
                  actionParam: item.actionParam,
                  cameraAngle: item.cameraAngle,
                });
                break;
              case "PTZ_PITCH_ANGLE":
                pitchAllList[pointIndex.value].set(index, item.actionParam);
                break;
              case "CAMERA_ZOOM":
                zoomAllList[pointIndex.value].set(index, item.actionParam);
                break;
              default:
                break;
            }
          }
        );
      }
    }

    //重置相机列表数据
    function resetCameraActionList() {
      if (Object.keys(zoomAllList).length > 0) {
        zoomAllList.forEach((zoomItem, zoomIndex) => {
          if (zoomItem !== undefined && zoomItem.size > 0) {
            zoomItem.clear();
            airPlaneData.routePoint[zoomIndex].addAction.forEach(
              (item, index) => {
                if (item.actionType === "CAMERA_ZOOM") {
                  //由最大最小值导致原本值变化
                  item.actionParam =
                    item.actionParam > leftState.zoomRatio.max
                      ? leftState.zoomRatio.max
                      : item.actionParam < leftState.zoomRatio.min
                      ? leftState.zoomRatio.min
                      : item.actionParam;

                  zoomItem.set(index, item.actionParam);
                }
              }
            );
          }
        });
      }
    }

    /* 添加动作面板模块 */
    let isAddaction = ref(false);
    //负载动作静态数据
    const loadAction = [
      { desc: t("306"), value: "PHOTOGRAPH" },
      { desc: t("307"), value: "START_VIDEO" },
      { desc: t("308"), value: "END_VIDEO" },
      { desc: t("309"), value: "CAMERA_ZOOM" },
      // { desc: t("310"), value: "CREATE_FOLDER" },
      { desc: t("311"), value: "PHOTO_INTERVAL" },
      { desc: t("312"), value: "PHOTO_EQUIDISTANCE" },
      { desc: t("313"), value: "END_PHOTO" },
    ];

    /**
     * 获取当前点动作列表中index之前的最终偏航角
     * @param {*Number} index 动作下标
     */
    function comAddAngle(index) {
      const objAngle = {
        cameraAngle: 0, //相机角度
        flyAngle: 0, //飞机角度
        isHasFly: false, //是否有飞行器
      };

      let flyIndex = -1; //飞行器偏航角对象下标
      let actionData = airPlaneData.routePoint[pointIndex.value].addAction; //动作数组

      if (actionData.length === 0) {
        return objAngle;
      }

      //查询数组中最后一个（当前动作之前最近的）飞行器偏航角
      for (let i = index - 1; i >= 0; i--) {
        if (actionData[i].actionType === "FLY_YAW_ANGLE") {
          objAngle.cameraAngle = actionData[i].cameraAngle;
          objAngle.flyAngle = actionData[i].actionParam;
          flyIndex = i;
          objAngle.isHasFly = true;
          break; //退出循环
        }
      }

      //查询飞行器偏航角后有无云台偏航角
      if (flyIndex !== -1) {
        // for (let i = flyIndex; i < index; i++) {
        //   if (actionData[i].actionType === "PTZ_YAW_ANGLE") {
        //     objAngle.cameraAngle += actionData[i].actionParam;
        //   }
        // }
        for (let i = index - 1; i > flyIndex; i--) {
          if (actionData[i].actionType === "PTZ_YAW_ANGLE") {
            objAngle.cameraAngle = actionData[i].actionParam;
            break;
          }
        }
      } else {
        //无飞行器动作时获取最近云台偏航角
        for (let i = index - 1; i >= 0; i--) {
          if (actionData[i].actionType === "PTZ_YAW_ANGLE") {
            objAngle.cameraAngle = actionData[i].actionParam;
            break;
          }
        }
      }

      return objAngle;
    }

    let typeArr = ["PHOTO_INTERVAL", "PHOTO_EQUIDISTANCE", "END_PHOTO"];
    let photoNum = 0; //拍照动作的照片数

    let oldStopParam = 10;
    /**
     * @description: 获取点击动作的旧悬停值
     * @param {*Number} index 当前航点第n个动作
     * @return {*}
     */
    function getOldParam(index) {
      const action = airPlaneData.routePoint[pointIndex.value].addAction[index];
      if (action.actionType === "HOVER") {
        oldStopParam = action.actionParam;
      }
    }

    /**
     * @description: 修改悬停动作的值
     * @param {*Number} index 当前航点第n个动作
     * @param {*String} type 操作类型
     * @param {*Number} size 值
     * @return {*}
     */
    function updateStopParam(index, type, size) {
      const action = airPlaneData.routePoint[pointIndex.value].addAction[index];
      if (type === "button") {
        action.actionParam += size;
        pointAtTime += size;
      } else {
        pointAtTime = pointAtTime + action.actionParam - oldStopParam;
      }
      airPlaneData.statistic.expectedTime = airLineTime + pointAtTime; //总时间
      conversionTime(airPlaneData.statistic.expectedTime); //换算总时间
    }

    //添加动作
    function addActions(typeAction, params) {
      const currentAction = airPlaneData.routePoint[pointIndex.value].addAction;
      //负载动作的初始值
      if (params == "loads") {
        switch (typeAction) {
          case "CAMERA_ZOOM":
            params = leftState.zoomRatio.min;
            break;
          case "PHOTO_INTERVAL":
            params = 3;
            break;
          case "PHOTO_EQUIDISTANCE":
            params = 10;
            break;

          default:
            params = "0";
        }
      }
      //判断动作是否属于可修改，加入输入框编辑标志
      if (
        typeAction === "PHOTOGRAPH" ||
        typeAction === "START_VIDEO" ||
        typeAction === "CREATE_FOLDER"
      ) {
        airPlaneData.routePoint[pointIndex.value].addAction.push({
          actionType: typeAction,
          actionParam: params,
          isEdit: false,
        });
      } else if (typeAction === "FLY_YAW_ANGLE") {
        //高效模式下,且第一个动作是飞行器动作,要同步偏航角度
        if (
          airPlaneData.airYaw === t("314") &&
          pointIndex.value !== 0 &&
          currentAction.length === 0
        ) {
          airPlaneData.routePoint[pointIndex.value].efficiencyPointYaw = 0;
        }
        //飞行器偏航角动作
        let { cameraAngle, flyAngle } = comAddAngle(currentAction.length);

        //将飞行器偏航角动作放入对象中维护
        aloneActionListAdd(flyAllList, {
          actionParam: flyAngle,
          cameraAngle: cameraAngle,
        });

        //添加偏航角动作最终数据
        currentAction.push({
          actionType: typeAction,
          actionParam: flyAngle,
          cameraAngle: cameraAngle,
        });
      } else if (typeAction === "PTZ_PITCH_ANGLE") {
        //将俯仰角动作放入对象中维护
        aloneActionListAdd(pitchAllList, params);

        // if (pitchAllList[pointIndex.value] === undefined) {
        //   pitchAllList[pointIndex.value] = new Map([
        //     [
        //       airPlaneData.routePoint[pointIndex.value].addAction.length,
        //       params,
        //     ],
        //   ]);
        // } else {
        //   pitchAllList[pointIndex.value].set(
        //     airPlaneData.routePoint[pointIndex.value].addAction.length,
        //     params
        //   );
        // }
        //添加回航线对象的动作里面
        currentAction.push({
          actionType: typeAction,
          actionParam: params,
        });
      } else if (typeAction === "CAMERA_ZOOM") {
        //将变焦动作放入对象中维护
        aloneActionListAdd(zoomAllList, params);
        currentAction.push({
          actionType: typeAction,
          actionParam: params,
        });
      } else {
        currentAction.push({
          actionType: typeAction,
          actionParam: params,
        });
      }

      //添加后选中动作
      pointActionData.checkAction = currentAction.length - 1;

      if (typeAction === "HOVER") {
        pointAtTime += 10;
      } else {
        pointAtTime += 1; //添加动作后，预计时间会+1
      }
      airPlaneData.statistic.expectedTime = airLineTime + pointAtTime; //总时间
      conversionTime(airPlaneData.statistic.expectedTime); //换算总时间

      //拍照动作
      if (typeAction === "PHOTOGRAPH") {
        photoNum += 1;
        airPlaneData.statistic.picture += 1;
      }

      //开始间隔拍照动作相关
      if (typeArr.includes(typeAction)) {
        //插入photoActionList中的动作
        if (photoActionList.length > 0) {
          let isFind = false;
          for (let last = photoActionList.length - 1; last >= 0; last--) {
            if (photoActionList[last].pointIndex <= pointIndex.value) {
              photoActionList.splice(last + 1, 0, {
                pointIndex: pointIndex.value,
                actionIndex: pointActionData.checkAction,
                action: currentAction[pointActionData.checkAction],
              });
              isFind = true;
              break; //退出循环
            }
          }
          if (!isFind) {
            photoActionList.unshift({
              pointIndex: pointIndex.value,
              actionIndex: pointActionData.checkAction,
              action: currentAction[pointActionData.checkAction],
            });
          }
        } else {
          photoActionList.push({
            pointIndex: pointIndex.value,
            actionIndex: pointActionData.checkAction,
            action: currentAction[pointActionData.checkAction],
          });
        }

        //计算时间照片
        airPlaneData.statistic.picture =
          publicComPhoto(0, photoActionList.length) + photoNum;
      }
    }

    /**
     * 开始等间距，时间，结束拍照动作列表
     * 数据结构
     *  pointIndex: number --航点下标
     *  actionIndex：number --该航点下的动作下标
     *  action --{actionType: string --动作类型,actionParam: number -- 动作值}
     */
    let photoActionList = [];

    /**
     * 1.加点和删除点:如果为起点，则不计算，其他点---重新计算照片 
     * 2.拖拽点：重新计算照片   
     * 3.在航点中加动作、在航点中删除动作 、在航点中拖拽动作-- 重新计算照片 
     
     * 计算照片逻辑：
     * 0.照片动作数组长度0
     * 1.查找有开始间隔拍照，记录poindIndex 若无，则结束，若有，则执行下边逻辑
     * 2.查找结束拍照的endIndex，若有且与开始间隔拍照同个pointIndex，则照片数1，回调该函数
     * 若有，且不是和开始间隔拍照同个航点，则计算两个动作的pointIndex之间距离或者时间的照片数，
     * 然后回调该函数，继续查找接下来的动作列表
     * 若无结束拍照，且开始间隔拍照不为终点，则直接开始到结束全长计算 ，若开始间隔拍照为终点则照片数+1，结束
     * 
     * 计算照片函数
     * @param {*photoActionList开始} start
     * @param {*photoActionList结束} end
     */
    function publicComPhoto(start, end) {
      let pictureNum = 0; //这个循环的照片数

      if (photoActionList?.length <= 0) {
        //没有间隔拍照动作
        return pictureNum;
      }

      if (end === 0) {
        //查找结束范围为0，
        return pictureNum;
      }

      let actionStart = null; //第一个开始间隔拍照下标--照片数组下标

      for (let i = start; i < end; i++) {
        //查找开始拍照间隔下标
        if (
          photoActionList[i].action.actionType === "PHOTO_INTERVAL" ||
          photoActionList[i].action.actionType === "PHOTO_EQUIDISTANCE"
        ) {
          actionStart = i;
          break;
        }
      }
      //没有找到开始动作
      if (actionStart === null) {
        return pictureNum;
      }

      let actionEnd = null; //第一个开始间隔拍照下标--照片数组下标
      // 查找结束拍照动作
      for (let j = actionStart + 1; j < end; j++) {
        if (photoActionList[j].action.actionType === "END_PHOTO") {
          //有结束动作
          actionEnd = j;
          break;
        }
      }

      //没有找到结束间隔拍照
      if (actionEnd === null) {
        if (
          photoActionList[actionStart].pointIndex ===
          airPlaneData.routePoint.length - 1
        ) {
          pictureNum = 1;
          return pictureNum;
        } else {
          pictureNum = comDotsPhoto(
            photoActionList[actionStart].pointIndex,
            airPlaneData.routePoint.length - 1,
            photoActionList[actionStart].action.actionType,
            photoActionList[actionStart].action.actionParam
          );
          return pictureNum;
        }
      } else {
        //找到结束间隔拍照
        //结束和开始同个航点
        if (
          photoActionList[actionStart].pointIndex ===
          photoActionList[actionEnd].pointIndex
        ) {
          pictureNum = 1;

          //查找后续动作
          if (actionEnd < photoActionList.length - 1) {
            pictureNum += publicComPhoto(
              actionEnd + 1,
              photoActionList?.length
            );
          }
          return pictureNum;
        } else {
          //开始结束不同点
          pictureNum = comDotsPhoto(
            photoActionList[actionStart].pointIndex,
            photoActionList[actionEnd].pointIndex,
            photoActionList[actionStart].action.actionType,
            photoActionList[actionStart].action.actionParam
          );

          //查找后续动作
          if (actionEnd < photoActionList?.length - 1) {
            pictureNum += publicComPhoto(
              actionEnd + 1,
              photoActionList?.length
            );
          }
          return pictureNum;
        }
      }
    }

    /**
     * 公共函数--计算开始点和结束点之间的照片数
     * @param {*开始点} startPoint
     * @param {*结束点} endPoint
     * @param {*间隔拍照类型} actionType
     * @param {*间隔拍照数值} actionParam
     */
    function comDotsPhoto(startPoint, endPoint, actionType, actionParam) {
      let dotsPhoto = 0; //照片数
      let dotsTime = 0; //开始--结束的时间
      let dotsLong = 0; //开始--结束的长度
      let dotsSpeed = airPlaneData.routePoint[startPoint].speed.flySpeed; //开始的速度

      //防止开始结束点相同导致结果错误
      if (startPoint === endPoint) {
        return dotsPhoto;
      }

      for (let i = startPoint + 1; i <= endPoint; i++) {
        dotsLong += airPlaneData.routePoint[i].pointDistance;
        if (actionType === "PHOTO_INTERVAL") {
          // dotsTime += airPlaneData.routePoint[i].addAction.length; //加上这些点动作的时间
          dotsSpeed += airPlaneData.routePoint[i].speed.flySpeed; //判断开始间隔拍照类型
        }
      }
      if (actionType === "PHOTO_INTERVAL") {
        dotsTime = dotsLong / (dotsSpeed / (endPoint - startPoint + 1));
        // 计算开始点-结束点的时间
        dotsTime += (endPoint - startPoint) * 2;

        dotsPhoto = dotsTime / actionParam; //计算照片数
      } else {
        dotsPhoto = dotsLong / actionParam;
      }
      dotsPhoto = Math.ceil(dotsPhoto);
      return dotsPhoto;
    }

    //消息提示弹窗
    let isTips = ref(false);

    // //监听航点类型是否为航点不停留
    // watch(
    //   () => airPlaneData.wayPoint,
    //   (newName) => {
    //     // 当航点类型为航点不停留 航点启用云台俯仰角
    //     if (newName == "WAYPOINT_STOP") {
    //       leftState.isWayPoitStop = false;
    //     } else {
    //       leftState.isWayPoitStop = true;
    //     }
    //   }
    // );

    /* 退出组件销毁 */
    onBeforeUnmount(() => {
      destroyCesium("cesiumContainer");
    });

    /* 隐藏右键菜单 */
    function hideRightMenuBox() {
      const rightMenuBox = document.getElementById("rightMenuBox");
      rightMenuBox.style.display = "none";
    }

    /* 删除绘制实体 */
    function rightDelPoint(e) {
      const rightMenuBox = document.getElementById("rightMenuBox");
      const routeplanId = rightMenuBox.getAttribute("data-routeplanId"); //数据对象id
      const routePointId = rightMenuBox.getAttribute("data-routePointId"); //数据对象id
      rightMenuBox.style.display = "none";
      const res = delLinePoint(routeplanId, routePointId);

      //查找删除点的数据数组中的坐标
      for (let [index, item] of airPlaneData.routePoint.entries()) {
        if (item.routePointId === routePointId) {
          airPointFun.publicDeleteP(index);
          publicUpdateYaw(res);
          break;
        }
      }
    }

    /* 修改拖拽类型 */
    function changeDragType(type) {
      dragType.value = type;
    }

    //二次编辑加点
    function aginEditAddPoint() {
      const pointList = [];
      for (let item of airPlaneData.routePoint) {
        pointList.push({
          lng: item.pointLog,
          lat: item.pointLat,
          alt: item.pointAltitude,
          relAlt: item.pointRelative,
          id: item.routePointId,
        });
      }
      batchAddRoutePoint(routeplanId, pointList);
      zoomToRouteLine(routeplanId);
    }
    //将string（原型为[Map]）转回数组map
    function strTransformArrmap(list) {
      const mapList = list.map((item) => {
        item = new Map(JSON.parse(item));
        return item;
      });
      return mapList;
    }
    //命名航线函数
    async function autoAirlineName() {
      const { value, airPlaneId } = route.query;
      // 自动航线命名
      if (value === "addRoutePlan") {
        //新建航线
        const lastRoutePlanName =
          localStorage.getItem("lastRoutePlanName") === null
            ? t("354")
            : localStorage.getItem("lastRoutePlanName");
        airPointServer
          .getRoutePlanName({ routeName: lastRoutePlanName, routeId: 0 })
          .then((res) => {
            if (res.resultStatus) {
              airPlaneData.routePlanName = res.resultData;
            }
          });
      } else {
        //二次编辑航线
        let res = await airPointServer.reviseEdit({ routeId: airPlaneId });
        if (res.resultStatus) {
          const [routeInfo, additionalInfo] = res.resultData;

          if (additionalInfo === undefined || additionalInfo === "null") {
            isSupportEdit = false;
            ElMessage.warning(t("355"));
          } else {
            // const routeInfoList = JSON.parse(routeInfo);
            // for (let key in airPlaneData) {
            //   airPlaneData[key] = routeInfoList[key];
            // }
            routeId = airPlaneId;
            airPlaneData = Object.assign(airPlaneData, JSON.parse(routeInfo));
            pointIndex.value = airPlaneData.routePoint.length - 1;
            anewWatch && anewWatch();
            anewWatch = saveWatch();

            //赋值之前保存的值
            const otherData = JSON.parse(additionalInfo);
            routeplanId = otherData.routeplanId;
            airLineTime = otherData.airLineTime;
            pointAtTime = otherData.pointAtTime;
            photoNum = otherData.photoNum;
            photoActionList = otherData.photoActionList;
            airPointData.latitude = otherData.airPointData.latitude;
            airPointData.longitude = otherData.airPointData.longitude;
            estimatTime.value = otherData.estimatTime;
            tipsList.value = otherData.tipsList;
            pitchAllList = strTransformArrmap(otherData.pitchAllList);
            flyAllList = strTransformArrmap(otherData.flyAllList);
            zoomAllList = strTransformArrmap(otherData.zoomAllList);
            aginEditAddPoint();
          }
        } else {
          ElMessage.error(t("356"));
        }
      }
    }

    //相机弹出框相关数据
    let cameraDialog = ref(false); //弹出框
    let selectCamera = "M30 Camera"; //选择相机的型号未确认
    // 选择相机型号
    function changeCamera(params) {
      cameraDialog.value = true;
      selectCamera = params;
    }
    //确认选中相机
    function determineCanmera() {
      airPlaneData.loadSetting.panTilt = selectCamera;
      airPlaneData.loadSetting.routePlanImgType = [];
      leftState.lCheckBox = lCheckObj[selectCamera];
      leftState.zoomRatio = cameraZoom[selectCamera]; //变焦倍数
      lCheckObj[selectCamera].map((item) => {
        airPlaneData.loadSetting.routePlanImgType.push(item.type);
      });
      cameraDialog.value = false;
      nextTick(() => {
        resetCameraActionList();
        openAnyActionAngle(pointActionData.checkAction);
      });
    }
    return {
      loadAction,
      isShowAirline,
      isShowPoint,
      isTips,
      isAddaction,
      rangNav,
      twoThreeNav,
      tipsList,
      isBack,
      isAltitude,
      isRelative,
      openLoad,
      ...toRefs(flyPointData),
      ...toRefs(pointActionData),
      ...toRefs(buttonData),
      ...toRefs(airPlaneData),
      ...toRefs(airPointData),
      ...toRefs(methods),
      ...toRefs(leftState),
      ...toRefs(earthData),
      ...pointSLData,
      pointIndex,
      isDraggable,
      changeHide,
      addActions,
      getOldParam,
      updateStopParam,
      handleDragStart,
      handleDragEnter,
      handleDragEnd,
      openAddAction,
      save,
      ...airLineFun,
      ...setflyFun,
      ...revertFun,
      ...mapButtonFun,
      ...calculation,
      ...airPointFun,
      ...pointActionFun,

      modelDialog,
      isHeightMode,

      Delete,
      // MapLocation,
      DArrowRight,
      Plus,
      // Check,
      // Close,
      Search,
      ArrowLeft,
      ArrowRight,
      CloseBold,
      // Edit,
      Warning,
      hideRightMenuBox,
      rightDelPoint,
      incompleteDelete,

      estimatTime,
      changeDragType,
      changeCamera,
      cameraDialog,
      determineCanmera,
    };
  },
});
</script>
<style scoped lang="less">
@import url("./index.less");
</style>
<style lang="less">
.nopadding_popover.el-popper.is-light .el-popper__arrow::before {
  background: rgba(11, 79, 122, 0.86) !important;
  border-color: rgba(11, 79, 122, 0.86) !important;
}
.search_ul {
  max-height: 20.875rem !important;
}
.titleTip {
  width: 20rem;
}
.tip-class {
  color: #000;
}
</style>
