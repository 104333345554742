<template>
  <div class="searchs flex-a item_box">
    <el-input
      class="filePut common-input"
      type="text"
      :placeholder="t('963')"
      clearable
      v-model="searchWord"
      @change="inputChange"
    />
    <el-icon class="file_icon hover_opacity" @click="search"
      ><Search
    /></el-icon>
    <div>
      <div class="search_con" v-show="searchList.length > 0">
        <ul class="search_ul">
          <li v-for="(item, index) in searchList" :key="index" @click="locationPlace(item.location)">
            {{ item.name }}（{{item.cityname}}）
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../languages';
import { ref } from "vue-demi";
import axios from "axios";

const emit = defineEmits(['flyToPlace'])

const searchWord = ref("");
const searchList = ref([]);
function search() {
  if(searchWord.value.trim() === '') return;
  axios
    .get(
      `https://restapi.amap.com/v3/place/text?key=b5c9e191fc5906c1ee7329888aa857ef&keywords=${searchWord.value}`
    )
    .then(function (res) {
      console.log(res);
      searchList.value = res.data.pois ? res.data.pois : [];
    })
    .catch(function (error) {
      console.log(error);
    });
}

/* 搜索框改变 */
function inputChange(){
  if(searchWord.value === ''){
    searchList.value = [];
  }
}

/* 定位到地点的位置 */
function locationPlace(position){
  console.log(position)
  const arr = position.split(',');
  emit("flyToPlace",arr);
}
</script>

<style scoped lang="less">
.file_icon {
  width: 15%;
  height: 100%;
  font-size: 1.375rem;
  color: #ffffff;
  font-weight: 500;
  border-radius: 0px 0.25rem 0.25rem 0px;
  background: #32abf9;
}
.item_box {
  position: relative;
}
.search_con {
  position: absolute;
  left: 0;
  top: 2.5rem;
  width:  22.4rem;
  background: #313742;
  margin-bottom: 0.25rem;
  margin-right: 2.75rem;
  overflow: hidden;
}
.search_ul {
  max-height: 21.875rem;
  padding: 0.375rem 0;
  font-size: 0.875rem;
  color: #b7bcd4;
  overflow: auto;
  li {
    padding: 0.5rem 1.125rem;
    cursor: pointer;
    line-height: 2;
    cursor: pointer;
  }
  li:hover{
    background: #0090da;
    color: #fff;
  }
}
</style>

